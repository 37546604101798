import { useTranslation } from "react-i18next";
import {
  getTaskMutationErrorLabels,
  getTaskTypeLabels,
} from "../../../__generatedEnumLabels";
import { useMutation } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import { Alert, ListItem, ListItemText, Snackbar, Switch } from "@mui/material";
import React, { useState } from "react";
import { TaskListItemProps } from "./TaskListItem";
import {
  TaskMutationError,
  TaskToggleListItemEnabledMutation,
} from "./__generated__/TaskToggleListItemEnabledMutation.graphql";
import queryErrorToMessages from "../../../utils/queryErrorToMessages";

export default function TaskToggleListItem({ task }: TaskListItemProps) {
  const { t } = useTranslation();
  const taskTypeLabels = getTaskTypeLabels(t);

  const [commit, isInFlight] = useMutation<TaskToggleListItemEnabledMutation>(
    graphql`
      mutation TaskToggleListItemEnabledMutation(
        $input: TaskMutationFieldInput!
      ) {
        updateTask(input: $input) {
          task {
            id
            state
            enabled
          }
          errors
        }
      }
    `
  );

  const [errorSnackbar, setErrorSnackbar] = useState<{
    open: boolean;
    errorKeys: readonly string[];
    mutationErrors: readonly TaskMutationError[];
  }>({ open: false, errorKeys: [], mutationErrors: [] });

  return (
    <ListItem>
      <Snackbar
        open={errorSnackbar.open}
        autoHideDuration={5000}
        onClose={() =>
          setErrorSnackbar({
            ...errorSnackbar,
            open: false,
          })
        }
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={() =>
            setErrorSnackbar({
              ...errorSnackbar,
              open: false,
            })
          }
          severity="error"
          sx={{ width: "100%" }}
        >
          <p>{t("Error when enabling/disabling task")}</p>
          {errorSnackbar.errorKeys.map((key) => (
            <p key={key}>{t(key)}</p>
          ))}
          {errorSnackbar.mutationErrors.map((key) => (
            <p key={key}>{getTaskMutationErrorLabels(t).get(key)}</p>
          ))}
        </Alert>
      </Snackbar>
      <ListItemText primary={taskTypeLabels.get(task.type!)} />
      <Switch
        checked={(task.enabled === true) !== isInFlight}
        disabled={isInFlight}
        onChange={(event) => {
          const newEnabled = event.target.checked;
          commit({
            variables: {
              input: {
                id: task.id,
                enabled: newEnabled,
              },
            },
            onCompleted: (response) => {
              if ((response.updateTask?.errors || []).length > 0) {
                setErrorSnackbar({
                  open: true,
                  mutationErrors: response.updateTask!.errors!,
                  errorKeys: [],
                });
              }
            },
            onError: (error) => {
              const errorKeys = queryErrorToMessages(error);
              console.log(error);
              setErrorSnackbar({
                open: true,
                errorKeys: [
                  errorKeys.error.primaryError,
                  errorKeys.error.secondaryError,
                ],
                mutationErrors: [],
              });
            },
          });
        }}
      />
    </ListItem>
  );
}
