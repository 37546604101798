import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import queryErrorToMessages from "../../utils/queryErrorToMessages";

interface ErrorBoundaryProps extends WithTranslation {
  fallback?: (primaryError: string, secondaryError: string) => React.ReactNode;
}

interface ErrorBoundaryState {
  error?: {
    primaryError: string;
    secondaryError: string;
  };
}

class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = {};
  }

  static getDerivedStateFromError(error: any) {
    return queryErrorToMessages(error);
  }

  render() {
    if (this.state.error) {
      if (this.props.fallback == null) {
        return <></>;
      }
      return this.props.fallback(
        this.props.t(this.state.error.primaryError),
        this.props.t(this.state.error.secondaryError)
      );
    }
    return this.props.children;
  }
}

export default withTranslation()(ErrorBoundary);
