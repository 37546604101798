/**
 * @generated SignedSource<<10b57e12aecc84ce0cf2ab8ef5843aa9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type TaskMutationError = "INVALID_TRANSITION" | "STATE_CHANGE_NOT_ALLOWED" | "ENABLE_DISABLE_NOT_ALLOWED" | "XMF_PUSH_SP_NOT_FOUND" | "XMF_PUSH_SP_MISSING_PAGE_COUNT" | "XMF_PUSH_SP_INVALID_DATA" | "XMF_PUSH_FAILED" | "XMF_PUSH_REJECTED" | "XMF_PUSH_PPS_QUERY_FAILED" | "CALENDAR_UPDATE_FAILED" | "%future added value";
export type TaskState = "PENDING" | "IN_PROGRESS" | "DONE" | "%future added value";
export type TaskMutationFieldInput = {
  id?: string | null;
  state?: TaskState | null;
  enabled?: boolean | null;
  clientMutationId?: string | null;
};
export type TaskToggleListItemEnabledMutation$variables = {
  input: TaskMutationFieldInput;
};
export type TaskToggleListItemEnabledMutation$data = {
  readonly updateTask: {
    readonly task: {
      readonly id: string;
      readonly state: TaskState | null;
      readonly enabled: boolean;
    } | null;
    readonly errors: ReadonlyArray<TaskMutationError> | null;
  } | null;
};
export type TaskToggleListItemEnabledMutation = {
  variables: TaskToggleListItemEnabledMutation$variables;
  response: TaskToggleListItemEnabledMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "TaskMutationFieldPayload",
    "kind": "LinkedField",
    "name": "updateTask",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "TaskNode",
        "kind": "LinkedField",
        "name": "task",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "state",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "enabled",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "errors",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TaskToggleListItemEnabledMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TaskToggleListItemEnabledMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "09e8dbe8dd28ade9396d3d9339221de6",
    "id": null,
    "metadata": {},
    "name": "TaskToggleListItemEnabledMutation",
    "operationKind": "mutation",
    "text": "mutation TaskToggleListItemEnabledMutation(\n  $input: TaskMutationFieldInput!\n) {\n  updateTask(input: $input) {\n    task {\n      id\n      state\n      enabled\n    }\n    errors\n  }\n}\n"
  }
};
})();

(node as any).hash = "7753d09392d7bc4122e81d0e419fdb8c";

export default node;
