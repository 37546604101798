import React, { useEffect, useState, Suspense } from "react";

import {
  Box,
  Card,
  Chip,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Skeleton,
  Typography,
  useMediaQuery,
} from "@mui/material";
import {
  LoadMoreFn,
  PreloadedQuery,
  usePaginationFragment,
  usePreloadedQuery,
} from "react-relay";
import graphql from "babel-plugin-relay/macro";
import { useTranslation } from "react-i18next";
import JobManagerAppBar from "../navigation/JobManagerAppBar/JobManagerAppBar";
import LoadMoreButton from "../common/LoadMoreButton";
import ListViewFilter, {
  ListViewFilterContainer,
} from "../common/ListViewFilter";
import {
  TaskListViewComponent_tasks$data,
  TaskState,
  TaskType,
} from "./__generated__/TaskListViewComponent_tasks.graphql";
import { TaskListViewQuery } from "./__generated__/TaskListViewQuery.graphql";
import { getTaskTypeLabels } from "../../__generatedEnumLabels";
import StatusChip from "../common/StatusChip";
import ErrorBoundaryCard from "../common/ErrorBoundaryCard";
import { NavLink } from "react-router-dom";

interface TaskListCardProps {
  data: TaskListViewComponent_tasks$data;
  hasNext: boolean;
  loadNext: LoadMoreFn<any>;
  isLoadingNext: boolean;
}

function TaskListCard({
  data,
  hasNext,
  loadNext,
  isLoadingNext,
}: TaskListCardProps) {
  const { t } = useTranslation();

  const taskTypeLabels = getTaskTypeLabels(t);

  const listItems = (data.allTasks?.edges || [])
    .filter((edge) => edge !== null && edge.node !== null)
    .map((edge) => (
      <ListItemButton
        key={edge!.node!.id}
        component={NavLink}
        to={"/jobs/" + edge!.node!.job.pk}
      >
        <ListItemText
          primary={
            "Job " +
            edge!.node!.job.jdfId +
            ": " +
            taskTypeLabels.get(edge!.node!.type!)
          }
          secondary={edge!.node!.job.name}
        />
        <StatusChip status={edge!.node!.state} />
      </ListItemButton>
    ));

  const content =
    listItems.length > 0 ? (
      <>
        <List>{listItems}</List>
        {hasNext ? (
          <LoadMoreButton loadNext={loadNext} isLoadingNext={isLoadingNext} />
        ) : null}
      </>
    ) : (
      <Typography
        sx={{ fontStyle: "italic", textAlign: "center", margin: "32px" }}
      >
        {t("No tasks found")}
      </Typography>
    );

  return (
    <Box
      sx={{
        maxWidth: "1340px",
        margin: "16px auto",
      }}
    >
      <Card
        variant="outlined"
        sx={{
          maxWidth: "600px",
          margin: "0 auto",
          bgcolor: "background.paper",
        }}
      >
        {content}
      </Card>
    </Box>
  );
}

interface TaskListFilterProps {
  data: TaskListViewComponent_tasks$data;
  statesFilterState: Set<TaskState>;
  setStateFilterState: React.Dispatch<React.SetStateAction<Set<TaskState>>>;
  typesFilterState: Set<TaskType>;
  setTypeFilterState: React.Dispatch<React.SetStateAction<Set<TaskType>>>;
  filterDialogOpen?: boolean;
  onFilterDialogClose?: () => void;
}

function TaskListFilter({
  data,
  statesFilterState,
  setStateFilterState,
  typesFilterState,
  setTypeFilterState,
  filterDialogOpen,
  onFilterDialogClose,
}: TaskListFilterProps) {
  const { t } = useTranslation();
  const taskStates = data.allTasks?.taskStates;
  const taskTypes = data.allTasks?.taskTypes;

  if (taskStates == null || taskTypes == null) {
    return null;
  }

  const taskStateLabels = new Map<TaskState, string>([
    ["PENDING", t("Pending")],
    ["IN_PROGRESS", t("In progress")],
    ["DONE", t("Done")],
  ]);
  const taskStateAmounts = new Map<TaskState, { total: number; shown: number }>(
    taskStates!.map((state) => [
      state.stateKey,
      { total: state.stateTotalCount, shown: state.stateCount },
    ])
  );

  const taskTypeLabels = getTaskTypeLabels(t);
  const taskTypeAmounts = new Map<TaskType, { total: number; shown: number }>(
    taskTypes!.map((type) => [
      type.typeKey,
      { total: type.typeTotalCount, shown: type.typeCount },
    ])
  );

  return (
    <ListViewFilterContainer
      dialogOpen={filterDialogOpen}
      onDialogClose={onFilterDialogClose}
    >
      <ListViewFilter
        title={t("Filter by state")}
        keys={taskStates.map((state) => state.stateKey)}
        labels={taskStateLabels}
        amounts={taskStateAmounts}
        state={statesFilterState}
        setState={setStateFilterState}
      />
      <ListViewFilter
        title={t("Filter by type")}
        keys={taskTypes.map((type) => type.typeKey)}
        labels={taskTypeLabels}
        amounts={taskTypeAmounts}
        state={typesFilterState}
        setState={setTypeFilterState}
      />
    </ListViewFilterContainer>
  );
}

interface TaskListProps {
  taskListQueryRef: PreloadedQuery<TaskListViewQuery>;
  toggleDrawer: (
    open: boolean
  ) => (event: React.KeyboardEvent | React.MouseEvent) => void;
}

interface TaskListContentProps {
  taskListQueryRef: PreloadedQuery<TaskListViewQuery>;
  searchString: string | null;
  filterDialogOpen?: boolean;
  onFilterDialogClose?: () => void;
}

function TaskListContent({
  taskListQueryRef,
  searchString,
  filterDialogOpen,
  onFilterDialogClose,
}: TaskListContentProps) {
  const [stateFilterState, setStateFilterState] = useState<Set<TaskState>>(
    new Set<TaskState>(taskListDefaultStates)
  );
  const [typeFilterState, setTypeFilterState] = useState<Set<TaskType>>(
    new Set<TaskType>([])
  );

  const queryData = usePreloadedQuery(
    graphql`
      query TaskListViewQuery(
        $types: [TaskType!]
        $states: [TaskState!]
        $search: String
        $pk: Float
      ) {
        ...TaskListViewComponent_tasks
      }
    `,
    taskListQueryRef
  );

  const { data, refetch, loadNext, isLoadingNext, hasNext } =
    usePaginationFragment<TaskListViewQuery, any>(
      graphql`
        fragment TaskListViewComponent_tasks on Query
        @argumentDefinitions(
          cursor: { type: "String" }
          count: { type: "Int", defaultValue: 30 }
        )
        @refetchable(queryName: "TaskListPaginationQuery") {
          allTasks(
            first: $count
            after: $cursor
            state: $states
            taskType: $types
            job_Name_Icontains: $search
            job_Id: $pk
          ) @connection(key: "TaskList_allTasks") {
            edges {
              node {
                job {
                  pk
                  jdfId
                  name
                }
                id
                type
                state
              }
            }
            taskStates {
              stateKey
              stateCount
              stateTotalCount
            }
            taskTypes {
              typeKey
              typeCount
              typeTotalCount
            }
          }
        }
      `,
      queryData
    );

  useEffect(() => {
    const variables =
      searchString != null && searchString.match("^J|jP|p[0-9]+$")
        ? {
            states: Array.from(stateFilterState),
            types:
              typeFilterState.size === 0
                ? undefined
                : Array.from(typeFilterState),
            pk: parseInt(searchString.substring(2)),
          }
        : {
            states: Array.from(stateFilterState),
            types:
              typeFilterState.size === 0
                ? undefined
                : Array.from(typeFilterState),
            search: searchString,
          };
    refetch(variables, { fetchPolicy: "store-or-network" });
  }, [stateFilterState, typeFilterState, refetch, searchString]);

  return (
    <Box
      sx={{
        maxWidth: "1340px",
        margin: "16px auto",
      }}
    >
      <TaskListCard
        data={data}
        loadNext={loadNext}
        isLoadingNext={isLoadingNext}
        hasNext={hasNext}
      />
      <TaskListFilter
        data={data}
        statesFilterState={stateFilterState}
        setStateFilterState={setStateFilterState}
        typesFilterState={typeFilterState}
        setTypeFilterState={setTypeFilterState}
        filterDialogOpen={filterDialogOpen}
        onFilterDialogClose={onFilterDialogClose}
      />
    </Box>
  );
}

function TaskListContentSkeleton() {
  return (
    <Box
      sx={{
        maxWidth: "1340px",
        margin: "16px auto",
      }}
    >
      <Card
        variant="outlined"
        sx={{
          maxWidth: "600px",
          margin: "0 auto",
          bgcolor: "background.paper",
        }}
      >
        <List>
          {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((key: number) => (
            <ListItem key={key}>
              <ListItemText primary={<Skeleton />} secondary={<Skeleton />} />
              <Skeleton sx={{ marginLeft: "16px" }}>
                <Chip
                  sx={{
                    width: "116px",
                  }}
                />
              </Skeleton>
            </ListItem>
          ))}
        </List>
      </Card>
    </Box>
  );
}

export default function TaskListView({
  taskListQueryRef,
  toggleDrawer,
}: TaskListProps) {
  const [searchState, setSearchState] = useState<string | null>(null);

  const [filterDialogOpen, setFilterDialogOpen] = useState(false);
  const showFilterAsDialog = !useMediaQuery("(min-width:1200px)");

  return (
    <>
      <JobManagerAppBar
        toggleDrawer={toggleDrawer}
        onSearchInputChange={(str) => {
          setSearchState(str === "" ? null : str);
        }}
        onFilterButtonTap={
          showFilterAsDialog ? () => setFilterDialogOpen(true) : undefined
        }
      />
      <Suspense fallback={<TaskListContentSkeleton />}>
        <ErrorBoundaryCard>
          <TaskListContent
            taskListQueryRef={taskListQueryRef}
            searchString={searchState}
            filterDialogOpen={showFilterAsDialog ? filterDialogOpen : undefined}
            onFilterDialogClose={() => setFilterDialogOpen(false)}
          />
        </ErrorBoundaryCard>
      </Suspense>
    </>
  );
}

export const taskListDefaultStates: TaskState[] = ["PENDING", "IN_PROGRESS"];
