import { useLazyLoadQuery, useMutation } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import React, { useState } from "react";
import { SubproductDataSelectorListQuery } from "./__generated__/SubproductDataSelectorListQuery.graphql";
import {
  SubproductDataSelectorListMutation,
  UpdateJobSubproductDataMutationError,
} from "./__generated__/SubproductDataSelectorListMutation.graphql";
import SubproductDataList from "../../subproduct-data/SubproductDataList/SubproductDataList";
import { Alert, Snackbar } from "@mui/material";
import queryErrorToMessages from "../../../utils/queryErrorToMessages";
import { useTranslation } from "react-i18next";
import { getUpdateJobSubproductDataMutationErrorLabels } from "../../../__generatedEnumLabels";

export enum SPDataStates {
  ASSIGNING,
  ASSIGNED,
  UNASSIGNING,
}

interface SubproductDataCardListProps {
  searchString: string | null;
  jobId: string;
  assignedSubproductIDs: string[];
}

export default function SubproductDataSelectorList({
  searchString,
  jobId,
  assignedSubproductIDs,
}: SubproductDataCardListProps) {
  const { t } = useTranslation();

  const queryData = useLazyLoadQuery<SubproductDataSelectorListQuery>(
    graphql`
      query SubproductDataSelectorListQuery(
        $search: String
        $searchNumber: String
        $returnAssignedToJobs: Boolean
        $returnUnassignedToJobs: Boolean
      ) {
        ...SubproductDataList_subproductData
      }
    `,
    { search: null },
    { fetchPolicy: "network-only" }
  );

  const [commit] = useMutation<SubproductDataSelectorListMutation>(
    graphql`
      mutation SubproductDataSelectorListMutation(
        $id: ID!
        $subproductData: ID!
        $action: AssignmentAction!
      ) {
        updateJobSubproductData(
          input: { id: $id, spDataId: $subproductData, action: $action }
        ) {
          job {
            id
            ...RelatedSubproductsList_subproductData
          }
          errors
        }
      }
    `
  );

  const [spDataStates, setSpDataStates] = useState<{
    [id: string]: SPDataStates;
  }>(
    assignedSubproductIDs.reduce((obj: { [id: string]: SPDataStates }, x) => {
      obj[x] = SPDataStates.ASSIGNED;
      return obj;
    }, {})
  );

  const [errorSnackbar, setErrorSnackbar] = useState<{
    open: boolean;
    errorKeys: readonly string[];
    mutationErrors: readonly UpdateJobSubproductDataMutationError[];
  }>({ open: false, errorKeys: [], mutationErrors: [] });

  const handleCheckboxChange = (id: string, isChecked: boolean) => {
    setSpDataStates((spDataStates) => {
      let newSpDataStates = {
        ...spDataStates,
      };
      newSpDataStates[id] = isChecked
        ? SPDataStates.ASSIGNING
        : SPDataStates.UNASSIGNING;
      return newSpDataStates;
    });

    const confirmChange = () => {
      setSpDataStates((spDataStates) => {
        let newSpDataStates = {
          ...spDataStates,
        };
        if (isChecked) {
          newSpDataStates[id] = SPDataStates.ASSIGNED;
        } else {
          delete newSpDataStates[id];
        }
        return newSpDataStates;
      });
    };

    const abortChange = () => {
      setSpDataStates((spDataStates) => {
        let newSpDataStates = {
          ...spDataStates,
        };
        if (isChecked) {
          delete newSpDataStates[id];
        } else {
          newSpDataStates[id] = SPDataStates.ASSIGNED;
        }
        return newSpDataStates;
      });
    };

    commit({
      variables: {
        id: jobId,
        subproductData: id,
        action: isChecked ? "ASSIGN" : "UNASSIGN",
      },
      onCompleted: (response) => {
        if ((response.updateJobSubproductData?.errors || []).length > 0) {
          setErrorSnackbar({
            open: true,
            mutationErrors: response.updateJobSubproductData!.errors!,
            errorKeys: [],
          });
          abortChange();
        } else {
          confirmChange();
        }
      },
      onError: (error) => {
        const errorKeys = queryErrorToMessages(error);
        console.log(error);
        setErrorSnackbar({
          open: true,
          errorKeys: [
            errorKeys.error.primaryError,
            errorKeys.error.secondaryError,
          ],
          mutationErrors: [],
        });
        abortChange();
      },
    });
  };

  return (
    <>
      <Snackbar
        open={errorSnackbar.open}
        autoHideDuration={5000}
        onClose={() =>
          setErrorSnackbar({
            ...errorSnackbar,
            open: false,
          })
        }
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={() =>
            setErrorSnackbar({
              ...errorSnackbar,
              open: false,
            })
          }
          severity="error"
          sx={{ width: "100%" }}
        >
          <p>{t("Error when assigning subproduct")}</p>
          {errorSnackbar.errorKeys.map((key) => (
            <p key={key}>{t(key)}</p>
          ))}
          {errorSnackbar.mutationErrors.map((key) => (
            <p key={key}>
              {getUpdateJobSubproductDataMutationErrorLabels(t).get(key)}
            </p>
          ))}
        </Alert>
      </Snackbar>

      <SubproductDataList
        queryData={queryData}
        searchString={searchString}
        spDataStates={spDataStates}
        onCheckboxChange={handleCheckboxChange}
        openAsDialog
        hideJobs={[jobId]}
      />
    </>
  );
}
