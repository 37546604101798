import { Chip } from "@mui/material";
import { useTranslation } from "react-i18next";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import EditIcon from "@mui/icons-material/Edit";
import SettingsIcon from "@mui/icons-material/Settings";

interface Props {
  status: string | null;
  step?: "completed" | "active" | "todo";
}

export default function StatusChip(props: Props) {
  const { t } = useTranslation();
  const statuses: { [key: string]: string } = {
    IN_PROGRESS: t("In progress"),
    PENDING: t("Pending"),
    DONE: t("Done"),
    DRAFT: t("Draft"),
    READY: t("Ready"),
  };

  let style = {};
  let iconStyle = {};
  if (props.step === "completed") {
    style = { backgroundColor: "#eeeeee", color: "grey" };
    iconStyle = { color: "grey" };
  }
  if (props.step === "todo") {
    style = { backgroundColor: "white", color: "grey" };
    iconStyle = { color: "grey" };
  }

  if (props.status === "PENDING") {
    return (
      <Chip
        label={statuses[props.status]}
        icon={<AccessTimeIcon />}
        variant="outlined"
      />
    );
  } else if (props.status === "READY") {
    return (
      <Chip
        label={statuses[props.status]}
        icon={
          <AccessTimeIcon
            sx={{
              color: "white !important",
              ...iconStyle,
            }}
          />
        }
        variant="outlined"
        sx={{
          backgroundColor: "#e89641",
          color: "white",
          ...style,
        }}
      />
    );
  } else if (props.status === "IN_PROGRESS") {
    return (
      <Chip
        label={statuses[props.status]}
        icon={
          <SettingsIcon
            sx={{
              color: "white !important",
              ...iconStyle,
            }}
          />
        }
        variant="outlined"
        sx={{
          backgroundColor: "#bf3f3f",
          color: "white",
          ...style,
        }}
      />
    );
  } else if (props.status === "DRAFT") {
    return (
      <Chip
        label={statuses[props.status]}
        icon={<EditIcon fontSize="small" />}
        variant="outlined"
        sx={{
          backgroundColor: "#eeeeee",
          ...style,
        }}
      />
    );
  } else if (props.status === "DONE") {
    return (
      <Chip
        className="green"
        label={statuses[props.status]}
        icon={
          <CheckCircleIcon
            sx={{
              color: "white !important",
              ...iconStyle,
            }}
          />
        }
        variant="outlined"
        sx={{
          backgroundColor: "#158785",
          color: "white",
          ...style,
        }}
      />
    );
  }
  return <Chip />;
}
