import { SubscriptionClient } from "subscriptions-transport-ws";
import {
  loggerMiddleware,
  RelayNetworkLayer,
  urlMiddleware,
  authMiddleware,
  retryMiddleware,
} from "react-relay-network-modern";
import Cookies from "js-cookie";
import { Environment, Observable, RecordSource, Store } from "relay-runtime";

// This is written in Javascript as opposed to Typescript due to type mismatch between type of `subscribeFn` of
// `react-relay-network-modern` and `subscribe` parameter of `relay-runtime` `Environment`.

const loc = window.location;
const subscription_client = new SubscriptionClient(
  (loc.protocol === "https:" ? "wss://" : "ws://") + loc.host + "/graphql/",
  { reconnect: true }
);

const subscribe = (request, variables) => {
  const subscribeObservable = subscription_client.request({
    query: request.text,
    operationName: request.name,
    variables,
  });
  // Important: Convert subscriptions-transport-ws observable type to Relay's
  return Observable.from(subscribeObservable);
};

let relay_network_middlewares = [
  retryMiddleware({
    retryDelays: [250, 1000, 3000],
    beforeRetry: ({ lastError, req }) => {
      if (lastError?.name === "RRNLRetryMiddlewareError") {
        if (Cookies.get("csrftoken") == null) {
          // We are missing the CSRF Token cookie. We can get it by using GET on `/graphql/`.
          // We don't have to send anything, the response will have `Set-Cookie` header anyway.
          let request = new XMLHttpRequest();
          request.open("GET", "/graphql/", false);
          request.send(null);

          if (Cookies.get("csrftoken") == null) {
            console.log("Failed to get CSRF Token cookie!");
          } else {
            req.fetchOpts.headers["X-CSRFToken"] = Cookies.get("csrftoken");
          }
        }
      }
    },
  }),
  urlMiddleware({
    url: (req) => Promise.resolve("/graphql/"),
    headers: {
      "X-CSRFToken": Cookies.get("csrftoken") || "",
    },
  }),
  authMiddleware({
    token: "token",
    allowEmptyToken: true,
    tokenRefreshPromise: (req, res) => {
      try {
        const resp = JSON.parse(res.text);
        window.location = `${resp.login_url}?next=${window.location.pathname}`;
      } catch (e) {
        return Promise.reject("Unable to authorize");
      }
      return Promise.reject("Redirected to authorization page");
    },
  }),
];

if (process.env.NODE_ENV === "development") {
  relay_network_middlewares.push(loggerMiddleware());
}

const relay_network = new RelayNetworkLayer(relay_network_middlewares, {
  subscribeFn: subscribe,
});

const relay_store = new Store(new RecordSource());
const relay_environment = new Environment({
  network: relay_network,
  store: relay_store,
});

export default relay_environment;
