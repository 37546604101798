/**
 * @generated SignedSource<<7b6484ade3b246f157f5d21b0b26c8ac>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type PaperAutocompleteQuery$variables = {};
export type PaperAutocompleteQuery$data = {
  readonly allPapers: ReadonlyArray<{
    readonly id: any | null;
    readonly class: string | null;
    readonly weight: number | null;
    readonly size: {
      readonly width: number | null;
      readonly height: number | null;
    } | null;
  } | null> | null;
};
export type PaperAutocompleteQuery = {
  variables: PaperAutocompleteQuery$variables;
  response: PaperAutocompleteQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "PaperType",
    "kind": "LinkedField",
    "name": "allPapers",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "class",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "weight",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "SizeType",
        "kind": "LinkedField",
        "name": "size",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "width",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "height",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "PaperAutocompleteQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "PaperAutocompleteQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "9522ea7bc225971c4eb309b6c5622766",
    "id": null,
    "metadata": {},
    "name": "PaperAutocompleteQuery",
    "operationKind": "query",
    "text": "query PaperAutocompleteQuery {\n  allPapers {\n    id\n    class\n    weight\n    size {\n      width\n      height\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "cbef89fb26b54070f73e6f636a9042d7";

export default node;
