/**
 * @generated SignedSource<<7a1ed804cc33e8e272fd08868277b626>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UpdateJobSubproductDataMutationError = "CANNOT_MODIFY_SP_DATA_NOT_DRAFT" | "%future added value";
export type SubproductDataListNotFoundItemMutation$variables = {
  jobId: string;
  spDataId: string;
};
export type SubproductDataListNotFoundItemMutation$data = {
  readonly updateJobSubproductData: {
    readonly job: {
      readonly id: string;
      readonly " $fragmentSpreads": FragmentRefs<"RelatedSubproductsList_subproductData">;
    } | null;
    readonly errors: ReadonlyArray<UpdateJobSubproductDataMutationError> | null;
  } | null;
};
export type SubproductDataListNotFoundItemMutation = {
  variables: SubproductDataListNotFoundItemMutation$variables;
  response: SubproductDataListNotFoundItemMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "jobId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "spDataId"
  }
],
v1 = [
  {
    "fields": [
      {
        "kind": "Literal",
        "name": "action",
        "value": "UNASSIGN"
      },
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "jobId"
      },
      {
        "kind": "Variable",
        "name": "spDataId",
        "variableName": "spDataId"
      }
    ],
    "kind": "ObjectValue",
    "name": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "errors",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SubproductDataListNotFoundItemMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateJobSubproductDataFieldPayload",
        "kind": "LinkedField",
        "name": "updateJobSubproductData",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "JobNode",
            "kind": "LinkedField",
            "name": "job",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "RelatedSubproductsList_subproductData"
              }
            ],
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SubproductDataListNotFoundItemMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateJobSubproductDataFieldPayload",
        "kind": "LinkedField",
        "name": "updateJobSubproductData",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "JobNode",
            "kind": "LinkedField",
            "name": "job",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "SubproductDataNodeConnection",
                "kind": "LinkedField",
                "name": "subproductData",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SubproductDataNodeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "SubproductDataNode",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "pk",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "version",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "dataUrl",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "SubproductType",
                            "kind": "LinkedField",
                            "name": "subproduct",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "number",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "name",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "ProductType",
                                "kind": "LinkedField",
                                "name": "product",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "dueDate",
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "JobNodeConnection",
                            "kind": "LinkedField",
                            "name": "jobSet",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "JobNodeEdge",
                                "kind": "LinkedField",
                                "name": "edges",
                                "plural": true,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "JobNode",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                      (v2/*: any*/),
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "jdfId",
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "8518f102be13d690ca7d3112b78d51fd",
    "id": null,
    "metadata": {},
    "name": "SubproductDataListNotFoundItemMutation",
    "operationKind": "mutation",
    "text": "mutation SubproductDataListNotFoundItemMutation(\n  $jobId: ID!\n  $spDataId: ID!\n) {\n  updateJobSubproductData(input: {id: $jobId, spDataId: $spDataId, action: UNASSIGN}) {\n    job {\n      id\n      ...RelatedSubproductsList_subproductData\n    }\n    errors\n  }\n}\n\nfragment RelatedSubproductsList_subproductData on JobNode {\n  subproductData {\n    edges {\n      node {\n        ...SubproductDataListItem_subproductDataNode\n        id\n      }\n    }\n  }\n}\n\nfragment SubproductDataListItem_subproductDataNode on SubproductDataNode {\n  id\n  pk\n  version\n  dataUrl\n  subproduct {\n    number\n    name\n    product {\n      dueDate\n    }\n  }\n  jobSet {\n    edges {\n      node {\n        id\n        jdfId\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "f5cb8fa9e4dd42938f7b53b6a79193fa";

export default node;
