import React, { Suspense, useState } from "react";

import {
  Box,
  Card,
  Chip,
  List,
  ListItem,
  ListItemText,
  Skeleton,
  useMediaQuery,
} from "@mui/material";
import { PreloadedQuery, usePreloadedQuery } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import JobManagerAppBar from "../../navigation/JobManagerAppBar/JobManagerAppBar";
import ErrorBoundaryCard from "../../common/ErrorBoundaryCard";
import { SubproductDataListViewQuery } from "./__generated__/SubproductDataListViewQuery.graphql";
import SubproductDataList from "./SubproductDataList";
import SubproductDataListFilter, {
  SPDataState,
} from "./SubproductDataListFilter";

interface SubproductDataListCardProps {
  subproductDataListQueryRef: PreloadedQuery<SubproductDataListViewQuery>;
  searchString: string | null;
  filterDialogOpen?: boolean;
  onFilterDialogClose?: () => void;
}

function SubproductDataListCard({
  subproductDataListQueryRef,
  searchString,
  filterDialogOpen,
  onFilterDialogClose,
}: SubproductDataListCardProps) {
  const queryData = usePreloadedQuery(
    graphql`
      query SubproductDataListViewQuery(
        $search: String
        $searchNumber: String
        $returnAssignedToJobs: Boolean
        $returnUnassignedToJobs: Boolean
      ) {
        ...SubproductDataList_subproductData
      }
    `,
    subproductDataListQueryRef
  );

  const [filterState, setFilterState] = useState<Set<SPDataState>>(
    new Set<SPDataState>([SPDataState.ASSIGNED, SPDataState.UNASSIGNED])
  );

  return (
    <Box
      sx={{
        maxWidth: "1340px",
        margin: "16px auto",
      }}
    >
      <Card
        variant="outlined"
        sx={{
          maxWidth: "600px",
          margin: "0 auto",
          bgcolor: "background.paper",
        }}
      >
        <SubproductDataList
          queryData={queryData}
          searchString={searchString}
          filterState={filterState}
        />
      </Card>
      <SubproductDataListFilter
        filterState={filterState}
        setFilterState={setFilterState}
        filterDialogOpen={filterDialogOpen}
        onFilterDialogClose={onFilterDialogClose}
      />
    </Box>
  );
}

interface SubproductDataListViewProps {
  subproductDataListQueryRef: PreloadedQuery<SubproductDataListViewQuery>;
  toggleDrawer: (
    open: boolean
  ) => (event: React.KeyboardEvent | React.MouseEvent) => void;
}

export function SubproductDataListContentSkeleton() {
  return (
    <List>
      {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((key: number) => (
        <ListItem
          key={key}
          secondaryAction={
            <Skeleton variant="circular" width={32} height={32} />
          }
        >
          <ListItemText primary={<Skeleton />} secondary={<Skeleton />} />
          <Skeleton sx={{ marginLeft: "16px", marginRight: "16px" }}>
            <Chip
              sx={{
                width: "80px",
              }}
            />
          </Skeleton>
        </ListItem>
      ))}
    </List>
  );
}

function SubproductDataListSkeleton() {
  return (
    <Box
      sx={{
        maxWidth: "1340px",
        margin: "16px auto",
      }}
    >
      <Card
        variant="outlined"
        sx={{
          maxWidth: "600px",
          margin: "0 auto",
          bgcolor: "background.paper",
        }}
      >
        <SubproductDataListContentSkeleton />
      </Card>
    </Box>
  );
}

export default function SubproductDataListView({
  subproductDataListQueryRef,
  toggleDrawer,
}: SubproductDataListViewProps) {
  const [searchState, setSearchState] = useState<string | null>(null);

  const [filterDialogOpen, setFilterDialogOpen] = useState(false);
  const showFilterAsDialog = !useMediaQuery("(min-width:1100px)");

  return (
    <>
      <JobManagerAppBar
        toggleDrawer={toggleDrawer}
        onSearchInputChange={(str) => {
          setSearchState(str === "" ? null : str);
        }}
        onFilterButtonTap={
          showFilterAsDialog ? () => setFilterDialogOpen(true) : undefined
        }
      />
      <Suspense fallback={<SubproductDataListSkeleton />}>
        <ErrorBoundaryCard>
          <SubproductDataListCard
            subproductDataListQueryRef={subproductDataListQueryRef}
            searchString={searchState}
            filterDialogOpen={showFilterAsDialog ? filterDialogOpen : undefined}
            onFilterDialogClose={() => setFilterDialogOpen(false)}
          />
        </ErrorBoundaryCard>
      </Suspense>
    </>
  );
}
