import { useTranslation } from "react-i18next";
import {
  Dialog,
  DialogTitle,
  List,
  ListItemButton,
  Menu,
  MenuItem,
} from "@mui/material";
import React from "react";

interface LanguageMenuProps {
  open: boolean;
  onClose: () => void;
  anchorElement: HTMLElement | null;
  showAsDialog?: boolean;
}

export default function LanguageMenu({
  open,
  onClose,
  anchorElement,
  showAsDialog,
}: LanguageMenuProps) {
  const { t, i18n } = useTranslation();

  const handleSetLanguage = (lang: "en-US" | "sk-SK") => () => {
    i18n.changeLanguage(lang);
    onClose();
  };

  if (showAsDialog) {
    return (
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>{t("Language")}</DialogTitle>
        <List>
          <ListItemButton
            selected={i18n.language === "en-US"}
            onClick={handleSetLanguage("en-US")}
          >
            English
          </ListItemButton>
          <ListItemButton
            selected={i18n.language === "sk-SK"}
            onClick={handleSetLanguage("sk-SK")}
          >
            Slovenčina
          </ListItemButton>
        </List>
      </Dialog>
    );
  }

  return (
    <Menu open={open} onClose={onClose} anchorEl={anchorElement}>
      <MenuItem
        selected={i18n.language === "en-US"}
        onClick={handleSetLanguage("en-US")}
      >
        English
      </MenuItem>
      <MenuItem
        selected={i18n.language === "sk-SK"}
        onClick={handleSetLanguage("sk-SK")}
      >
        Slovenčina
      </MenuItem>
    </Menu>
  );
}
