/**
 * @generated SignedSource<<d2e241ba9ef38a1a580ffc5cfef98d42>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SubproductDataSelectorListQuery$variables = {
  search?: string | null;
  searchNumber?: string | null;
  returnAssignedToJobs?: boolean | null;
  returnUnassignedToJobs?: boolean | null;
};
export type SubproductDataSelectorListQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"SubproductDataList_subproductData">;
};
export type SubproductDataSelectorListQuery = {
  variables: SubproductDataSelectorListQuery$variables;
  response: SubproductDataSelectorListQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "returnAssignedToJobs"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "returnUnassignedToJobs"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "search"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "searchNumber"
},
v4 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 15
  },
  {
    "kind": "Variable",
    "name": "returnAssignedToJobs",
    "variableName": "returnAssignedToJobs"
  },
  {
    "kind": "Variable",
    "name": "returnNotAssignedToJobs",
    "variableName": "returnUnassignedToJobs"
  },
  {
    "kind": "Variable",
    "name": "subproductNameIcontains",
    "variableName": "search"
  },
  {
    "kind": "Variable",
    "name": "subproductNumber",
    "variableName": "searchNumber"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "SubproductDataSelectorListQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "SubproductDataList_subproductData"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v3/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "SubproductDataSelectorListQuery",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "SubproductDataConnection",
        "kind": "LinkedField",
        "name": "allActiveSubproductData",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "SubproductDataEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "SubproductDataNode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "pk",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "version",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "dataUrl",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SubproductType",
                    "kind": "LinkedField",
                    "name": "subproduct",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "number",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ProductType",
                        "kind": "LinkedField",
                        "name": "product",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "dueDate",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "JobNodeConnection",
                    "kind": "LinkedField",
                    "name": "jobSet",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "JobNodeEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "JobNode",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v5/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "jdfId",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v4/*: any*/),
        "filters": [
          "subproductNameIcontains",
          "subproductNumber",
          "returnAssignedToJobs",
          "returnNotAssignedToJobs"
        ],
        "handle": "connection",
        "key": "SubproductDataList_allActiveSubproductData",
        "kind": "LinkedHandle",
        "name": "allActiveSubproductData"
      }
    ]
  },
  "params": {
    "cacheID": "ec883cce6df1e27ccdb22a5c3b816e48",
    "id": null,
    "metadata": {},
    "name": "SubproductDataSelectorListQuery",
    "operationKind": "query",
    "text": "query SubproductDataSelectorListQuery(\n  $search: String\n  $searchNumber: String\n  $returnAssignedToJobs: Boolean\n  $returnUnassignedToJobs: Boolean\n) {\n  ...SubproductDataList_subproductData\n}\n\nfragment SubproductDataListItem_subproductDataNode on SubproductDataNode {\n  id\n  pk\n  version\n  dataUrl\n  subproduct {\n    number\n    name\n    product {\n      dueDate\n    }\n  }\n  jobSet {\n    edges {\n      node {\n        id\n        jdfId\n      }\n    }\n  }\n}\n\nfragment SubproductDataList_subproductData on Query {\n  allActiveSubproductData(first: 15, subproductNameIcontains: $search, subproductNumber: $searchNumber, returnAssignedToJobs: $returnAssignedToJobs, returnNotAssignedToJobs: $returnUnassignedToJobs) {\n    edges {\n      node {\n        id\n        ...SubproductDataListItem_subproductDataNode\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "58e66916f0dfb47f8b796e0eab2a2572";

export default node;
