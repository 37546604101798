/**
 * @generated SignedSource<<1bcfe3d2a887a84f652a8c8cc6928022>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type JobState = "DRAFT" | "READY" | "IN_PROGRESS" | "DONE" | "%future added value";
export type JobListPaginationQuery$variables = {
  count?: number | null;
  cursor?: string | null;
  pk?: number | null;
  search?: string | null;
  states?: ReadonlyArray<JobState> | null;
};
export type JobListPaginationQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"JobListViewComponent_jobs">;
};
export type JobListPaginationQuery = {
  variables: JobListPaginationQuery$variables;
  response: JobListPaginationQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": 30,
    "kind": "LocalArgument",
    "name": "count"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cursor"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "pk"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "search"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "states"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "cursor"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count"
  },
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "pk"
  },
  {
    "kind": "Variable",
    "name": "name_Icontains",
    "variableName": "search"
  },
  {
    "kind": "Variable",
    "name": "state",
    "variableName": "states"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "JobListPaginationQuery",
    "selections": [
      {
        "args": [
          {
            "kind": "Variable",
            "name": "count",
            "variableName": "count"
          },
          {
            "kind": "Variable",
            "name": "cursor",
            "variableName": "cursor"
          }
        ],
        "kind": "FragmentSpread",
        "name": "JobListViewComponent_jobs"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "JobListPaginationQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "JobNodeConnection",
        "kind": "LinkedField",
        "name": "allJobs",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "JobNodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "JobNode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "pk",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "jdfId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "state",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "JobStateCountType",
            "kind": "LinkedField",
            "name": "jobStates",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "stateKey",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "stateCount",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "stateTotalCount",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v1/*: any*/),
        "filters": [
          "state",
          "name_Icontains",
          "id"
        ],
        "handle": "connection",
        "key": "JobList_allJobs",
        "kind": "LinkedHandle",
        "name": "allJobs"
      }
    ]
  },
  "params": {
    "cacheID": "c24b7256710e7ba9fe6d788a2fbc53a7",
    "id": null,
    "metadata": {},
    "name": "JobListPaginationQuery",
    "operationKind": "query",
    "text": "query JobListPaginationQuery(\n  $count: Int = 30\n  $cursor: String\n  $pk: Float\n  $search: String\n  $states: [JobState!]\n) {\n  ...JobListViewComponent_jobs_1G22uz\n}\n\nfragment JobListViewComponent_jobs_1G22uz on Query {\n  allJobs(first: $count, after: $cursor, state: $states, name_Icontains: $search, id: $pk) {\n    edges {\n      node {\n        pk\n        id\n        name\n        jdfId\n        state\n        __typename\n      }\n      cursor\n    }\n    jobStates {\n      stateKey\n      stateCount\n      stateTotalCount\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "4fec1c2b678ab77d5a851885da2c8f4f";

export default node;
