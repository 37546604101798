import { PreloadedQuery, useFragment, usePreloadedQuery } from "react-relay";
import { JobDetailViewSlowQuery } from "./__generated__/JobDetailViewSlowQuery.graphql";
import { useTranslation } from "react-i18next";
import graphql from "babel-plugin-relay/macro";
import InfoItem from "../../common/InfoItem";
import {
  requestedPaperToString,
  sizeToString,
} from "../../../utils/stringifiers";
import React from "react";
import { slowQuery } from "./JobDetailView";
import { JobPaperInfoItem_paper$key } from "./__generated__/JobPaperInfoItem_paper.graphql";
import { Typography } from "@mui/material";

interface JobPaperInfoItemProps {
  jobDetailSlowQueryRef: PreloadedQuery<JobDetailViewSlowQuery>;
}

export default function JobPaperInfoItem({
  jobDetailSlowQueryRef,
}: JobPaperInfoItemProps) {
  const { t } = useTranslation();
  const slowQueryResponse = usePreloadedQuery(slowQuery, jobDetailSlowQueryRef);
  const data = useFragment<JobPaperInfoItem_paper$key>(
    graphql`
      fragment JobPaperInfoItem_paper on JobNode {
        paper {
          id
          class
          weight
          size {
            width
            height
          }
        }
      }
    `,
    slowQueryResponse.job!
  );

  if (data.paper != null) {
    return (
      <InfoItem
        sm={8}
        xs={12}
        value={`${requestedPaperToString(data.paper)}, ${sizeToString(
          data.paper?.size
        )}`}
        description={t("Paper")}
      />
    );
  } else {
    const errorElement = (
      <Typography sx={{ color: "#e89641" }}>
        {t("Paper for this job can't be found in PPS")}
      </Typography>
    );
    return <InfoItem xs={8} value={errorElement} description={t("Paper")} />;
  }
}
