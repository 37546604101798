import { useTranslation } from "react-i18next";
import {
  getTaskMutationErrorLabels,
  getTaskTypeLabels,
} from "../../../__generatedEnumLabels";
import React, { useState } from "react";
import { useMutation } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Box,
  ListItem,
  ListItemText,
  Snackbar,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import intersperse from "../../../utils/intersperse";
import StatusChip from "../../common/StatusChip";
import {
  TaskListItemStateMutation,
  TaskMutationError,
} from "./__generated__/TaskListItemStateMutation.graphql";
import { TaskType, TaskState } from "./__generated__/TasksCard_tasks.graphql";
import queryErrorToMessages from "../../../utils/queryErrorToMessages";

export interface TaskListItemProps {
  task: {
    readonly id: string;
    readonly type: TaskType | null;
    readonly state: TaskState | null;
    readonly enabled: boolean | null;
  };
  jobIsReady: boolean;
}

export default function TaskListItem({ task }: TaskListItemProps) {
  const { t } = useTranslation();
  const taskTypeLabels = getTaskTypeLabels(t);
  const [errors, setErrors] = useState<readonly TaskMutationError[]>([]);
  const theme = useTheme();
  const narrowList = useMediaQuery(theme.breakpoints.down("sm"));

  const [commit, isInFlight] = useMutation<TaskListItemStateMutation>(
    graphql`
      mutation TaskListItemStateMutation($input: TaskMutationFieldInput!) {
        updateTask(input: $input) {
          task {
            id
            state
            job {
              id
              state
            }
          }
          errors
        }
      }
    `
  );

  const [errorSnackbar, setErrorSnackbar] = useState<{
    open: boolean;
    errorKeys: readonly string[];
  }>({ open: false, errorKeys: [] });

  let advanceStateProps: { nextState: TaskState; label: string } | null = null;
  if (task.state === "PENDING") {
    advanceStateProps = {
      nextState: "IN_PROGRESS",
      label: t("Mark as in progress"),
    };
  }
  if (task.state === "IN_PROGRESS") {
    advanceStateProps = { nextState: "DONE", label: t("Mark as done") };
  }

  const advanceStateButton =
    advanceStateProps == null ? null : (
      <LoadingButton
        loading={isInFlight}
        variant="outlined"
        size="small"
        sx={{ marginRight: "8px" }}
        onClick={() => {
          setErrors([]);
          commit({
            variables: {
              input: {
                id: task.id,
                state: advanceStateProps!.nextState,
              },
            },
            onCompleted: (data) => {
              if ((data.updateTask?.errors || []).length > 0) {
                setErrors(data.updateTask!.errors!);
              }
            },
            onError: (error) => {
              const errorKeys = queryErrorToMessages(error);
              console.log(error);
              setErrorSnackbar({
                open: true,
                errorKeys: [
                  errorKeys.error.primaryError,
                  errorKeys.error.secondaryError,
                ],
              });
            },
          });
        }}
      >
        {advanceStateProps.label}
      </LoadingButton>
    );

  const advanceAndStatus = (
    <Box
      sx={{
        margin: "auto",
        width: "fit-content",
      }}
    >
      {advanceStateButton}
      <StatusChip status={task.state!} />
    </Box>
  );

  return (
    <ListItem>
      <Snackbar
        open={errorSnackbar.open}
        autoHideDuration={5000}
        onClose={() =>
          setErrorSnackbar({
            ...errorSnackbar,
            open: false,
          })
        }
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={() =>
            setErrorSnackbar({
              ...errorSnackbar,
              open: false,
            })
          }
          severity="error"
          sx={{ width: "100%" }}
        >
          <p>{t("Error when changing task state")}</p>
          {errorSnackbar.errorKeys.map((key) => (
            <p key={key}>{t(key)}</p>
          ))}
        </Alert>
      </Snackbar>
      <ListItemText
        primary={taskTypeLabels.get(task.type!)}
        secondary={
          <>
            {intersperse(
              errors.map((error) => (
                <Typography
                  sx={{
                    fontSize: "0.85rem",
                    lineHeight: "1rem",
                    color: "#bf3f3f",
                  }}
                >
                  {getTaskMutationErrorLabels(t).get(error)}
                </Typography>
              )),
              <br />
            )}
            {narrowList ? advanceAndStatus : null}
          </>
        }
      />
      {narrowList ? null : advanceAndStatus}
    </ListItem>
  );
}
