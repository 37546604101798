/**
 * @generated SignedSource<<3906dbad0b18106fbde6852a36877822>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SubproductDataMutationInput = {
  version: string;
  dataUrl?: string | null;
  id?: string | null;
  clientMutationId?: string | null;
};
export type SubproductDataCardMutation$variables = {
  input: SubproductDataMutationInput;
};
export type SubproductDataCardMutation$data = {
  readonly updateSubproductData: {
    readonly subproductData: {
      readonly id: string;
      readonly dataUrl: string;
      readonly version: string;
    } | null;
    readonly errors: ReadonlyArray<{
      readonly field: string;
      readonly errors: ReadonlyArray<{
        readonly __typename: "BaseErrorType";
        readonly " $fragmentSpreads": FragmentRefs<"ErrorString_baseError">;
      } | {
        readonly __typename: "DjangoErrorType";
        readonly " $fragmentSpreads": FragmentRefs<"ErrorString_djangoError">;
      } | {
        readonly __typename: "NextcloudFieldErrorType";
        readonly " $fragmentSpreads": FragmentRefs<"ErrorString_nextcloudFieldError">;
      } | {
        readonly __typename: "UpdateSubproductDataMutationErrorType";
        readonly " $fragmentSpreads": FragmentRefs<"ErrorString_updateSubproductDataMutationError">;
      } | {
        // This will never be '%other', but we need some
        // value in case none of the concrete values match.
        readonly __typename: "%other";
      }>;
    }> | null;
  } | null;
};
export type SubproductDataCardMutation = {
  variables: SubproductDataCardMutation$variables;
  response: SubproductDataCardMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "SubproductDataNode",
  "kind": "LinkedField",
  "name": "subproductData",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dataUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "version",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "field",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "message",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SubproductDataCardMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SubproductDataMutationPayload",
        "kind": "LinkedField",
        "name": "updateSubproductData",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "SubproductDataMutationErrorType",
            "kind": "LinkedField",
            "name": "errors",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "errors",
                "plural": true,
                "selections": [
                  (v4/*: any*/),
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "ErrorString_baseError"
                      }
                    ],
                    "type": "BaseErrorType",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "ErrorString_djangoError"
                      }
                    ],
                    "type": "DjangoErrorType",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "ErrorString_nextcloudFieldError"
                      }
                    ],
                    "type": "NextcloudFieldErrorType",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "ErrorString_updateSubproductDataMutationError"
                      }
                    ],
                    "type": "UpdateSubproductDataMutationErrorType",
                    "abstractKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SubproductDataCardMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SubproductDataMutationPayload",
        "kind": "LinkedField",
        "name": "updateSubproductData",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "SubproductDataMutationErrorType",
            "kind": "LinkedField",
            "name": "errors",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "errors",
                "plural": true,
                "selections": [
                  (v4/*: any*/),
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v5/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "code",
                        "storageKey": null
                      }
                    ],
                    "type": "BaseErrorType",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v5/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "subtype",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/),
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "alias": "invalidErrorType",
                                "args": null,
                                "kind": "ScalarField",
                                "name": "type",
                                "storageKey": null
                              }
                            ],
                            "type": "InvalidErrorType",
                            "abstractKey": null
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "alias": "invalidChoiceType",
                                "args": null,
                                "kind": "ScalarField",
                                "name": "type",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "allowedValues",
                                "storageKey": null
                              }
                            ],
                            "type": "InvalidChoiceErrorType",
                            "abstractKey": null
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "alias": "limitErrorType",
                                "args": null,
                                "kind": "ScalarField",
                                "name": "type",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "limitValue",
                                "storageKey": null
                              }
                            ],
                            "type": "LimitValueErrorType",
                            "abstractKey": null
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "maxValue",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "minValue",
                                "storageKey": null
                              }
                            ],
                            "type": "OverflowValueErrorType",
                            "abstractKey": null
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "alias": "uniqeValueErrorType",
                                "args": null,
                                "kind": "ScalarField",
                                "name": "type",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "modelName",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "fieldLabels",
                                "storageKey": null
                              }
                            ],
                            "type": "UniqueValueErrorType",
                            "abstractKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "type": "DjangoErrorType",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "alias": "nextcloudErrorType",
                        "args": null,
                        "kind": "ScalarField",
                        "name": "type",
                        "storageKey": null
                      }
                    ],
                    "type": "NextcloudFieldErrorType",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "alias": "updateSPDataErrorType",
                        "args": null,
                        "kind": "ScalarField",
                        "name": "type",
                        "storageKey": null
                      }
                    ],
                    "type": "UpdateSubproductDataMutationErrorType",
                    "abstractKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "8bf60cf4a1a7991f91cca8b013a66b64",
    "id": null,
    "metadata": {},
    "name": "SubproductDataCardMutation",
    "operationKind": "mutation",
    "text": "mutation SubproductDataCardMutation(\n  $input: SubproductDataMutationInput!\n) {\n  updateSubproductData(input: $input) {\n    subproductData {\n      id\n      dataUrl\n      version\n    }\n    errors {\n      field\n      errors {\n        __typename\n        ... on BaseErrorType {\n          ...ErrorString_baseError\n        }\n        ... on DjangoErrorType {\n          ...ErrorString_djangoError\n        }\n        ... on NextcloudFieldErrorType {\n          ...ErrorString_nextcloudFieldError\n        }\n        ... on UpdateSubproductDataMutationErrorType {\n          ...ErrorString_updateSubproductDataMutationError\n        }\n      }\n    }\n  }\n}\n\nfragment ErrorString_baseError on BaseErrorType {\n  message\n  code\n}\n\nfragment ErrorString_djangoError on DjangoErrorType {\n  message\n  subtype {\n    __typename\n    ... on InvalidErrorType {\n      invalidErrorType: type\n    }\n    ... on InvalidChoiceErrorType {\n      invalidChoiceType: type\n      allowedValues\n    }\n    ... on LimitValueErrorType {\n      limitErrorType: type\n      limitValue\n    }\n    ... on OverflowValueErrorType {\n      maxValue\n      minValue\n    }\n    ... on UniqueValueErrorType {\n      uniqeValueErrorType: type\n      modelName\n      fieldLabels\n    }\n  }\n}\n\nfragment ErrorString_nextcloudFieldError on NextcloudFieldErrorType {\n  nextcloudErrorType: type\n}\n\nfragment ErrorString_updateSubproductDataMutationError on UpdateSubproductDataMutationErrorType {\n  updateSPDataErrorType: type\n}\n"
  }
};
})();

(node as any).hash = "5c2c84cd08c77416e8c6db3875aab7dd";

export default node;
