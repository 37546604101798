import {
  Box,
  Divider,
  List,
  ListItemButton,
  ListItemText,
  Skeleton,
  SwipeableDrawer,
  Typography,
} from "@mui/material";
import React from "react";
import { Suspense } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useMatch } from "react-router-dom";
import { useLazyLoadQuery } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import ErrorBoundary from "../common/ErrorBoundary";
import { JobManagerDrawerUserQuery } from "./__generated__/JobManagerDrawerUserQuery.graphql";

interface DrawerToggleProps {
  toggleDrawer: (
    open: boolean
  ) => (event: React.KeyboardEvent | React.MouseEvent) => void;
}

interface DrawerStateToggleProps extends DrawerToggleProps {
  drawerState: boolean;
}

const listItemTextSx = {
  fontSize: ".875rem",
  fontWeight: 500,
};

function DrawerHeaderTypography(props: React.PropsWithChildren<{}>) {
  return (
    <Typography
      variant="h3"
      sx={{
        fontSize: "1.25rem",
        fontWeight: 500,
      }}
    >
      {props.children}
    </Typography>
  );
}

function DrawerSubheaderTypography(props: React.PropsWithChildren<{}>) {
  return (
    <Typography
      variant="h6"
      sx={{
        fontSize: ".875rem",
        fontWeight: 400,
        color: "rgba(0, 0, 0, .6)",
      }}
    >
      {props.children}
    </Typography>
  );
}

function JobManagerDrawerContents({ toggleDrawer }: DrawerToggleProps) {
  const { t } = useTranslation();

  const user = useLazyLoadQuery<JobManagerDrawerUserQuery>(
    graphql`
      query JobManagerDrawerUserQuery {
        me {
          name
        }
      }
    `,
    {},
    { fetchPolicy: "store-or-network" }
  );

  return (
    <Box>
      <Box
        sx={{
          padding: "16px",
          paddingBottom: "4px",
        }}
      >
        <DrawerHeaderTypography>Job Manager</DrawerHeaderTypography>
        <DrawerSubheaderTypography>{user.me.name}</DrawerSubheaderTypography>
      </Box>
      <Divider variant="fullWidth" />
      <List>
        <ListItemButton
          selected={useMatch("/") !== null}
          component={NavLink}
          to="/"
          onClick={toggleDrawer(false)}
        >
          <ListItemText
            primary={t("Jobs")}
            primaryTypographyProps={{ sx: listItemTextSx }}
          />
        </ListItemButton>
        <ListItemButton
          selected={useMatch("/subproduct-data") !== null}
          component={NavLink}
          to="/subproduct-data"
          onClick={toggleDrawer(false)}
        >
          <ListItemText
            primary={t("Subproduct data")}
            primaryTypographyProps={{ sx: listItemTextSx }}
          />
        </ListItemButton>
        <ListItemButton
          selected={useMatch("/tasks") !== null}
          component={NavLink}
          to="/tasks"
          onClick={toggleDrawer(false)}
        >
          <ListItemText
            primary={t("Tasks")}
            primaryTypographyProps={{ sx: listItemTextSx }}
          />
        </ListItemButton>
      </List>
    </Box>
  );
}

function JobManagerDrawerSkeleton() {
  return (
    <Box>
      <Box
        sx={{
          padding: "16px",
          paddingBottom: "4px",
        }}
      >
        <DrawerHeaderTypography>
          <Skeleton />
        </DrawerHeaderTypography>
        <DrawerSubheaderTypography>
          <Skeleton />
        </DrawerSubheaderTypography>
      </Box>
      <Divider />
      <List>
        {[1, 2, 3].map((key: number) => (
          <ListItemButton key={key}>
            <Skeleton sx={{ width: "100%" }} />
          </ListItemButton>
        ))}
      </List>
    </Box>
  );
}

export default function JobManagerDrawer({
  toggleDrawer,
  drawerState,
}: DrawerStateToggleProps) {
  return (
    <SwipeableDrawer
      anchor="left"
      open={drawerState}
      onClose={toggleDrawer(false)}
      onOpen={toggleDrawer(true)}
    >
      <Box
        sx={{
          width: "256px",
        }}
      >
        <ErrorBoundary>
          {/* No fallback. We want to show the drawer only to logged-in users, and if the user query fails, we can't be
              certain that the user is logged in. Therefore, we will show nothing. */}
          <Suspense fallback={<JobManagerDrawerSkeleton />}>
            <JobManagerDrawerContents toggleDrawer={toggleDrawer} />
          </Suspense>
        </ErrorBoundary>
      </Box>
    </SwipeableDrawer>
  );
}
