import graphql from "babel-plugin-relay/macro";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { FragmentRefs } from "relay-runtime";
import { ErrorString_baseError$key } from "./__generated__/ErrorString_baseError.graphql";
import { ErrorString_djangoError$key } from "./__generated__/ErrorString_djangoError.graphql";
import { useFragment } from "react-relay";
import {
  getInvalidErrorDetailLabels,
  getInvalidValueErrorDetailLabels,
  getNextcloudFieldErrorDetailLabels,
  getUpdateJobMutationErrorDetailLabels,
  getUpdateSubproductDataErrorDetailLabels,
} from "../../__generatedEnumLabels";
import { ErrorString_updateJobMutationError$key } from "./__generated__/ErrorString_updateJobMutationError.graphql";
import { ErrorString_nextcloudFieldError$key } from "./__generated__/ErrorString_nextcloudFieldError.graphql";
import { ErrorString_updateSubproductDataMutationError$key } from "./__generated__/ErrorString_updateSubproductDataMutationError.graphql";

interface BaseErrorStringParams {
  fragmentRef: ErrorString_baseError$key;
}

function BaseErrorString({ fragmentRef }: BaseErrorStringParams) {
  const { t } = useTranslation();

  const data = useFragment(
    graphql`
      fragment ErrorString_baseError on BaseErrorType {
        message
        code
      }
    `,
    fragmentRef
  );

  return (
    <Typography>
      {t("Unexpected error") + data.message + " (" + data.code + ")"}
    </Typography>
  );
}

interface DjangoErrorStringParams {
  fragmentRef: ErrorString_djangoError$key;
}

function DjangoErrorString({ fragmentRef }: DjangoErrorStringParams) {
  const { t } = useTranslation();

  const data = useFragment(
    graphql`
      fragment ErrorString_djangoError on DjangoErrorType {
        message
        subtype {
          __typename
          ... on InvalidErrorType {
            invalidErrorType: type
          }
          ... on InvalidChoiceErrorType {
            invalidChoiceType: type
            allowedValues
          }
          ... on LimitValueErrorType {
            limitErrorType: type
            limitValue
          }
          ... on OverflowValueErrorType {
            maxValue
            minValue
          }
          ... on UniqueValueErrorType {
            uniqeValueErrorType: type
            modelName
            fieldLabels
          }
        }
      }
    `,
    fragmentRef
  );

  switch (data.subtype.__typename) {
    case "InvalidErrorType":
      return (
        <Typography>
          {getInvalidErrorDetailLabels(t).get(data.subtype.invalidErrorType)}
        </Typography>
      );
    case "InvalidChoiceErrorType":
      return (
        <Typography>
          {t("This choice is invalid.")}
          {data.subtype.allowedValues != null
            ? t("Valid choices are") + data.subtype.allowedValues.join(", ")
            : null}
        </Typography>
      );
    case "LimitValueErrorType":
      return (
        <Typography>
          {getInvalidValueErrorDetailLabels(t).get(
            data.subtype.limitErrorType
          ) +
            " " +
            data.subtype.limitValue}
        </Typography>
      );
    case "OverflowValueErrorType":
      return (
        <Typography>
          {t("This value must be between", {
            min: data.subtype.minValue,
            max: data.subtype.maxValue,
          })}
        </Typography>
      );
    case "UniqueValueErrorType":
      return (
        <Typography>
          {data.subtype.uniqeValueErrorType !== "UNIQUE_FOR_DATE"
            ? t("model already exists", {
                model: data.subtype.modelName,
                fieldLabels: data.subtype.fieldLabels,
              })
            : t("field_labels must be unique", {
                fieldLabels: data.subtype.fieldLabels,
              })}
        </Typography>
      );
    default:
      return <Typography>{t("Unexpected error") + data.message}</Typography>;
  }
}

interface NextcloudFieldErrorStringParams {
  fragmentRef: ErrorString_nextcloudFieldError$key;
}

function NextcloudFieldErrorString({
  fragmentRef,
}: NextcloudFieldErrorStringParams) {
  const { t } = useTranslation();

  const data = useFragment(
    graphql`
      fragment ErrorString_nextcloudFieldError on NextcloudFieldErrorType {
        nextcloudErrorType: type
      }
    `,
    fragmentRef
  );

  return (
    <Typography>
      {getNextcloudFieldErrorDetailLabels(t).get(data.nextcloudErrorType)}
    </Typography>
  );
}

interface UpdateJobMutationErrorStringParams {
  fragmentRef: ErrorString_updateJobMutationError$key;
}

function UpdateJobMutationErrorString({
  fragmentRef,
}: UpdateJobMutationErrorStringParams) {
  const { t } = useTranslation();

  const data = useFragment(
    graphql`
      fragment ErrorString_updateJobMutationError on UpdateJobMutationErrorType {
        updateJobErrorType: type
      }
    `,
    fragmentRef
  );

  return (
    <Typography>
      {getUpdateJobMutationErrorDetailLabels(t).get(data.updateJobErrorType)}
    </Typography>
  );
}

interface UpdateSubproductDataMutationErrorTypeParams {
  fragmentRef: ErrorString_updateSubproductDataMutationError$key;
}

function UpdateSubproductDataMutationErrorType({
  fragmentRef,
}: UpdateSubproductDataMutationErrorTypeParams) {
  const { t } = useTranslation();

  const data = useFragment(
    graphql`
      fragment ErrorString_updateSubproductDataMutationError on UpdateSubproductDataMutationErrorType {
        updateSPDataErrorType: type
      }
    `,
    fragmentRef
  );

  return (
    <Typography>
      {getUpdateSubproductDataErrorDetailLabels(t).get(
        data.updateSPDataErrorType
      )}
    </Typography>
  );
}

interface ErrorFragmentWithTypename {
  readonly __typename: string;
  readonly " $fragmentSpreads"?: any;
}

type BaseErrorType = {
  readonly __typename: "BaseErrorType";
  readonly " $fragmentSpreads": FragmentRefs<"ErrorString_baseError">;
};

type DjangoErrorType = {
  readonly __typename: "DjangoErrorType";
  readonly " $fragmentSpreads": FragmentRefs<"ErrorString_djangoError">;
};

type NextcloudFieldErrorType = {
  readonly __typename: "NextcloudFieldErrorType";
  readonly " $fragmentSpreads": FragmentRefs<"ErrorString_nextcloudFieldError">;
};

type UpdateJobMutationErrorType = {
  readonly __typename: "UpdateJobMutationErrorType";
  readonly " $fragmentSpreads": FragmentRefs<"ErrorString_updateJobMutationError">;
};

type SubproductDataMutationErrorType = {
  readonly __typename: "SubproductDataMutationErrorType";
  readonly " $fragmentSpreads": FragmentRefs<"ErrorString_updateSubproductDataMutationError">;
};

function isBaseErrorType(f: ErrorFragmentWithTypename): f is BaseErrorType {
  return f.__typename === "BaseErrorType";
}

function isDjangoErrorType(f: ErrorFragmentWithTypename): f is DjangoErrorType {
  return f.__typename === "DjangoErrorType";
}

function isNextcloudFieldErrorType(
  f: ErrorFragmentWithTypename
): f is NextcloudFieldErrorType {
  return f.__typename === "NextcloudFieldErrorType";
}

function isUpdateJobMutationErrorType(
  f: ErrorFragmentWithTypename
): f is UpdateJobMutationErrorType {
  return f.__typename === "UpdateJobMutationErrorType";
}

function isUpdateSubproductDataMutationErrorType(
  f: ErrorFragmentWithTypename
): f is SubproductDataMutationErrorType {
  return f.__typename === "SubproductDataMutationErrorType";
}

interface ErrorStringProps {
  errorRef: ErrorFragmentWithTypename;
}

export default function ErrorString({ errorRef }: ErrorStringProps) {
  const { t } = useTranslation();

  if (isBaseErrorType(errorRef)) {
    return <BaseErrorString fragmentRef={errorRef} />;
  }

  if (isDjangoErrorType(errorRef)) {
    return <DjangoErrorString fragmentRef={errorRef} />;
  }

  if (isNextcloudFieldErrorType(errorRef)) {
    return <NextcloudFieldErrorString fragmentRef={errorRef} />;
  }

  if (isUpdateJobMutationErrorType(errorRef)) {
    return <UpdateJobMutationErrorString fragmentRef={errorRef} />;
  }

  if (isUpdateSubproductDataMutationErrorType(errorRef)) {
    return <UpdateSubproductDataMutationErrorType fragmentRef={errorRef} />;
  }

  return <Typography>{t("Unknown error")}</Typography>;
}
