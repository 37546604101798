import {
  Avatar,
  Dialog,
  DialogTitle,
  Divider,
  List,
  ListItemButton,
  ListItemIcon,
  Menu,
  MenuItem,
} from "@mui/material";
import { Logout } from "@mui/icons-material";
import { ToolbarAccountIconQuery$data } from "./__generated__/ToolbarAccountIconQuery.graphql";
import { useTranslation } from "react-i18next";

interface AccountMenuProps {
  open: boolean;
  onClose: () => void;
  anchorEl: HTMLElement | null;
  user: ToolbarAccountIconQuery$data;
  showAsDialog?: boolean;
}

export default function AccountMenu({
  open,
  onClose,
  anchorEl,
  user,
  showAsDialog,
}: AccountMenuProps) {
  const { t } = useTranslation();

  const AccountMenuItem = showAsDialog ? ListItemButton : MenuItem;

  let logoutItem = null;

  if (user.me.logoutUrl != null) {
    logoutItem = (
      <AccountMenuItem
        onClick={() => {
          window.location.href = `${user.me.logoutUrl}?next=/`;
        }}
        href=""
      >
        <ListItemIcon>
          <Logout fontSize="small" />
        </ListItemIcon>
        {t("Logout")}
      </AccountMenuItem>
    );
  }

  const accountMenuContent = (
    <>
      <AccountMenuItem disabled href="">
        <ListItemIcon>
          <Avatar
            sx={{
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            }}
          />
        </ListItemIcon>
        {user.me.name}
      </AccountMenuItem>
      <Divider
        sx={{
          marginTop: "8px",
          marginBottom: "8px",
        }}
      />
      {logoutItem}
    </>
  );

  if (showAsDialog) {
    return (
      <Dialog open={open} onClose={onClose} fullWidth>
        <DialogTitle>{t("User")}</DialogTitle>
        <List>{accountMenuContent}</List>
      </Dialog>
    );
  }

  return (
    <Menu
      open={open}
      onClose={onClose}
      anchorEl={anchorEl}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: "visible",
          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
          mt: 1.5,
          "&:before": {
            content: '""',
            display: "block",
            position: "absolute",
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            bgcolor: "background.paper",
            transform: "translateY(-50%) rotate(45deg)",
            zIndex: 0,
          },
        },
      }}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
    >
      {accountMenuContent}
    </Menu>
  );
}
