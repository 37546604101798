import graphql from "babel-plugin-relay/macro";
import { JobDetailViewFastQuery$data } from "./__generated__/JobDetailViewFastQuery.graphql";
import { useTranslation } from "react-i18next";
import { useFragment, useSubscription } from "react-relay";
import React, { useMemo } from "react";
import {
  Card,
  CardContent,
  Chip,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  Skeleton,
} from "@mui/material";
import CardSection from "../../common/CardSection";
import intersperse from "../../../utils/intersperse";
import TaskListItem from "./TaskListItem";
import TaskToggleListItem from "./TaskToggleListItem";
import { TasksCard_tasks$key } from "./__generated__/TasksCard_tasks.graphql";

const taskStatusSubscription = graphql`
  subscription TasksCardSubscription($jobId: ID!) {
    tasks(job: $jobId) {
      id
      type
      state
    }
  }
`;

interface TasksCardProps {
  jobQueryResponse: JobDetailViewFastQuery$data;
  jobId: string;
  jobIsReady: boolean;
}

export default function TasksCard({
  jobQueryResponse,
  jobId,
  jobIsReady,
}: TasksCardProps) {
  const { t } = useTranslation();

  const data = useFragment<TasksCard_tasks$key>(
    graphql`
      fragment TasksCard_tasks on JobNode {
        taskSet {
          edges {
            node {
              id
              type
              state
              enabled
            }
          }
        }
      }
    `,
    jobQueryResponse.job!
  );

  const subscriptionConfig = useMemo(
    () => ({
      variables: { jobId },
      subscription: taskStatusSubscription,
    }),
    [jobId]
  );

  useSubscription(subscriptionConfig);

  return (
    <Card variant="outlined">
      <CardContent>
        <CardSection title={t("Tasks")} disablePaddingForIcon>
          <Grid
            item
            xs={12}
            sx={{ paddingLeft: "0 !important", paddingTop: "0 !important" }}
          >
            <List sx={{ width: "100%" }}>
              {intersperse(
                data.taskSet!.edges.map((edge) =>
                  jobIsReady ? (
                    <TaskListItem task={edge!.node!} jobIsReady={jobIsReady} />
                  ) : (
                    <TaskToggleListItem
                      task={edge!.node!}
                      jobIsReady={jobIsReady}
                    />
                  )
                ),
                <Divider />
              )}
            </List>
          </Grid>
        </CardSection>
      </CardContent>
    </Card>
  );
}

export function TasksCardSkeleton() {
  const { t } = useTranslation();

  return (
    <Card variant="outlined">
      <CardContent>
        <CardSection title={t("Tasks")} disablePaddingForIcon>
          <Grid
            item
            xs={12}
            sx={{ paddingLeft: "0 !important", paddingTop: "0 !important" }}
          >
            <List sx={{ width: "100%" }}>
              {intersperse(
                [1, 2, 3, 4, 5].map((key) => (
                  <ListItem key={key}>
                    <ListItemText primary={<Skeleton />} />
                    <Skeleton sx={{ marginLeft: "16px", marginRight: "16px" }}>
                      <Chip
                        sx={{
                          width: "80px",
                        }}
                      />
                    </Skeleton>
                  </ListItem>
                )),
                <Divider />
              )}
            </List>
          </Grid>
        </CardSection>
      </CardContent>
    </Card>
  );
}
