/**
 * @generated SignedSource<<77aa87386f7f77e24804230ab433e1ca>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RelatedSubproductsList_subproductData$data = {
  readonly subproductData: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly " $fragmentSpreads": FragmentRefs<"SubproductDataListItem_subproductDataNode">;
      } | null;
    } | null>;
  };
  readonly " $fragmentType": "RelatedSubproductsList_subproductData";
};
export type RelatedSubproductsList_subproductData$key = {
  readonly " $data"?: RelatedSubproductsList_subproductData$data;
  readonly " $fragmentSpreads": FragmentRefs<"RelatedSubproductsList_subproductData">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RelatedSubproductsList_subproductData",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "SubproductDataNodeConnection",
      "kind": "LinkedField",
      "name": "subproductData",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "SubproductDataNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "SubproductDataNode",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "SubproductDataListItem_subproductDataNode"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "JobNode",
  "abstractKey": null
};

(node as any).hash = "c0b224b04227a7025b0405f10d2ff1b2";

export default node;
