/* eslint-disable */
import { TFunction } from "react-i18next";
import { TaskType } from "./components/tasks/__generated__/TaskListViewQuery.graphql";
import {
  Binding,
  Color,
  Machine,
  WorkStyle,
  ConversionWarningType,
} from "./components/job/JobDetail/__generated__/JobDataCard_params.graphql";
import {
  InvalidChoiceErrorDetail,
  InvalidErrorDetail,
  InvalidValueErrorDetail,
  UniqueValueErrorDetail,
} from "./components/common/__generated__/ErrorString_djangoError.graphql";
import {
  UpdateJobMutationErrorDetail
} from "./components/common/__generated__/ErrorString_updateJobMutationError.graphql";
import {
  NextcloudFieldErrorDetail
} from "./components/common/__generated__/ErrorString_nextcloudFieldError.graphql";
import {
  UpdateSubproductDataErrorDetail
} from "./components/common/__generated__/ErrorString_updateSubproductDataMutationError.graphql";
import {
  CreateOrOverwriteJobMutationError
} from "./components/job/JobDetail/__generated__/RelatedSubproductsListPopulateDataMutation.graphql";
import {
  UpdateJobSubproductDataMutationError
} from "./components/job/JobDetail/__generated__/SubproductDataSelectorListMutation.graphql"
import {
  TaskMutationError
} from "./components/job/JobDetail/__generated__/TaskToggleListItemEnabledMutation.graphql"
import {
  SubproductDataCloneMutationError
} from "./components/subproduct-data/__generated__/SubproductDataCloneDialogMutation.graphql";

// @ts-ignore
export const getBindingLabels = (t: TFunction<"translation">) => {
  return new Map<Binding, string>([
    ["NONE", t("Binding__NONE")],
    ["OTABIND", t("Binding__OTABIND")],
    ["OTHER", t("Binding__OTHER")],
    ["SWISSBIND", t("Binding__SWISSBIND")],
    ["V1", t("Binding__V1")],
    ["V2", t("Binding__V2")],
    ["V4", t("Binding__V4")],
    ["V8", t("Binding__V8")],
  ]);
};

// @ts-ignore
export const colorLabels = new Map<Color, string>([
  ["CYAN", "Cyan"],
  ["KEY", "Key"],
  ["MAGENTA", "Magenta"],
  ["PANTONE_100_C", "Pantone 100 C"],
  ["PANTONE_101_C", "Pantone 101 C"],
  ["PANTONE_102_C", "Pantone 102 C"],
  ["PANTONE_103_C", "Pantone 103 C"],
  ["PANTONE_104_C", "Pantone 104 C"],
  ["PANTONE_105_C", "Pantone 105 C"],
  ["PANTONE_106_C", "Pantone 106 C"],
  ["PANTONE_107_C", "Pantone 107 C"],
  ["PANTONE_108_C", "Pantone 108 C"],
  ["PANTONE_109_C", "Pantone 109 C"],
  ["PANTONE_110_C", "Pantone 110 C"],
  ["PANTONE_111_C", "Pantone 111 C"],
  ["PANTONE_112_C", "Pantone 112 C"],
  ["PANTONE_113_C", "Pantone 113 C"],
  ["PANTONE_114_C", "Pantone 114 C"],
  ["PANTONE_115_C", "Pantone 115 C"],
  ["PANTONE_116_C", "Pantone 116 C"],
  ["PANTONE_117_C", "Pantone 117 C"],
  ["PANTONE_118_C", "Pantone 118 C"],
  ["PANTONE_119_C", "Pantone 119 C"],
  ["PANTONE_120_C", "Pantone 120 C"],
  ["PANTONE_121_C", "Pantone 121 C"],
  ["PANTONE_122_C", "Pantone 122 C"],
  ["PANTONE_123_C", "Pantone 123 C"],
  ["PANTONE_124_C", "Pantone 124 C"],
  ["PANTONE_125_C", "Pantone 125 C"],
  ["PANTONE_126_C", "Pantone 126 C"],
  ["PANTONE_127_C", "Pantone 127 C"],
  ["PANTONE_128_C", "Pantone 128 C"],
  ["PANTONE_129_C", "Pantone 129 C"],
  ["PANTONE_130_C", "Pantone 130 C"],
  ["PANTONE_131_C", "Pantone 131 C"],
  ["PANTONE_132_C", "Pantone 132 C"],
  ["PANTONE_133_C", "Pantone 133 C"],
  ["PANTONE_134_C", "Pantone 134 C"],
  ["PANTONE_135_C", "Pantone 135 C"],
  ["PANTONE_136_C", "Pantone 136 C"],
  ["PANTONE_137_C", "Pantone 137 C"],
  ["PANTONE_138_C", "Pantone 138 C"],
  ["PANTONE_139_C", "Pantone 139 C"],
  ["PANTONE_140_C", "Pantone 140 C"],
  ["PANTONE_141_C", "Pantone 141 C"],
  ["PANTONE_142_C", "Pantone 142 C"],
  ["PANTONE_143_C", "Pantone 143 C"],
  ["PANTONE_144_C", "Pantone 144 C"],
  ["PANTONE_145_C", "Pantone 145 C"],
  ["PANTONE_146_C", "Pantone 146 C"],
  ["PANTONE_147_C", "Pantone 147 C"],
  ["PANTONE_148_C", "Pantone 148 C"],
  ["PANTONE_149_C", "Pantone 149 C"],
  ["PANTONE_150_C", "Pantone 150 C"],
  ["PANTONE_151_C", "Pantone 151 C"],
  ["PANTONE_152_C", "Pantone 152 C"],
  ["PANTONE_153_C", "Pantone 153 C"],
  ["PANTONE_154_C", "Pantone 154 C"],
  ["PANTONE_155_C", "Pantone 155 C"],
  ["PANTONE_156_C", "Pantone 156 C"],
  ["PANTONE_157_C", "Pantone 157 C"],
  ["PANTONE_158_C", "Pantone 158 C"],
  ["PANTONE_159_C", "Pantone 159 C"],
  ["PANTONE_160_C", "Pantone 160 C"],
  ["PANTONE_161_C", "Pantone 161 C"],
  ["PANTONE_162_C", "Pantone 162 C"],
  ["PANTONE_163_C", "Pantone 163 C"],
  ["PANTONE_164_C", "Pantone 164 C"],
  ["PANTONE_165_C", "Pantone 165 C"],
  ["PANTONE_166_C", "Pantone 166 C"],
  ["PANTONE_167_C", "Pantone 167 C"],
  ["PANTONE_168_C", "Pantone 168 C"],
  ["PANTONE_169_C", "Pantone 169 C"],
  ["PANTONE_170_C", "Pantone 170 C"],
  ["PANTONE_171_C", "Pantone 171 C"],
  ["PANTONE_172_C", "Pantone 172 C"],
  ["PANTONE_173_C", "Pantone 173 C"],
  ["PANTONE_174_C", "Pantone 174 C"],
  ["PANTONE_175_C", "Pantone 175 C"],
  ["PANTONE_176_C", "Pantone 176 C"],
  ["PANTONE_177_C", "Pantone 177 C"],
  ["PANTONE_178_C", "Pantone 178 C"],
  ["PANTONE_179_C", "Pantone 179 C"],
  ["PANTONE_180_C", "Pantone 180 C"],
  ["PANTONE_181_C", "Pantone 181 C"],
  ["PANTONE_182_C", "Pantone 182 C"],
  ["PANTONE_183_C", "Pantone 183 C"],
  ["PANTONE_184_C", "Pantone 184 C"],
  ["PANTONE_185_C", "Pantone 185 C"],
  ["PANTONE_186_C", "Pantone 186 C"],
  ["PANTONE_187_C", "Pantone 187 C"],
  ["PANTONE_188_C", "Pantone 188 C"],
  ["PANTONE_189_C", "Pantone 189 C"],
  ["PANTONE_190_C", "Pantone 190 C"],
  ["PANTONE_191_C", "Pantone 191 C"],
  ["PANTONE_192_C", "Pantone 192 C"],
  ["PANTONE_193_C", "Pantone 193 C"],
  ["PANTONE_194_C", "Pantone 194 C"],
  ["PANTONE_195_C", "Pantone 195 C"],
  ["PANTONE_196_C", "Pantone 196 C"],
  ["PANTONE_197_C", "Pantone 197 C"],
  ["PANTONE_198_C", "Pantone 198 C"],
  ["PANTONE_199_C", "Pantone 199 C"],
  ["PANTONE_200_C", "Pantone 200 C"],
  ["PANTONE_201_C", "Pantone 201 C"],
  ["PANTONE_202_C", "Pantone 202 C"],
  ["PANTONE_203_C", "Pantone 203 C"],
  ["PANTONE_204_C", "Pantone 204 C"],
  ["PANTONE_205_C", "Pantone 205 C"],
  ["PANTONE_206_C", "Pantone 206 C"],
  ["PANTONE_207_C", "Pantone 207 C"],
  ["PANTONE_208_C", "Pantone 208 C"],
  ["PANTONE_209_C", "Pantone 209 C"],
  ["PANTONE_210_C", "Pantone 210 C"],
  ["PANTONE_211_C", "Pantone 211 C"],
  ["PANTONE_212_C", "Pantone 212 C"],
  ["PANTONE_213_C", "Pantone 213 C"],
  ["PANTONE_214_C", "Pantone 214 C"],
  ["PANTONE_215_C", "Pantone 215 C"],
  ["PANTONE_216_C", "Pantone 216 C"],
  ["PANTONE_217_C", "Pantone 217 C"],
  ["PANTONE_218_C", "Pantone 218 C"],
  ["PANTONE_219_C", "Pantone 219 C"],
  ["PANTONE_220_C", "Pantone 220 C"],
  ["PANTONE_221_C", "Pantone 221 C"],
  ["PANTONE_222_C", "Pantone 222 C"],
  ["PANTONE_223_C", "Pantone 223 C"],
  ["PANTONE_224_C", "Pantone 224 C"],
  ["PANTONE_225_C", "Pantone 225 C"],
  ["PANTONE_226_C", "Pantone 226 C"],
  ["PANTONE_227_C", "Pantone 227 C"],
  ["PANTONE_228_C", "Pantone 228 C"],
  ["PANTONE_229_C", "Pantone 229 C"],
  ["PANTONE_230_C", "Pantone 230 C"],
  ["PANTONE_231_C", "Pantone 231 C"],
  ["PANTONE_232_C", "Pantone 232 C"],
  ["PANTONE_233_C", "Pantone 233 C"],
  ["PANTONE_234_C", "Pantone 234 C"],
  ["PANTONE_235_C", "Pantone 235 C"],
  ["PANTONE_236_C", "Pantone 236 C"],
  ["PANTONE_237_C", "Pantone 237 C"],
  ["PANTONE_238_C", "Pantone 238 C"],
  ["PANTONE_239_C", "Pantone 239 C"],
  ["PANTONE_240_C", "Pantone 240 C"],
  ["PANTONE_241_C", "Pantone 241 C"],
  ["PANTONE_242_C", "Pantone 242 C"],
  ["PANTONE_243_C", "Pantone 243 C"],
  ["PANTONE_244_C", "Pantone 244 C"],
  ["PANTONE_245_C", "Pantone 245 C"],
  ["PANTONE_246_C", "Pantone 246 C"],
  ["PANTONE_247_C", "Pantone 247 C"],
  ["PANTONE_248_C", "Pantone 248 C"],
  ["PANTONE_249_C", "Pantone 249 C"],
  ["PANTONE_250_C", "Pantone 250 C"],
  ["PANTONE_251_C", "Pantone 251 C"],
  ["PANTONE_252_C", "Pantone 252 C"],
  ["PANTONE_253_C", "Pantone 253 C"],
  ["PANTONE_254_C", "Pantone 254 C"],
  ["PANTONE_255_C", "Pantone 255 C"],
  ["PANTONE_256_C", "Pantone 256 C"],
  ["PANTONE_257_C", "Pantone 257 C"],
  ["PANTONE_258_C", "Pantone 258 C"],
  ["PANTONE_259_C", "Pantone 259 C"],
  ["PANTONE_260_C", "Pantone 260 C"],
  ["PANTONE_261_C", "Pantone 261 C"],
  ["PANTONE_262_C", "Pantone 262 C"],
  ["PANTONE_263_C", "Pantone 263 C"],
  ["PANTONE_264_C", "Pantone 264 C"],
  ["PANTONE_265_C", "Pantone 265 C"],
  ["PANTONE_266_C", "Pantone 266 C"],
  ["PANTONE_267_C", "Pantone 267 C"],
  ["PANTONE_268_C", "Pantone 268 C"],
  ["PANTONE_269_C", "Pantone 269 C"],
  ["PANTONE_270_C", "Pantone 270 C"],
  ["PANTONE_271_C", "Pantone 271 C"],
  ["PANTONE_272_C", "Pantone 272 C"],
  ["PANTONE_273_C", "Pantone 273 C"],
  ["PANTONE_274_C", "Pantone 274 C"],
  ["PANTONE_275_C", "Pantone 275 C"],
  ["PANTONE_276_C", "Pantone 276 C"],
  ["PANTONE_277_C", "Pantone 277 C"],
  ["PANTONE_278_C", "Pantone 278 C"],
  ["PANTONE_279_C", "Pantone 279 C"],
  ["PANTONE_280_C", "Pantone 280 C"],
  ["PANTONE_281_C", "Pantone 281 C"],
  ["PANTONE_282_C", "Pantone 282 C"],
  ["PANTONE_283_C", "Pantone 283 C"],
  ["PANTONE_284_C", "Pantone 284 C"],
  ["PANTONE_285_C", "Pantone 285 C"],
  ["PANTONE_286_C", "Pantone 286 C"],
  ["PANTONE_287_C", "Pantone 287 C"],
  ["PANTONE_288_C", "Pantone 288 C"],
  ["PANTONE_289_C", "Pantone 289 C"],
  ["PANTONE_290_C", "Pantone 290 C"],
  ["PANTONE_291_C", "Pantone 291 C"],
  ["PANTONE_292_C", "Pantone 292 C"],
  ["PANTONE_293_C", "Pantone 293 C"],
  ["PANTONE_294_C", "Pantone 294 C"],
  ["PANTONE_295_C", "Pantone 295 C"],
  ["PANTONE_296_C", "Pantone 296 C"],
  ["PANTONE_297_C", "Pantone 297 C"],
  ["PANTONE_298_C", "Pantone 298 C"],
  ["PANTONE_299_C", "Pantone 299 C"],
  ["PANTONE_300_C", "Pantone 300 C"],
  ["PANTONE_301_C", "Pantone 301 C"],
  ["PANTONE_302_C", "Pantone 302 C"],
  ["PANTONE_303_C", "Pantone 303 C"],
  ["PANTONE_304_C", "Pantone 304 C"],
  ["PANTONE_305_C", "Pantone 305 C"],
  ["PANTONE_306_C", "Pantone 306 C"],
  ["PANTONE_307_C", "Pantone 307 C"],
  ["PANTONE_308_C", "Pantone 308 C"],
  ["PANTONE_309_C", "Pantone 309 C"],
  ["PANTONE_310_C", "Pantone 310 C"],
  ["PANTONE_311_C", "Pantone 311 C"],
  ["PANTONE_312_C", "Pantone 312 C"],
  ["PANTONE_313_C", "Pantone 313 C"],
  ["PANTONE_314_C", "Pantone 314 C"],
  ["PANTONE_315_C", "Pantone 315 C"],
  ["PANTONE_316_C", "Pantone 316 C"],
  ["PANTONE_317_C", "Pantone 317 C"],
  ["PANTONE_318_C", "Pantone 318 C"],
  ["PANTONE_319_C", "Pantone 319 C"],
  ["PANTONE_320_C", "Pantone 320 C"],
  ["PANTONE_321_C", "Pantone 321 C"],
  ["PANTONE_322_C", "Pantone 322 C"],
  ["PANTONE_323_C", "Pantone 323 C"],
  ["PANTONE_324_C", "Pantone 324 C"],
  ["PANTONE_325_C", "Pantone 325 C"],
  ["PANTONE_326_C", "Pantone 326 C"],
  ["PANTONE_327_C", "Pantone 327 C"],
  ["PANTONE_328_C", "Pantone 328 C"],
  ["PANTONE_329_C", "Pantone 329 C"],
  ["PANTONE_330_C", "Pantone 330 C"],
  ["PANTONE_331_C", "Pantone 331 C"],
  ["PANTONE_332_C", "Pantone 332 C"],
  ["PANTONE_333_C", "Pantone 333 C"],
  ["PANTONE_334_C", "Pantone 334 C"],
  ["PANTONE_335_C", "Pantone 335 C"],
  ["PANTONE_336_C", "Pantone 336 C"],
  ["PANTONE_337_C", "Pantone 337 C"],
  ["PANTONE_338_C", "Pantone 338 C"],
  ["PANTONE_339_C", "Pantone 339 C"],
  ["PANTONE_340_C", "Pantone 340 C"],
  ["PANTONE_341_C", "Pantone 341 C"],
  ["PANTONE_342_C", "Pantone 342 C"],
  ["PANTONE_343_C", "Pantone 343 C"],
  ["PANTONE_344_C", "Pantone 344 C"],
  ["PANTONE_345_C", "Pantone 345 C"],
  ["PANTONE_346_C", "Pantone 346 C"],
  ["PANTONE_347_C", "Pantone 347 C"],
  ["PANTONE_348_C", "Pantone 348 C"],
  ["PANTONE_349_C", "Pantone 349 C"],
  ["PANTONE_350_C", "Pantone 350 C"],
  ["PANTONE_351_C", "Pantone 351 C"],
  ["PANTONE_352_C", "Pantone 352 C"],
  ["PANTONE_353_C", "Pantone 353 C"],
  ["PANTONE_354_C", "Pantone 354 C"],
  ["PANTONE_355_C", "Pantone 355 C"],
  ["PANTONE_356_C", "Pantone 356 C"],
  ["PANTONE_357_C", "Pantone 357 C"],
  ["PANTONE_358_C", "Pantone 358 C"],
  ["PANTONE_359_C", "Pantone 359 C"],
  ["PANTONE_360_C", "Pantone 360 C"],
  ["PANTONE_361_C", "Pantone 361 C"],
  ["PANTONE_362_C", "Pantone 362 C"],
  ["PANTONE_363_C", "Pantone 363 C"],
  ["PANTONE_364_C", "Pantone 364 C"],
  ["PANTONE_365_C", "Pantone 365 C"],
  ["PANTONE_366_C", "Pantone 366 C"],
  ["PANTONE_367_C", "Pantone 367 C"],
  ["PANTONE_368_C", "Pantone 368 C"],
  ["PANTONE_369_C", "Pantone 369 C"],
  ["PANTONE_370_C", "Pantone 370 C"],
  ["PANTONE_371_C", "Pantone 371 C"],
  ["PANTONE_372_C", "Pantone 372 C"],
  ["PANTONE_373_C", "Pantone 373 C"],
  ["PANTONE_374_C", "Pantone 374 C"],
  ["PANTONE_375_C", "Pantone 375 C"],
  ["PANTONE_376_C", "Pantone 376 C"],
  ["PANTONE_377_C", "Pantone 377 C"],
  ["PANTONE_378_C", "Pantone 378 C"],
  ["PANTONE_379_C", "Pantone 379 C"],
  ["PANTONE_380_C", "Pantone 380 C"],
  ["PANTONE_381_C", "Pantone 381 C"],
  ["PANTONE_382_C", "Pantone 382 C"],
  ["PANTONE_383_C", "Pantone 383 C"],
  ["PANTONE_384_C", "Pantone 384 C"],
  ["PANTONE_385_C", "Pantone 385 C"],
  ["PANTONE_386_C", "Pantone 386 C"],
  ["PANTONE_387_C", "Pantone 387 C"],
  ["PANTONE_388_C", "Pantone 388 C"],
  ["PANTONE_389_C", "Pantone 389 C"],
  ["PANTONE_390_C", "Pantone 390 C"],
  ["PANTONE_391_C", "Pantone 391 C"],
  ["PANTONE_392_C", "Pantone 392 C"],
  ["PANTONE_393_C", "Pantone 393 C"],
  ["PANTONE_394_C", "Pantone 394 C"],
  ["PANTONE_395_C", "Pantone 395 C"],
  ["PANTONE_396_C", "Pantone 396 C"],
  ["PANTONE_397_C", "Pantone 397 C"],
  ["PANTONE_398_C", "Pantone 398 C"],
  ["PANTONE_399_C", "Pantone 399 C"],
  ["PANTONE_400_C", "Pantone 400 C"],
  ["PANTONE_401_C", "Pantone 401 C"],
  ["PANTONE_402_C", "Pantone 402 C"],
  ["PANTONE_403_C", "Pantone 403 C"],
  ["PANTONE_404_C", "Pantone 404 C"],
  ["PANTONE_405_C", "Pantone 405 C"],
  ["PANTONE_406_C", "Pantone 406 C"],
  ["PANTONE_407_C", "Pantone 407 C"],
  ["PANTONE_408_C", "Pantone 408 C"],
  ["PANTONE_409_C", "Pantone 409 C"],
  ["PANTONE_410_C", "Pantone 410 C"],
  ["PANTONE_411_C", "Pantone 411 C"],
  ["PANTONE_412_C", "Pantone 412 C"],
  ["PANTONE_413_C", "Pantone 413 C"],
  ["PANTONE_414_C", "Pantone 414 C"],
  ["PANTONE_415_C", "Pantone 415 C"],
  ["PANTONE_416_C", "Pantone 416 C"],
  ["PANTONE_417_C", "Pantone 417 C"],
  ["PANTONE_418_C", "Pantone 418 C"],
  ["PANTONE_419_C", "Pantone 419 C"],
  ["PANTONE_420_C", "Pantone 420 C"],
  ["PANTONE_421_C", "Pantone 421 C"],
  ["PANTONE_422_C", "Pantone 422 C"],
  ["PANTONE_423_C", "Pantone 423 C"],
  ["PANTONE_424_C", "Pantone 424 C"],
  ["PANTONE_425_C", "Pantone 425 C"],
  ["PANTONE_426_C", "Pantone 426 C"],
  ["PANTONE_427_C", "Pantone 427 C"],
  ["PANTONE_428_C", "Pantone 428 C"],
  ["PANTONE_429_C", "Pantone 429 C"],
  ["PANTONE_430_C", "Pantone 430 C"],
  ["PANTONE_431_C", "Pantone 431 C"],
  ["PANTONE_432_C", "Pantone 432 C"],
  ["PANTONE_433_C", "Pantone 433 C"],
  ["PANTONE_434_C", "Pantone 434 C"],
  ["PANTONE_435_C", "Pantone 435 C"],
  ["PANTONE_436_C", "Pantone 436 C"],
  ["PANTONE_437_C", "Pantone 437 C"],
  ["PANTONE_438_C", "Pantone 438 C"],
  ["PANTONE_439_C", "Pantone 439 C"],
  ["PANTONE_440_C", "Pantone 440 C"],
  ["PANTONE_441_C", "Pantone 441 C"],
  ["PANTONE_442_C", "Pantone 442 C"],
  ["PANTONE_443_C", "Pantone 443 C"],
  ["PANTONE_444_C", "Pantone 444 C"],
  ["PANTONE_445_C", "Pantone 445 C"],
  ["PANTONE_446_C", "Pantone 446 C"],
  ["PANTONE_447_C", "Pantone 447 C"],
  ["PANTONE_448_C", "Pantone 448 C"],
  ["PANTONE_449_C", "Pantone 449 C"],
  ["PANTONE_450_C", "Pantone 450 C"],
  ["PANTONE_451_C", "Pantone 451 C"],
  ["PANTONE_452_C", "Pantone 452 C"],
  ["PANTONE_453_C", "Pantone 453 C"],
  ["PANTONE_454_C", "Pantone 454 C"],
  ["PANTONE_455_C", "Pantone 455 C"],
  ["PANTONE_456_C", "Pantone 456 C"],
  ["PANTONE_457_C", "Pantone 457 C"],
  ["PANTONE_458_C", "Pantone 458 C"],
  ["PANTONE_459_C", "Pantone 459 C"],
  ["PANTONE_460_C", "Pantone 460 C"],
  ["PANTONE_461_C", "Pantone 461 C"],
  ["PANTONE_462_C", "Pantone 462 C"],
  ["PANTONE_463_C", "Pantone 463 C"],
  ["PANTONE_464_C", "Pantone 464 C"],
  ["PANTONE_465_C", "Pantone 465 C"],
  ["PANTONE_466_C", "Pantone 466 C"],
  ["PANTONE_467_C", "Pantone 467 C"],
  ["PANTONE_468_C", "Pantone 468 C"],
  ["PANTONE_469_C", "Pantone 469 C"],
  ["PANTONE_470_C", "Pantone 470 C"],
  ["PANTONE_471_C", "Pantone 471 C"],
  ["PANTONE_472_C", "Pantone 472 C"],
  ["PANTONE_473_C", "Pantone 473 C"],
  ["PANTONE_474_C", "Pantone 474 C"],
  ["PANTONE_475_C", "Pantone 475 C"],
  ["PANTONE_476_C", "Pantone 476 C"],
  ["PANTONE_477_C", "Pantone 477 C"],
  ["PANTONE_478_C", "Pantone 478 C"],
  ["PANTONE_479_C", "Pantone 479 C"],
  ["PANTONE_480_C", "Pantone 480 C"],
  ["PANTONE_481_C", "Pantone 481 C"],
  ["PANTONE_482_C", "Pantone 482 C"],
  ["PANTONE_483_C", "Pantone 483 C"],
  ["PANTONE_484_C", "Pantone 484 C"],
  ["PANTONE_485_C", "Pantone 485 C"],
  ["PANTONE_486_C", "Pantone 486 C"],
  ["PANTONE_487_C", "Pantone 487 C"],
  ["PANTONE_488_C", "Pantone 488 C"],
  ["PANTONE_489_C", "Pantone 489 C"],
  ["PANTONE_490_C", "Pantone 490 C"],
  ["PANTONE_491_C", "Pantone 491 C"],
  ["PANTONE_492_C", "Pantone 492 C"],
  ["PANTONE_493_C", "Pantone 493 C"],
  ["PANTONE_494_C", "Pantone 494 C"],
  ["PANTONE_495_C", "Pantone 495 C"],
  ["PANTONE_496_C", "Pantone 496 C"],
  ["PANTONE_497_C", "Pantone 497 C"],
  ["PANTONE_498_C", "Pantone 498 C"],
  ["PANTONE_499_C", "Pantone 499 C"],
  ["PANTONE_500_C", "Pantone 500 C"],
  ["PANTONE_501_C", "Pantone 501 C"],
  ["PANTONE_502_C", "Pantone 502 C"],
  ["PANTONE_503_C", "Pantone 503 C"],
  ["PANTONE_504_C", "Pantone 504 C"],
  ["PANTONE_505_C", "Pantone 505 C"],
  ["PANTONE_506_C", "Pantone 506 C"],
  ["PANTONE_507_C", "Pantone 507 C"],
  ["PANTONE_508_C", "Pantone 508 C"],
  ["PANTONE_509_C", "Pantone 509 C"],
  ["PANTONE_510_C", "Pantone 510 C"],
  ["PANTONE_511_C", "Pantone 511 C"],
  ["PANTONE_512_C", "Pantone 512 C"],
  ["PANTONE_513_C", "Pantone 513 C"],
  ["PANTONE_514_C", "Pantone 514 C"],
  ["PANTONE_515_C", "Pantone 515 C"],
  ["PANTONE_516_C", "Pantone 516 C"],
  ["PANTONE_517_C", "Pantone 517 C"],
  ["PANTONE_518_C", "Pantone 518 C"],
  ["PANTONE_519_C", "Pantone 519 C"],
  ["PANTONE_520_C", "Pantone 520 C"],
  ["PANTONE_521_C", "Pantone 521 C"],
  ["PANTONE_522_C", "Pantone 522 C"],
  ["PANTONE_523_C", "Pantone 523 C"],
  ["PANTONE_524_C", "Pantone 524 C"],
  ["PANTONE_525_C", "Pantone 525 C"],
  ["PANTONE_526_C", "Pantone 526 C"],
  ["PANTONE_527_C", "Pantone 527 C"],
  ["PANTONE_528_C", "Pantone 528 C"],
  ["PANTONE_529_C", "Pantone 529 C"],
  ["PANTONE_530_C", "Pantone 530 C"],
  ["PANTONE_531_C", "Pantone 531 C"],
  ["PANTONE_532_C", "Pantone 532 C"],
  ["PANTONE_533_C", "Pantone 533 C"],
  ["PANTONE_534_C", "Pantone 534 C"],
  ["PANTONE_535_C", "Pantone 535 C"],
  ["PANTONE_536_C", "Pantone 536 C"],
  ["PANTONE_537_C", "Pantone 537 C"],
  ["PANTONE_538_C", "Pantone 538 C"],
  ["PANTONE_539_C", "Pantone 539 C"],
  ["PANTONE_540_C", "Pantone 540 C"],
  ["PANTONE_541_C", "Pantone 541 C"],
  ["PANTONE_542_C", "Pantone 542 C"],
  ["PANTONE_543_C", "Pantone 543 C"],
  ["PANTONE_544_C", "Pantone 544 C"],
  ["PANTONE_545_C", "Pantone 545 C"],
  ["PANTONE_546_C", "Pantone 546 C"],
  ["PANTONE_547_C", "Pantone 547 C"],
  ["PANTONE_548_C", "Pantone 548 C"],
  ["PANTONE_549_C", "Pantone 549 C"],
  ["PANTONE_550_C", "Pantone 550 C"],
  ["PANTONE_551_C", "Pantone 551 C"],
  ["PANTONE_552_C", "Pantone 552 C"],
  ["PANTONE_553_C", "Pantone 553 C"],
  ["PANTONE_554_C", "Pantone 554 C"],
  ["PANTONE_555_C", "Pantone 555 C"],
  ["PANTONE_556_C", "Pantone 556 C"],
  ["PANTONE_557_C", "Pantone 557 C"],
  ["PANTONE_558_C", "Pantone 558 C"],
  ["PANTONE_559_C", "Pantone 559 C"],
  ["PANTONE_560_C", "Pantone 560 C"],
  ["PANTONE_561_C", "Pantone 561 C"],
  ["PANTONE_562_C", "Pantone 562 C"],
  ["PANTONE_563_C", "Pantone 563 C"],
  ["PANTONE_564_C", "Pantone 564 C"],
  ["PANTONE_565_C", "Pantone 565 C"],
  ["PANTONE_566_C", "Pantone 566 C"],
  ["PANTONE_567_C", "Pantone 567 C"],
  ["PANTONE_568_C", "Pantone 568 C"],
  ["PANTONE_569_C", "Pantone 569 C"],
  ["PANTONE_570_C", "Pantone 570 C"],
  ["PANTONE_571_C", "Pantone 571 C"],
  ["PANTONE_572_C", "Pantone 572 C"],
  ["PANTONE_573_C", "Pantone 573 C"],
  ["PANTONE_574_C", "Pantone 574 C"],
  ["PANTONE_575_C", "Pantone 575 C"],
  ["PANTONE_576_C", "Pantone 576 C"],
  ["PANTONE_577_C", "Pantone 577 C"],
  ["PANTONE_578_C", "Pantone 578 C"],
  ["PANTONE_579_C", "Pantone 579 C"],
  ["PANTONE_580_C", "Pantone 580 C"],
  ["PANTONE_581_C", "Pantone 581 C"],
  ["PANTONE_582_C", "Pantone 582 C"],
  ["PANTONE_583_C", "Pantone 583 C"],
  ["PANTONE_584_C", "Pantone 584 C"],
  ["PANTONE_585_C", "Pantone 585 C"],
  ["PANTONE_586_C", "Pantone 586 C"],
  ["PANTONE_587_C", "Pantone 587 C"],
  ["PANTONE_600_C", "Pantone 600 C"],
  ["PANTONE_601_C", "Pantone 601 C"],
  ["PANTONE_602_C", "Pantone 602 C"],
  ["PANTONE_603_C", "Pantone 603 C"],
  ["PANTONE_604_C", "Pantone 604 C"],
  ["PANTONE_605_C", "Pantone 605 C"],
  ["PANTONE_606_C", "Pantone 606 C"],
  ["PANTONE_607_C", "Pantone 607 C"],
  ["PANTONE_608_C", "Pantone 608 C"],
  ["PANTONE_609_C", "Pantone 609 C"],
  ["PANTONE_610_C", "Pantone 610 C"],
  ["PANTONE_611_C", "Pantone 611 C"],
  ["PANTONE_612_C", "Pantone 612 C"],
  ["PANTONE_613_C", "Pantone 613 C"],
  ["PANTONE_614_C", "Pantone 614 C"],
  ["PANTONE_615_C", "Pantone 615 C"],
  ["PANTONE_616_C", "Pantone 616 C"],
  ["PANTONE_617_C", "Pantone 617 C"],
  ["PANTONE_618_C", "Pantone 618 C"],
  ["PANTONE_619_C", "Pantone 619 C"],
  ["PANTONE_620_C", "Pantone 620 C"],
  ["PANTONE_621_C", "Pantone 621 C"],
  ["PANTONE_622_C", "Pantone 622 C"],
  ["PANTONE_623_C", "Pantone 623 C"],
  ["PANTONE_624_C", "Pantone 624 C"],
  ["PANTONE_625_C", "Pantone 625 C"],
  ["PANTONE_626_C", "Pantone 626 C"],
  ["PANTONE_627_C", "Pantone 627 C"],
  ["PANTONE_628_C", "Pantone 628 C"],
  ["PANTONE_629_C", "Pantone 629 C"],
  ["PANTONE_630_C", "Pantone 630 C"],
  ["PANTONE_631_C", "Pantone 631 C"],
  ["PANTONE_632_C", "Pantone 632 C"],
  ["PANTONE_633_C", "Pantone 633 C"],
  ["PANTONE_634_C", "Pantone 634 C"],
  ["PANTONE_635_C", "Pantone 635 C"],
  ["PANTONE_636_C", "Pantone 636 C"],
  ["PANTONE_637_C", "Pantone 637 C"],
  ["PANTONE_638_C", "Pantone 638 C"],
  ["PANTONE_639_C", "Pantone 639 C"],
  ["PANTONE_640_C", "Pantone 640 C"],
  ["PANTONE_641_C", "Pantone 641 C"],
  ["PANTONE_642_C", "Pantone 642 C"],
  ["PANTONE_643_C", "Pantone 643 C"],
  ["PANTONE_644_C", "Pantone 644 C"],
  ["PANTONE_645_C", "Pantone 645 C"],
  ["PANTONE_646_C", "Pantone 646 C"],
  ["PANTONE_647_C", "Pantone 647 C"],
  ["PANTONE_648_C", "Pantone 648 C"],
  ["PANTONE_649_C", "Pantone 649 C"],
  ["PANTONE_650_C", "Pantone 650 C"],
  ["PANTONE_651_C", "Pantone 651 C"],
  ["PANTONE_652_C", "Pantone 652 C"],
  ["PANTONE_653_C", "Pantone 653 C"],
  ["PANTONE_654_C", "Pantone 654 C"],
  ["PANTONE_655_C", "Pantone 655 C"],
  ["PANTONE_656_C", "Pantone 656 C"],
  ["PANTONE_657_C", "Pantone 657 C"],
  ["PANTONE_658_C", "Pantone 658 C"],
  ["PANTONE_659_C", "Pantone 659 C"],
  ["PANTONE_660_C", "Pantone 660 C"],
  ["PANTONE_661_C", "Pantone 661 C"],
  ["PANTONE_662_C", "Pantone 662 C"],
  ["PANTONE_663_C", "Pantone 663 C"],
  ["PANTONE_664_C", "Pantone 664 C"],
  ["PANTONE_665_C", "Pantone 665 C"],
  ["PANTONE_666_C", "Pantone 666 C"],
  ["PANTONE_667_C", "Pantone 667 C"],
  ["PANTONE_668_C", "Pantone 668 C"],
  ["PANTONE_669_C", "Pantone 669 C"],
  ["PANTONE_670_C", "Pantone 670 C"],
  ["PANTONE_671_C", "Pantone 671 C"],
  ["PANTONE_672_C", "Pantone 672 C"],
  ["PANTONE_673_C", "Pantone 673 C"],
  ["PANTONE_674_C", "Pantone 674 C"],
  ["PANTONE_675_C", "Pantone 675 C"],
  ["PANTONE_676_C", "Pantone 676 C"],
  ["PANTONE_677_C", "Pantone 677 C"],
  ["PANTONE_678_C", "Pantone 678 C"],
  ["PANTONE_679_C", "Pantone 679 C"],
  ["PANTONE_680_C", "Pantone 680 C"],
  ["PANTONE_681_C", "Pantone 681 C"],
  ["PANTONE_682_C", "Pantone 682 C"],
  ["PANTONE_683_C", "Pantone 683 C"],
  ["PANTONE_684_C", "Pantone 684 C"],
  ["PANTONE_685_C", "Pantone 685 C"],
  ["PANTONE_686_C", "Pantone 686 C"],
  ["PANTONE_687_C", "Pantone 687 C"],
  ["PANTONE_688_C", "Pantone 688 C"],
  ["PANTONE_689_C", "Pantone 689 C"],
  ["PANTONE_690_C", "Pantone 690 C"],
  ["PANTONE_691_C", "Pantone 691 C"],
  ["PANTONE_692_C", "Pantone 692 C"],
  ["PANTONE_693_C", "Pantone 693 C"],
  ["PANTONE_694_C", "Pantone 694 C"],
  ["PANTONE_695_C", "Pantone 695 C"],
  ["PANTONE_696_C", "Pantone 696 C"],
  ["PANTONE_697_C", "Pantone 697 C"],
  ["PANTONE_698_C", "Pantone 698 C"],
  ["PANTONE_699_C", "Pantone 699 C"],
  ["PANTONE_700_C", "Pantone 700 C"],
  ["PANTONE_701_C", "Pantone 701 C"],
  ["PANTONE_702_C", "Pantone 702 C"],
  ["PANTONE_703_C", "Pantone 703 C"],
  ["PANTONE_704_C", "Pantone 704 C"],
  ["PANTONE_705_C", "Pantone 705 C"],
  ["PANTONE_706_C", "Pantone 706 C"],
  ["PANTONE_707_C", "Pantone 707 C"],
  ["PANTONE_708_C", "Pantone 708 C"],
  ["PANTONE_709_C", "Pantone 709 C"],
  ["PANTONE_710_C", "Pantone 710 C"],
  ["PANTONE_711_C", "Pantone 711 C"],
  ["PANTONE_712_C", "Pantone 712 C"],
  ["PANTONE_713_C", "Pantone 713 C"],
  ["PANTONE_714_C", "Pantone 714 C"],
  ["PANTONE_715_C", "Pantone 715 C"],
  ["PANTONE_716_C", "Pantone 716 C"],
  ["PANTONE_717_C", "Pantone 717 C"],
  ["PANTONE_718_C", "Pantone 718 C"],
  ["PANTONE_719_C", "Pantone 719 C"],
  ["PANTONE_720_C", "Pantone 720 C"],
  ["PANTONE_721_C", "Pantone 721 C"],
  ["PANTONE_722_C", "Pantone 722 C"],
  ["PANTONE_723_C", "Pantone 723 C"],
  ["PANTONE_724_C", "Pantone 724 C"],
  ["PANTONE_725_C", "Pantone 725 C"],
  ["PANTONE_726_C", "Pantone 726 C"],
  ["PANTONE_727_C", "Pantone 727 C"],
  ["PANTONE_728_C", "Pantone 728 C"],
  ["PANTONE_729_C", "Pantone 729 C"],
  ["PANTONE_730_C", "Pantone 730 C"],
  ["PANTONE_731_C", "Pantone 731 C"],
  ["PANTONE_732_C", "Pantone 732 C"],
  ["PANTONE_801_C", "Pantone 801 C"],
  ["PANTONE_802_C", "Pantone 802 C"],
  ["PANTONE_803_C", "Pantone 803 C"],
  ["PANTONE_804_C", "Pantone 804 C"],
  ["PANTONE_805_C", "Pantone 805 C"],
  ["PANTONE_806_C", "Pantone 806 C"],
  ["PANTONE_807_C", "Pantone 807 C"],
  ["PANTONE_871_C", "Pantone 871 C"],
  ["PANTONE_872_C", "Pantone 872 C"],
  ["PANTONE_873_C", "Pantone 873 C"],
  ["PANTONE_874_C", "Pantone 874 C"],
  ["PANTONE_875_C", "Pantone 875 C"],
  ["PANTONE_876_C", "Pantone 876 C"],
  ["PANTONE_877_C", "Pantone 877 C"],
  ["PANTONE_1205_C", "Pantone 1205 C"],
  ["PANTONE_1215_C", "Pantone 1215 C"],
  ["PANTONE_1225_C", "Pantone 1225 C"],
  ["PANTONE_1235_C", "Pantone 1235 C"],
  ["PANTONE_1245_C", "Pantone 1245 C"],
  ["PANTONE_1255_C", "Pantone 1255 C"],
  ["PANTONE_1265_C", "Pantone 1265 C"],
  ["PANTONE_1345_C", "Pantone 1345 C"],
  ["PANTONE_1355_C", "Pantone 1355 C"],
  ["PANTONE_1365_C", "Pantone 1365 C"],
  ["PANTONE_1375_C", "Pantone 1375 C"],
  ["PANTONE_1385_C", "Pantone 1385 C"],
  ["PANTONE_1395_C", "Pantone 1395 C"],
  ["PANTONE_1405_C", "Pantone 1405 C"],
  ["PANTONE_1485_C", "Pantone 1485 C"],
  ["PANTONE_1495_C", "Pantone 1495 C"],
  ["PANTONE_1505_C", "Pantone 1505 C"],
  ["PANTONE_1525_C", "Pantone 1525 C"],
  ["PANTONE_1535_C", "Pantone 1535 C"],
  ["PANTONE_1545_C", "Pantone 1545 C"],
  ["PANTONE_1555_C", "Pantone 1555 C"],
  ["PANTONE_1565_C", "Pantone 1565 C"],
  ["PANTONE_1575_C", "Pantone 1575 C"],
  ["PANTONE_1585_C", "Pantone 1585 C"],
  ["PANTONE_1595_C", "Pantone 1595 C"],
  ["PANTONE_1605_C", "Pantone 1605 C"],
  ["PANTONE_1615_C", "Pantone 1615 C"],
  ["PANTONE_1625_C", "Pantone 1625 C"],
  ["PANTONE_1635_C", "Pantone 1635 C"],
  ["PANTONE_1645_C", "Pantone 1645 C"],
  ["PANTONE_1655_C", "Pantone 1655 C"],
  ["PANTONE_1665_C", "Pantone 1665 C"],
  ["PANTONE_1675_C", "Pantone 1675 C"],
  ["PANTONE_1685_C", "Pantone 1685 C"],
  ["PANTONE_1765_C", "Pantone 1765 C"],
  ["PANTONE_1767_C", "Pantone 1767 C"],
  ["PANTONE_1775_C", "Pantone 1775 C"],
  ["PANTONE_1777_C", "Pantone 1777 C"],
  ["PANTONE_1785_C", "Pantone 1785 C"],
  ["PANTONE_1787_C", "Pantone 1787 C"],
  ["PANTONE_1788_C", "Pantone 1788 C"],
  ["PANTONE_1795_C", "Pantone 1795 C"],
  ["PANTONE_1797_C", "Pantone 1797 C"],
  ["PANTONE_1805_C", "Pantone 1805 C"],
  ["PANTONE_1807_C", "Pantone 1807 C"],
  ["PANTONE_1815_C", "Pantone 1815 C"],
  ["PANTONE_1817_C", "Pantone 1817 C"],
  ["PANTONE_1895_C", "Pantone 1895 C"],
  ["PANTONE_1905_C", "Pantone 1905 C"],
  ["PANTONE_1915_C", "Pantone 1915 C"],
  ["PANTONE_1925_C", "Pantone 1925 C"],
  ["PANTONE_1935_C", "Pantone 1935 C"],
  ["PANTONE_1945_C", "Pantone 1945 C"],
  ["PANTONE_1955_C", "Pantone 1955 C"],
  ["PANTONE_2001_C", "Pantone 2001 C"],
  ["PANTONE_2002_C", "Pantone 2002 C"],
  ["PANTONE_2003_C", "Pantone 2003 C"],
  ["PANTONE_2004_C", "Pantone 2004 C"],
  ["PANTONE_2005_C", "Pantone 2005 C"],
  ["PANTONE_2006_C", "Pantone 2006 C"],
  ["PANTONE_2007_C", "Pantone 2007 C"],
  ["PANTONE_2008_C", "Pantone 2008 C"],
  ["PANTONE_2009_C", "Pantone 2009 C"],
  ["PANTONE_2010_C", "Pantone 2010 C"],
  ["PANTONE_2011_C", "Pantone 2011 C"],
  ["PANTONE_2012_C", "Pantone 2012 C"],
  ["PANTONE_2013_C", "Pantone 2013 C"],
  ["PANTONE_2014_C", "Pantone 2014 C"],
  ["PANTONE_2015_C", "Pantone 2015 C"],
  ["PANTONE_2016_C", "Pantone 2016 C"],
  ["PANTONE_2017_C", "Pantone 2017 C"],
  ["PANTONE_2018_C", "Pantone 2018 C"],
  ["PANTONE_2019_C", "Pantone 2019 C"],
  ["PANTONE_2020_C", "Pantone 2020 C"],
  ["PANTONE_2021_C", "Pantone 2021 C"],
  ["PANTONE_2022_C", "Pantone 2022 C"],
  ["PANTONE_2023_C", "Pantone 2023 C"],
  ["PANTONE_2024_C", "Pantone 2024 C"],
  ["PANTONE_2025_C", "Pantone 2025 C"],
  ["PANTONE_2026_C", "Pantone 2026 C"],
  ["PANTONE_2027_C", "Pantone 2027 C"],
  ["PANTONE_2028_C", "Pantone 2028 C"],
  ["PANTONE_2029_C", "Pantone 2029 C"],
  ["PANTONE_2030_C", "Pantone 2030 C"],
  ["PANTONE_2031_C", "Pantone 2031 C"],
  ["PANTONE_2032_C", "Pantone 2032 C"],
  ["PANTONE_2033_C", "Pantone 2033 C"],
  ["PANTONE_2034_C", "Pantone 2034 C"],
  ["PANTONE_2035_C", "Pantone 2035 C"],
  ["PANTONE_2036_C", "Pantone 2036 C"],
  ["PANTONE_2037_C", "Pantone 2037 C"],
  ["PANTONE_2038_C", "Pantone 2038 C"],
  ["PANTONE_2039_C", "Pantone 2039 C"],
  ["PANTONE_2040_C", "Pantone 2040 C"],
  ["PANTONE_2041_C", "Pantone 2041 C"],
  ["PANTONE_2042_C", "Pantone 2042 C"],
  ["PANTONE_2043_C", "Pantone 2043 C"],
  ["PANTONE_2044_C", "Pantone 2044 C"],
  ["PANTONE_2045_C", "Pantone 2045 C"],
  ["PANTONE_2046_C", "Pantone 2046 C"],
  ["PANTONE_2047_C", "Pantone 2047 C"],
  ["PANTONE_2048_C", "Pantone 2048 C"],
  ["PANTONE_2049_C", "Pantone 2049 C"],
  ["PANTONE_2050_C", "Pantone 2050 C"],
  ["PANTONE_2051_C", "Pantone 2051 C"],
  ["PANTONE_2052_C", "Pantone 2052 C"],
  ["PANTONE_2053_C", "Pantone 2053 C"],
  ["PANTONE_2054_C", "Pantone 2054 C"],
  ["PANTONE_2055_C", "Pantone 2055 C"],
  ["PANTONE_2056_C", "Pantone 2056 C"],
  ["PANTONE_2057_C", "Pantone 2057 C"],
  ["PANTONE_2058_C", "Pantone 2058 C"],
  ["PANTONE_2059_C", "Pantone 2059 C"],
  ["PANTONE_2060_C", "Pantone 2060 C"],
  ["PANTONE_2061_C", "Pantone 2061 C"],
  ["PANTONE_2062_C", "Pantone 2062 C"],
  ["PANTONE_2063_C", "Pantone 2063 C"],
  ["PANTONE_2064_C", "Pantone 2064 C"],
  ["PANTONE_2065_C", "Pantone 2065 C"],
  ["PANTONE_2066_C", "Pantone 2066 C"],
  ["PANTONE_2067_C", "Pantone 2067 C"],
  ["PANTONE_2068_C", "Pantone 2068 C"],
  ["PANTONE_2069_C", "Pantone 2069 C"],
  ["PANTONE_2070_C", "Pantone 2070 C"],
  ["PANTONE_2071_C", "Pantone 2071 C"],
  ["PANTONE_2072_C", "Pantone 2072 C"],
  ["PANTONE_2073_C", "Pantone 2073 C"],
  ["PANTONE_2074_C", "Pantone 2074 C"],
  ["PANTONE_2075_C", "Pantone 2075 C"],
  ["PANTONE_2076_C", "Pantone 2076 C"],
  ["PANTONE_2077_C", "Pantone 2077 C"],
  ["PANTONE_2078_C", "Pantone 2078 C"],
  ["PANTONE_2079_C", "Pantone 2079 C"],
  ["PANTONE_2080_C", "Pantone 2080 C"],
  ["PANTONE_2081_C", "Pantone 2081 C"],
  ["PANTONE_2082_C", "Pantone 2082 C"],
  ["PANTONE_2083_C", "Pantone 2083 C"],
  ["PANTONE_2084_C", "Pantone 2084 C"],
  ["PANTONE_2085_C", "Pantone 2085 C"],
  ["PANTONE_2086_C", "Pantone 2086 C"],
  ["PANTONE_2087_C", "Pantone 2087 C"],
  ["PANTONE_2088_C", "Pantone 2088 C"],
  ["PANTONE_2089_C", "Pantone 2089 C"],
  ["PANTONE_2090_C", "Pantone 2090 C"],
  ["PANTONE_2091_C", "Pantone 2091 C"],
  ["PANTONE_2092_C", "Pantone 2092 C"],
  ["PANTONE_2093_C", "Pantone 2093 C"],
  ["PANTONE_2094_C", "Pantone 2094 C"],
  ["PANTONE_2095_C", "Pantone 2095 C"],
  ["PANTONE_2096_C", "Pantone 2096 C"],
  ["PANTONE_2097_C", "Pantone 2097 C"],
  ["PANTONE_2098_C", "Pantone 2098 C"],
  ["PANTONE_2099_C", "Pantone 2099 C"],
  ["PANTONE_2100_C", "Pantone 2100 C"],
  ["PANTONE_2101_C", "Pantone 2101 C"],
  ["PANTONE_2102_C", "Pantone 2102 C"],
  ["PANTONE_2103_C", "Pantone 2103 C"],
  ["PANTONE_2104_C", "Pantone 2104 C"],
  ["PANTONE_2105_C", "Pantone 2105 C"],
  ["PANTONE_2106_C", "Pantone 2106 C"],
  ["PANTONE_2107_C", "Pantone 2107 C"],
  ["PANTONE_2108_C", "Pantone 2108 C"],
  ["PANTONE_2109_C", "Pantone 2109 C"],
  ["PANTONE_2110_C", "Pantone 2110 C"],
  ["PANTONE_2111_C", "Pantone 2111 C"],
  ["PANTONE_2112_C", "Pantone 2112 C"],
  ["PANTONE_2113_C", "Pantone 2113 C"],
  ["PANTONE_2114_C", "Pantone 2114 C"],
  ["PANTONE_2115_C", "Pantone 2115 C"],
  ["PANTONE_2116_C", "Pantone 2116 C"],
  ["PANTONE_2117_C", "Pantone 2117 C"],
  ["PANTONE_2118_C", "Pantone 2118 C"],
  ["PANTONE_2119_C", "Pantone 2119 C"],
  ["PANTONE_2120_C", "Pantone 2120 C"],
  ["PANTONE_2121_C", "Pantone 2121 C"],
  ["PANTONE_2122_C", "Pantone 2122 C"],
  ["PANTONE_2123_C", "Pantone 2123 C"],
  ["PANTONE_2124_C", "Pantone 2124 C"],
  ["PANTONE_2125_C", "Pantone 2125 C"],
  ["PANTONE_2126_C", "Pantone 2126 C"],
  ["PANTONE_2127_C", "Pantone 2127 C"],
  ["PANTONE_2128_C", "Pantone 2128 C"],
  ["PANTONE_2129_C", "Pantone 2129 C"],
  ["PANTONE_2130_C", "Pantone 2130 C"],
  ["PANTONE_2131_C", "Pantone 2131 C"],
  ["PANTONE_2132_C", "Pantone 2132 C"],
  ["PANTONE_2133_C", "Pantone 2133 C"],
  ["PANTONE_2134_C", "Pantone 2134 C"],
  ["PANTONE_2135_C", "Pantone 2135 C"],
  ["PANTONE_2136_C", "Pantone 2136 C"],
  ["PANTONE_2137_C", "Pantone 2137 C"],
  ["PANTONE_2138_C", "Pantone 2138 C"],
  ["PANTONE_2139_C", "Pantone 2139 C"],
  ["PANTONE_2140_C", "Pantone 2140 C"],
  ["PANTONE_2141_C", "Pantone 2141 C"],
  ["PANTONE_2142_C", "Pantone 2142 C"],
  ["PANTONE_2143_C", "Pantone 2143 C"],
  ["PANTONE_2144_C", "Pantone 2144 C"],
  ["PANTONE_2145_C", "Pantone 2145 C"],
  ["PANTONE_2146_C", "Pantone 2146 C"],
  ["PANTONE_2147_C", "Pantone 2147 C"],
  ["PANTONE_2148_C", "Pantone 2148 C"],
  ["PANTONE_2149_C", "Pantone 2149 C"],
  ["PANTONE_2150_C", "Pantone 2150 C"],
  ["PANTONE_2151_C", "Pantone 2151 C"],
  ["PANTONE_2152_C", "Pantone 2152 C"],
  ["PANTONE_2153_C", "Pantone 2153 C"],
  ["PANTONE_2154_C", "Pantone 2154 C"],
  ["PANTONE_2155_C", "Pantone 2155 C"],
  ["PANTONE_2156_C", "Pantone 2156 C"],
  ["PANTONE_2157_C", "Pantone 2157 C"],
  ["PANTONE_2158_C", "Pantone 2158 C"],
  ["PANTONE_2159_C", "Pantone 2159 C"],
  ["PANTONE_2160_C", "Pantone 2160 C"],
  ["PANTONE_2161_C", "Pantone 2161 C"],
  ["PANTONE_2162_C", "Pantone 2162 C"],
  ["PANTONE_2163_C", "Pantone 2163 C"],
  ["PANTONE_2164_C", "Pantone 2164 C"],
  ["PANTONE_2165_C", "Pantone 2165 C"],
  ["PANTONE_2166_C", "Pantone 2166 C"],
  ["PANTONE_2167_C", "Pantone 2167 C"],
  ["PANTONE_2168_C", "Pantone 2168 C"],
  ["PANTONE_2169_C", "Pantone 2169 C"],
  ["PANTONE_2170_C", "Pantone 2170 C"],
  ["PANTONE_2171_C", "Pantone 2171 C"],
  ["PANTONE_2172_C", "Pantone 2172 C"],
  ["PANTONE_2173_C", "Pantone 2173 C"],
  ["PANTONE_2174_C", "Pantone 2174 C"],
  ["PANTONE_2175_C", "Pantone 2175 C"],
  ["PANTONE_2176_C", "Pantone 2176 C"],
  ["PANTONE_2177_C", "Pantone 2177 C"],
  ["PANTONE_2178_C", "Pantone 2178 C"],
  ["PANTONE_2179_C", "Pantone 2179 C"],
  ["PANTONE_2180_C", "Pantone 2180 C"],
  ["PANTONE_2181_C", "Pantone 2181 C"],
  ["PANTONE_2182_C", "Pantone 2182 C"],
  ["PANTONE_2183_C", "Pantone 2183 C"],
  ["PANTONE_2184_C", "Pantone 2184 C"],
  ["PANTONE_2185_C", "Pantone 2185 C"],
  ["PANTONE_2186_C", "Pantone 2186 C"],
  ["PANTONE_2187_C", "Pantone 2187 C"],
  ["PANTONE_2188_C", "Pantone 2188 C"],
  ["PANTONE_2189_C", "Pantone 2189 C"],
  ["PANTONE_2190_C", "Pantone 2190 C"],
  ["PANTONE_2191_C", "Pantone 2191 C"],
  ["PANTONE_2192_C", "Pantone 2192 C"],
  ["PANTONE_2193_C", "Pantone 2193 C"],
  ["PANTONE_2194_C", "Pantone 2194 C"],
  ["PANTONE_2195_C", "Pantone 2195 C"],
  ["PANTONE_2196_C", "Pantone 2196 C"],
  ["PANTONE_2197_C", "Pantone 2197 C"],
  ["PANTONE_2198_C", "Pantone 2198 C"],
  ["PANTONE_2199_C", "Pantone 2199 C"],
  ["PANTONE_2200_C", "Pantone 2200 C"],
  ["PANTONE_2201_C", "Pantone 2201 C"],
  ["PANTONE_2202_C", "Pantone 2202 C"],
  ["PANTONE_2203_C", "Pantone 2203 C"],
  ["PANTONE_2204_C", "Pantone 2204 C"],
  ["PANTONE_2205_C", "Pantone 2205 C"],
  ["PANTONE_2206_C", "Pantone 2206 C"],
  ["PANTONE_2207_C", "Pantone 2207 C"],
  ["PANTONE_2208_C", "Pantone 2208 C"],
  ["PANTONE_2209_C", "Pantone 2209 C"],
  ["PANTONE_2210_C", "Pantone 2210 C"],
  ["PANTONE_2211_C", "Pantone 2211 C"],
  ["PANTONE_2212_C", "Pantone 2212 C"],
  ["PANTONE_2213_C", "Pantone 2213 C"],
  ["PANTONE_2214_C", "Pantone 2214 C"],
  ["PANTONE_2215_C", "Pantone 2215 C"],
  ["PANTONE_2216_C", "Pantone 2216 C"],
  ["PANTONE_2217_C", "Pantone 2217 C"],
  ["PANTONE_2218_C", "Pantone 2218 C"],
  ["PANTONE_2219_C", "Pantone 2219 C"],
  ["PANTONE_2220_C", "Pantone 2220 C"],
  ["PANTONE_2221_C", "Pantone 2221 C"],
  ["PANTONE_2222_C", "Pantone 2222 C"],
  ["PANTONE_2223_C", "Pantone 2223 C"],
  ["PANTONE_2224_C", "Pantone 2224 C"],
  ["PANTONE_2225_C", "Pantone 2225 C"],
  ["PANTONE_2226_C", "Pantone 2226 C"],
  ["PANTONE_2227_C", "Pantone 2227 C"],
  ["PANTONE_2228_C", "Pantone 2228 C"],
  ["PANTONE_2229_C", "Pantone 2229 C"],
  ["PANTONE_2230_C", "Pantone 2230 C"],
  ["PANTONE_2231_C", "Pantone 2231 C"],
  ["PANTONE_2232_C", "Pantone 2232 C"],
  ["PANTONE_2233_C", "Pantone 2233 C"],
  ["PANTONE_2234_C", "Pantone 2234 C"],
  ["PANTONE_2235_C", "Pantone 2235 C"],
  ["PANTONE_2236_C", "Pantone 2236 C"],
  ["PANTONE_2237_C", "Pantone 2237 C"],
  ["PANTONE_2238_C", "Pantone 2238 C"],
  ["PANTONE_2239_C", "Pantone 2239 C"],
  ["PANTONE_2240_C", "Pantone 2240 C"],
  ["PANTONE_2241_C", "Pantone 2241 C"],
  ["PANTONE_2242_C", "Pantone 2242 C"],
  ["PANTONE_2243_C", "Pantone 2243 C"],
  ["PANTONE_2244_C", "Pantone 2244 C"],
  ["PANTONE_2245_C", "Pantone 2245 C"],
  ["PANTONE_2246_C", "Pantone 2246 C"],
  ["PANTONE_2247_C", "Pantone 2247 C"],
  ["PANTONE_2248_C", "Pantone 2248 C"],
  ["PANTONE_2249_C", "Pantone 2249 C"],
  ["PANTONE_2250_C", "Pantone 2250 C"],
  ["PANTONE_2251_C", "Pantone 2251 C"],
  ["PANTONE_2252_C", "Pantone 2252 C"],
  ["PANTONE_2253_C", "Pantone 2253 C"],
  ["PANTONE_2254_C", "Pantone 2254 C"],
  ["PANTONE_2255_C", "Pantone 2255 C"],
  ["PANTONE_2256_C", "Pantone 2256 C"],
  ["PANTONE_2257_C", "Pantone 2257 C"],
  ["PANTONE_2258_C", "Pantone 2258 C"],
  ["PANTONE_2259_C", "Pantone 2259 C"],
  ["PANTONE_2260_C", "Pantone 2260 C"],
  ["PANTONE_2261_C", "Pantone 2261 C"],
  ["PANTONE_2262_C", "Pantone 2262 C"],
  ["PANTONE_2263_C", "Pantone 2263 C"],
  ["PANTONE_2264_C", "Pantone 2264 C"],
  ["PANTONE_2265_C", "Pantone 2265 C"],
  ["PANTONE_2266_C", "Pantone 2266 C"],
  ["PANTONE_2267_C", "Pantone 2267 C"],
  ["PANTONE_2268_C", "Pantone 2268 C"],
  ["PANTONE_2269_C", "Pantone 2269 C"],
  ["PANTONE_2270_C", "Pantone 2270 C"],
  ["PANTONE_2271_C", "Pantone 2271 C"],
  ["PANTONE_2272_C", "Pantone 2272 C"],
  ["PANTONE_2273_C", "Pantone 2273 C"],
  ["PANTONE_2274_C", "Pantone 2274 C"],
  ["PANTONE_2275_C", "Pantone 2275 C"],
  ["PANTONE_2276_C", "Pantone 2276 C"],
  ["PANTONE_2277_C", "Pantone 2277 C"],
  ["PANTONE_2278_C", "Pantone 2278 C"],
  ["PANTONE_2279_C", "Pantone 2279 C"],
  ["PANTONE_2280_C", "Pantone 2280 C"],
  ["PANTONE_2281_C", "Pantone 2281 C"],
  ["PANTONE_2282_C", "Pantone 2282 C"],
  ["PANTONE_2283_C", "Pantone 2283 C"],
  ["PANTONE_2284_C", "Pantone 2284 C"],
  ["PANTONE_2285_C", "Pantone 2285 C"],
  ["PANTONE_2286_C", "Pantone 2286 C"],
  ["PANTONE_2287_C", "Pantone 2287 C"],
  ["PANTONE_2288_C", "Pantone 2288 C"],
  ["PANTONE_2289_C", "Pantone 2289 C"],
  ["PANTONE_2290_C", "Pantone 2290 C"],
  ["PANTONE_2291_C", "Pantone 2291 C"],
  ["PANTONE_2292_C", "Pantone 2292 C"],
  ["PANTONE_2293_C", "Pantone 2293 C"],
  ["PANTONE_2294_C", "Pantone 2294 C"],
  ["PANTONE_2295_C", "Pantone 2295 C"],
  ["PANTONE_2296_C", "Pantone 2296 C"],
  ["PANTONE_2297_C", "Pantone 2297 C"],
  ["PANTONE_2298_C", "Pantone 2298 C"],
  ["PANTONE_2299_C", "Pantone 2299 C"],
  ["PANTONE_2300_C", "Pantone 2300 C"],
  ["PANTONE_2301_C", "Pantone 2301 C"],
  ["PANTONE_2302_C", "Pantone 2302 C"],
  ["PANTONE_2303_C", "Pantone 2303 C"],
  ["PANTONE_2304_C", "Pantone 2304 C"],
  ["PANTONE_2305_C", "Pantone 2305 C"],
  ["PANTONE_2306_C", "Pantone 2306 C"],
  ["PANTONE_2307_C", "Pantone 2307 C"],
  ["PANTONE_2308_C", "Pantone 2308 C"],
  ["PANTONE_2309_C", "Pantone 2309 C"],
  ["PANTONE_2310_C", "Pantone 2310 C"],
  ["PANTONE_2311_C", "Pantone 2311 C"],
  ["PANTONE_2312_C", "Pantone 2312 C"],
  ["PANTONE_2313_C", "Pantone 2313 C"],
  ["PANTONE_2314_C", "Pantone 2314 C"],
  ["PANTONE_2315_C", "Pantone 2315 C"],
  ["PANTONE_2316_C", "Pantone 2316 C"],
  ["PANTONE_2317_C", "Pantone 2317 C"],
  ["PANTONE_2318_C", "Pantone 2318 C"],
  ["PANTONE_2319_C", "Pantone 2319 C"],
  ["PANTONE_2320_C", "Pantone 2320 C"],
  ["PANTONE_2321_C", "Pantone 2321 C"],
  ["PANTONE_2322_C", "Pantone 2322 C"],
  ["PANTONE_2323_C", "Pantone 2323 C"],
  ["PANTONE_2324_C", "Pantone 2324 C"],
  ["PANTONE_2325_C", "Pantone 2325 C"],
  ["PANTONE_2326_C", "Pantone 2326 C"],
  ["PANTONE_2327_C", "Pantone 2327 C"],
  ["PANTONE_2328_C", "Pantone 2328 C"],
  ["PANTONE_2329_C", "Pantone 2329 C"],
  ["PANTONE_2330_C", "Pantone 2330 C"],
  ["PANTONE_2331_C", "Pantone 2331 C"],
  ["PANTONE_2332_C", "Pantone 2332 C"],
  ["PANTONE_2333_C", "Pantone 2333 C"],
  ["PANTONE_2334_C", "Pantone 2334 C"],
  ["PANTONE_2335_C", "Pantone 2335 C"],
  ["PANTONE_2336_C", "Pantone 2336 C"],
  ["PANTONE_2365_C", "Pantone 2365 C"],
  ["PANTONE_2375_C", "Pantone 2375 C"],
  ["PANTONE_2385_C", "Pantone 2385 C"],
  ["PANTONE_2395_C", "Pantone 2395 C"],
  ["PANTONE_2405_C", "Pantone 2405 C"],
  ["PANTONE_2415_C", "Pantone 2415 C"],
  ["PANTONE_2425_C", "Pantone 2425 C"],
  ["PANTONE_2562_C", "Pantone 2562 C"],
  ["PANTONE_2563_C", "Pantone 2563 C"],
  ["PANTONE_2567_C", "Pantone 2567 C"],
  ["PANTONE_2572_C", "Pantone 2572 C"],
  ["PANTONE_2573_C", "Pantone 2573 C"],
  ["PANTONE_2577_C", "Pantone 2577 C"],
  ["PANTONE_2582_C", "Pantone 2582 C"],
  ["PANTONE_2583_C", "Pantone 2583 C"],
  ["PANTONE_2587_C", "Pantone 2587 C"],
  ["PANTONE_2592_C", "Pantone 2592 C"],
  ["PANTONE_2593_C", "Pantone 2593 C"],
  ["PANTONE_2597_C", "Pantone 2597 C"],
  ["PANTONE_2602_C", "Pantone 2602 C"],
  ["PANTONE_2603_C", "Pantone 2603 C"],
  ["PANTONE_2607_C", "Pantone 2607 C"],
  ["PANTONE_2612_C", "Pantone 2612 C"],
  ["PANTONE_2613_C", "Pantone 2613 C"],
  ["PANTONE_2617_C", "Pantone 2617 C"],
  ["PANTONE_2622_C", "Pantone 2622 C"],
  ["PANTONE_2623_C", "Pantone 2623 C"],
  ["PANTONE_2627_C", "Pantone 2627 C"],
  ["PANTONE_2635_C", "Pantone 2635 C"],
  ["PANTONE_2645_C", "Pantone 2645 C"],
  ["PANTONE_2655_C", "Pantone 2655 C"],
  ["PANTONE_2665_C", "Pantone 2665 C"],
  ["PANTONE_2685_C", "Pantone 2685 C"],
  ["PANTONE_2695_C", "Pantone 2695 C"],
  ["PANTONE_2705_C", "Pantone 2705 C"],
  ["PANTONE_2706_C", "Pantone 2706 C"],
  ["PANTONE_2707_C", "Pantone 2707 C"],
  ["PANTONE_2708_C", "Pantone 2708 C"],
  ["PANTONE_2715_C", "Pantone 2715 C"],
  ["PANTONE_2716_C", "Pantone 2716 C"],
  ["PANTONE_2717_C", "Pantone 2717 C"],
  ["PANTONE_2718_C", "Pantone 2718 C"],
  ["PANTONE_2725_C", "Pantone 2725 C"],
  ["PANTONE_2726_C", "Pantone 2726 C"],
  ["PANTONE_2727_C", "Pantone 2727 C"],
  ["PANTONE_2728_C", "Pantone 2728 C"],
  ["PANTONE_2735_C", "Pantone 2735 C"],
  ["PANTONE_2736_C", "Pantone 2736 C"],
  ["PANTONE_2738_C", "Pantone 2738 C"],
  ["PANTONE_2745_C", "Pantone 2745 C"],
  ["PANTONE_2746_C", "Pantone 2746 C"],
  ["PANTONE_2747_C", "Pantone 2747 C"],
  ["PANTONE_2748_C", "Pantone 2748 C"],
  ["PANTONE_2755_C", "Pantone 2755 C"],
  ["PANTONE_2756_C", "Pantone 2756 C"],
  ["PANTONE_2757_C", "Pantone 2757 C"],
  ["PANTONE_2758_C", "Pantone 2758 C"],
  ["PANTONE_2765_C", "Pantone 2765 C"],
  ["PANTONE_2766_C", "Pantone 2766 C"],
  ["PANTONE_2767_C", "Pantone 2767 C"],
  ["PANTONE_2768_C", "Pantone 2768 C"],
  ["PANTONE_2905_C", "Pantone 2905 C"],
  ["PANTONE_2915_C", "Pantone 2915 C"],
  ["PANTONE_2925_C", "Pantone 2925 C"],
  ["PANTONE_2935_C", "Pantone 2935 C"],
  ["PANTONE_2945_C", "Pantone 2945 C"],
  ["PANTONE_2955_C", "Pantone 2955 C"],
  ["PANTONE_2965_C", "Pantone 2965 C"],
  ["PANTONE_2975_C", "Pantone 2975 C"],
  ["PANTONE_2985_C", "Pantone 2985 C"],
  ["PANTONE_2995_C", "Pantone 2995 C"],
  ["PANTONE_3005_C", "Pantone 3005 C"],
  ["PANTONE_3015_C", "Pantone 3015 C"],
  ["PANTONE_3025_C", "Pantone 3025 C"],
  ["PANTONE_3035_C", "Pantone 3035 C"],
  ["PANTONE_3105_C", "Pantone 3105 C"],
  ["PANTONE_3115_C", "Pantone 3115 C"],
  ["PANTONE_3125_C", "Pantone 3125 C"],
  ["PANTONE_3135_C", "Pantone 3135 C"],
  ["PANTONE_3145_C", "Pantone 3145 C"],
  ["PANTONE_3155_C", "Pantone 3155 C"],
  ["PANTONE_3165_C", "Pantone 3165 C"],
  ["PANTONE_3242_C", "Pantone 3242 C"],
  ["PANTONE_3245_C", "Pantone 3245 C"],
  ["PANTONE_3248_C", "Pantone 3248 C"],
  ["PANTONE_3252_C", "Pantone 3252 C"],
  ["PANTONE_3255_C", "Pantone 3255 C"],
  ["PANTONE_3258_C", "Pantone 3258 C"],
  ["PANTONE_3262_C", "Pantone 3262 C"],
  ["PANTONE_3265_C", "Pantone 3265 C"],
  ["PANTONE_3268_C", "Pantone 3268 C"],
  ["PANTONE_3272_C", "Pantone 3272 C"],
  ["PANTONE_3275_C", "Pantone 3275 C"],
  ["PANTONE_3278_C", "Pantone 3278 C"],
  ["PANTONE_3282_C", "Pantone 3282 C"],
  ["PANTONE_3285_C", "Pantone 3285 C"],
  ["PANTONE_3288_C", "Pantone 3288 C"],
  ["PANTONE_3292_C", "Pantone 3292 C"],
  ["PANTONE_3295_C", "Pantone 3295 C"],
  ["PANTONE_3298_C", "Pantone 3298 C"],
  ["PANTONE_3302_C", "Pantone 3302 C"],
  ["PANTONE_3305_C", "Pantone 3305 C"],
  ["PANTONE_3308_C", "Pantone 3308 C"],
  ["PANTONE_3375_C", "Pantone 3375 C"],
  ["PANTONE_3385_C", "Pantone 3385 C"],
  ["PANTONE_3395_C", "Pantone 3395 C"],
  ["PANTONE_3405_C", "Pantone 3405 C"],
  ["PANTONE_3415_C", "Pantone 3415 C"],
  ["PANTONE_3425_C", "Pantone 3425 C"],
  ["PANTONE_3435_C", "Pantone 3435 C"],
  ["PANTONE_3935_C", "Pantone 3935 C"],
  ["PANTONE_3945_C", "Pantone 3945 C"],
  ["PANTONE_3955_C", "Pantone 3955 C"],
  ["PANTONE_3965_C", "Pantone 3965 C"],
  ["PANTONE_3975_C", "Pantone 3975 C"],
  ["PANTONE_3985_C", "Pantone 3985 C"],
  ["PANTONE_3995_C", "Pantone 3995 C"],
  ["PANTONE_4485_C", "Pantone 4485 C"],
  ["PANTONE_4495_C", "Pantone 4495 C"],
  ["PANTONE_4505_C", "Pantone 4505 C"],
  ["PANTONE_4515_C", "Pantone 4515 C"],
  ["PANTONE_4525_C", "Pantone 4525 C"],
  ["PANTONE_4535_C", "Pantone 4535 C"],
  ["PANTONE_4545_C", "Pantone 4545 C"],
  ["PANTONE_4625_C", "Pantone 4625 C"],
  ["PANTONE_4635_C", "Pantone 4635 C"],
  ["PANTONE_4645_C", "Pantone 4645 C"],
  ["PANTONE_4655_C", "Pantone 4655 C"],
  ["PANTONE_4665_C", "Pantone 4665 C"],
  ["PANTONE_4675_C", "Pantone 4675 C"],
  ["PANTONE_4685_C", "Pantone 4685 C"],
  ["PANTONE_4695_C", "Pantone 4695 C"],
  ["PANTONE_4705_C", "Pantone 4705 C"],
  ["PANTONE_4715_C", "Pantone 4715 C"],
  ["PANTONE_4725_C", "Pantone 4725 C"],
  ["PANTONE_4735_C", "Pantone 4735 C"],
  ["PANTONE_4745_C", "Pantone 4745 C"],
  ["PANTONE_4755_C", "Pantone 4755 C"],
  ["PANTONE_4975_C", "Pantone 4975 C"],
  ["PANTONE_4985_C", "Pantone 4985 C"],
  ["PANTONE_4995_C", "Pantone 4995 C"],
  ["PANTONE_5005_C", "Pantone 5005 C"],
  ["PANTONE_5015_C", "Pantone 5015 C"],
  ["PANTONE_5025_C", "Pantone 5025 C"],
  ["PANTONE_5035_C", "Pantone 5035 C"],
  ["PANTONE_5115_C", "Pantone 5115 C"],
  ["PANTONE_5125_C", "Pantone 5125 C"],
  ["PANTONE_5135_C", "Pantone 5135 C"],
  ["PANTONE_5145_C", "Pantone 5145 C"],
  ["PANTONE_5155_C", "Pantone 5155 C"],
  ["PANTONE_5165_C", "Pantone 5165 C"],
  ["PANTONE_5175_C", "Pantone 5175 C"],
  ["PANTONE_5185_C", "Pantone 5185 C"],
  ["PANTONE_5195_C", "Pantone 5195 C"],
  ["PANTONE_5205_C", "Pantone 5205 C"],
  ["PANTONE_5215_C", "Pantone 5215 C"],
  ["PANTONE_5225_C", "Pantone 5225 C"],
  ["PANTONE_5235_C", "Pantone 5235 C"],
  ["PANTONE_5245_C", "Pantone 5245 C"],
  ["PANTONE_5255_C", "Pantone 5255 C"],
  ["PANTONE_5265_C", "Pantone 5265 C"],
  ["PANTONE_5275_C", "Pantone 5275 C"],
  ["PANTONE_5285_C", "Pantone 5285 C"],
  ["PANTONE_5295_C", "Pantone 5295 C"],
  ["PANTONE_5305_C", "Pantone 5305 C"],
  ["PANTONE_5315_C", "Pantone 5315 C"],
  ["PANTONE_5395_C", "Pantone 5395 C"],
  ["PANTONE_5405_C", "Pantone 5405 C"],
  ["PANTONE_5415_C", "Pantone 5415 C"],
  ["PANTONE_5425_C", "Pantone 5425 C"],
  ["PANTONE_5435_C", "Pantone 5435 C"],
  ["PANTONE_5445_C", "Pantone 5445 C"],
  ["PANTONE_5455_C", "Pantone 5455 C"],
  ["PANTONE_5463_C", "Pantone 5463 C"],
  ["PANTONE_5467_C", "Pantone 5467 C"],
  ["PANTONE_5473_C", "Pantone 5473 C"],
  ["PANTONE_5477_C", "Pantone 5477 C"],
  ["PANTONE_5483_C", "Pantone 5483 C"],
  ["PANTONE_5487_C", "Pantone 5487 C"],
  ["PANTONE_5493_C", "Pantone 5493 C"],
  ["PANTONE_5497_C", "Pantone 5497 C"],
  ["PANTONE_5503_C", "Pantone 5503 C"],
  ["PANTONE_5507_C", "Pantone 5507 C"],
  ["PANTONE_5513_C", "Pantone 5513 C"],
  ["PANTONE_5517_C", "Pantone 5517 C"],
  ["PANTONE_5523_C", "Pantone 5523 C"],
  ["PANTONE_5527_C", "Pantone 5527 C"],
  ["PANTONE_5535_C", "Pantone 5535 C"],
  ["PANTONE_5545_C", "Pantone 5545 C"],
  ["PANTONE_5555_C", "Pantone 5555 C"],
  ["PANTONE_5565_C", "Pantone 5565 C"],
  ["PANTONE_5575_C", "Pantone 5575 C"],
  ["PANTONE_5585_C", "Pantone 5585 C"],
  ["PANTONE_5595_C", "Pantone 5595 C"],
  ["PANTONE_5605_C", "Pantone 5605 C"],
  ["PANTONE_5615_C", "Pantone 5615 C"],
  ["PANTONE_5625_C", "Pantone 5625 C"],
  ["PANTONE_5635_C", "Pantone 5635 C"],
  ["PANTONE_5645_C", "Pantone 5645 C"],
  ["PANTONE_5655_C", "Pantone 5655 C"],
  ["PANTONE_5665_C", "Pantone 5665 C"],
  ["PANTONE_5743_C", "Pantone 5743 C"],
  ["PANTONE_5747_C", "Pantone 5747 C"],
  ["PANTONE_5753_C", "Pantone 5753 C"],
  ["PANTONE_5757_C", "Pantone 5757 C"],
  ["PANTONE_5763_C", "Pantone 5763 C"],
  ["PANTONE_5767_C", "Pantone 5767 C"],
  ["PANTONE_5773_C", "Pantone 5773 C"],
  ["PANTONE_5777_C", "Pantone 5777 C"],
  ["PANTONE_5783_C", "Pantone 5783 C"],
  ["PANTONE_5787_C", "Pantone 5787 C"],
  ["PANTONE_5793_C", "Pantone 5793 C"],
  ["PANTONE_5797_C", "Pantone 5797 C"],
  ["PANTONE_5803_C", "Pantone 5803 C"],
  ["PANTONE_5807_C", "Pantone 5807 C"],
  ["PANTONE_5815_C", "Pantone 5815 C"],
  ["PANTONE_5825_C", "Pantone 5825 C"],
  ["PANTONE_5835_C", "Pantone 5835 C"],
  ["PANTONE_5845_C", "Pantone 5845 C"],
  ["PANTONE_5855_C", "Pantone 5855 C"],
  ["PANTONE_5865_C", "Pantone 5865 C"],
  ["PANTONE_5875_C", "Pantone 5875 C"],
  ["PANTONE_7401_C", "Pantone 7401 C"],
  ["PANTONE_7402_C", "Pantone 7402 C"],
  ["PANTONE_7403_C", "Pantone 7403 C"],
  ["PANTONE_7404_C", "Pantone 7404 C"],
  ["PANTONE_7405_C", "Pantone 7405 C"],
  ["PANTONE_7406_C", "Pantone 7406 C"],
  ["PANTONE_7407_C", "Pantone 7407 C"],
  ["PANTONE_7408_C", "Pantone 7408 C"],
  ["PANTONE_7409_C", "Pantone 7409 C"],
  ["PANTONE_7410_C", "Pantone 7410 C"],
  ["PANTONE_7411_C", "Pantone 7411 C"],
  ["PANTONE_7412_C", "Pantone 7412 C"],
  ["PANTONE_7413_C", "Pantone 7413 C"],
  ["PANTONE_7414_C", "Pantone 7414 C"],
  ["PANTONE_7415_C", "Pantone 7415 C"],
  ["PANTONE_7416_C", "Pantone 7416 C"],
  ["PANTONE_7417_C", "Pantone 7417 C"],
  ["PANTONE_7418_C", "Pantone 7418 C"],
  ["PANTONE_7419_C", "Pantone 7419 C"],
  ["PANTONE_7420_C", "Pantone 7420 C"],
  ["PANTONE_7421_C", "Pantone 7421 C"],
  ["PANTONE_7422_C", "Pantone 7422 C"],
  ["PANTONE_7423_C", "Pantone 7423 C"],
  ["PANTONE_7424_C", "Pantone 7424 C"],
  ["PANTONE_7425_C", "Pantone 7425 C"],
  ["PANTONE_7426_C", "Pantone 7426 C"],
  ["PANTONE_7427_C", "Pantone 7427 C"],
  ["PANTONE_7428_C", "Pantone 7428 C"],
  ["PANTONE_7429_C", "Pantone 7429 C"],
  ["PANTONE_7430_C", "Pantone 7430 C"],
  ["PANTONE_7431_C", "Pantone 7431 C"],
  ["PANTONE_7432_C", "Pantone 7432 C"],
  ["PANTONE_7433_C", "Pantone 7433 C"],
  ["PANTONE_7434_C", "Pantone 7434 C"],
  ["PANTONE_7435_C", "Pantone 7435 C"],
  ["PANTONE_7436_C", "Pantone 7436 C"],
  ["PANTONE_7437_C", "Pantone 7437 C"],
  ["PANTONE_7438_C", "Pantone 7438 C"],
  ["PANTONE_7439_C", "Pantone 7439 C"],
  ["PANTONE_7440_C", "Pantone 7440 C"],
  ["PANTONE_7441_C", "Pantone 7441 C"],
  ["PANTONE_7442_C", "Pantone 7442 C"],
  ["PANTONE_7443_C", "Pantone 7443 C"],
  ["PANTONE_7444_C", "Pantone 7444 C"],
  ["PANTONE_7445_C", "Pantone 7445 C"],
  ["PANTONE_7446_C", "Pantone 7446 C"],
  ["PANTONE_7447_C", "Pantone 7447 C"],
  ["PANTONE_7448_C", "Pantone 7448 C"],
  ["PANTONE_7449_C", "Pantone 7449 C"],
  ["PANTONE_7450_C", "Pantone 7450 C"],
  ["PANTONE_7451_C", "Pantone 7451 C"],
  ["PANTONE_7452_C", "Pantone 7452 C"],
  ["PANTONE_7453_C", "Pantone 7453 C"],
  ["PANTONE_7454_C", "Pantone 7454 C"],
  ["PANTONE_7455_C", "Pantone 7455 C"],
  ["PANTONE_7456_C", "Pantone 7456 C"],
  ["PANTONE_7457_C", "Pantone 7457 C"],
  ["PANTONE_7458_C", "Pantone 7458 C"],
  ["PANTONE_7459_C", "Pantone 7459 C"],
  ["PANTONE_7460_C", "Pantone 7460 C"],
  ["PANTONE_7461_C", "Pantone 7461 C"],
  ["PANTONE_7462_C", "Pantone 7462 C"],
  ["PANTONE_7463_C", "Pantone 7463 C"],
  ["PANTONE_7464_C", "Pantone 7464 C"],
  ["PANTONE_7465_C", "Pantone 7465 C"],
  ["PANTONE_7466_C", "Pantone 7466 C"],
  ["PANTONE_7467_C", "Pantone 7467 C"],
  ["PANTONE_7468_C", "Pantone 7468 C"],
  ["PANTONE_7469_C", "Pantone 7469 C"],
  ["PANTONE_7470_C", "Pantone 7470 C"],
  ["PANTONE_7471_C", "Pantone 7471 C"],
  ["PANTONE_7472_C", "Pantone 7472 C"],
  ["PANTONE_7473_C", "Pantone 7473 C"],
  ["PANTONE_7474_C", "Pantone 7474 C"],
  ["PANTONE_7475_C", "Pantone 7475 C"],
  ["PANTONE_7476_C", "Pantone 7476 C"],
  ["PANTONE_7477_C", "Pantone 7477 C"],
  ["PANTONE_7478_C", "Pantone 7478 C"],
  ["PANTONE_7479_C", "Pantone 7479 C"],
  ["PANTONE_7480_C", "Pantone 7480 C"],
  ["PANTONE_7481_C", "Pantone 7481 C"],
  ["PANTONE_7482_C", "Pantone 7482 C"],
  ["PANTONE_7483_C", "Pantone 7483 C"],
  ["PANTONE_7484_C", "Pantone 7484 C"],
  ["PANTONE_7485_C", "Pantone 7485 C"],
  ["PANTONE_7486_C", "Pantone 7486 C"],
  ["PANTONE_7487_C", "Pantone 7487 C"],
  ["PANTONE_7488_C", "Pantone 7488 C"],
  ["PANTONE_7489_C", "Pantone 7489 C"],
  ["PANTONE_7490_C", "Pantone 7490 C"],
  ["PANTONE_7491_C", "Pantone 7491 C"],
  ["PANTONE_7492_C", "Pantone 7492 C"],
  ["PANTONE_7493_C", "Pantone 7493 C"],
  ["PANTONE_7494_C", "Pantone 7494 C"],
  ["PANTONE_7495_C", "Pantone 7495 C"],
  ["PANTONE_7496_C", "Pantone 7496 C"],
  ["PANTONE_7497_C", "Pantone 7497 C"],
  ["PANTONE_7498_C", "Pantone 7498 C"],
  ["PANTONE_7499_C", "Pantone 7499 C"],
  ["PANTONE_7500_C", "Pantone 7500 C"],
  ["PANTONE_7501_C", "Pantone 7501 C"],
  ["PANTONE_7502_C", "Pantone 7502 C"],
  ["PANTONE_7503_C", "Pantone 7503 C"],
  ["PANTONE_7504_C", "Pantone 7504 C"],
  ["PANTONE_7505_C", "Pantone 7505 C"],
  ["PANTONE_7506_C", "Pantone 7506 C"],
  ["PANTONE_7507_C", "Pantone 7507 C"],
  ["PANTONE_7508_C", "Pantone 7508 C"],
  ["PANTONE_7509_C", "Pantone 7509 C"],
  ["PANTONE_7510_C", "Pantone 7510 C"],
  ["PANTONE_7511_C", "Pantone 7511 C"],
  ["PANTONE_7512_C", "Pantone 7512 C"],
  ["PANTONE_7513_C", "Pantone 7513 C"],
  ["PANTONE_7514_C", "Pantone 7514 C"],
  ["PANTONE_7515_C", "Pantone 7515 C"],
  ["PANTONE_7516_C", "Pantone 7516 C"],
  ["PANTONE_7517_C", "Pantone 7517 C"],
  ["PANTONE_7518_C", "Pantone 7518 C"],
  ["PANTONE_7519_C", "Pantone 7519 C"],
  ["PANTONE_7520_C", "Pantone 7520 C"],
  ["PANTONE_7521_C", "Pantone 7521 C"],
  ["PANTONE_7522_C", "Pantone 7522 C"],
  ["PANTONE_7523_C", "Pantone 7523 C"],
  ["PANTONE_7524_C", "Pantone 7524 C"],
  ["PANTONE_7525_C", "Pantone 7525 C"],
  ["PANTONE_7526_C", "Pantone 7526 C"],
  ["PANTONE_7527_C", "Pantone 7527 C"],
  ["PANTONE_7528_C", "Pantone 7528 C"],
  ["PANTONE_7529_C", "Pantone 7529 C"],
  ["PANTONE_7530_C", "Pantone 7530 C"],
  ["PANTONE_7531_C", "Pantone 7531 C"],
  ["PANTONE_7532_C", "Pantone 7532 C"],
  ["PANTONE_7533_C", "Pantone 7533 C"],
  ["PANTONE_7534_C", "Pantone 7534 C"],
  ["PANTONE_7535_C", "Pantone 7535 C"],
  ["PANTONE_7536_C", "Pantone 7536 C"],
  ["PANTONE_7537_C", "Pantone 7537 C"],
  ["PANTONE_7538_C", "Pantone 7538 C"],
  ["PANTONE_7539_C", "Pantone 7539 C"],
  ["PANTONE_7540_C", "Pantone 7540 C"],
  ["PANTONE_7541_C", "Pantone 7541 C"],
  ["PANTONE_7542_C", "Pantone 7542 C"],
  ["PANTONE_7543_C", "Pantone 7543 C"],
  ["PANTONE_7544_C", "Pantone 7544 C"],
  ["PANTONE_7545_C", "Pantone 7545 C"],
  ["PANTONE_7546_C", "Pantone 7546 C"],
  ["PANTONE_7547_C", "Pantone 7547 C"],
  ["PANTONE_7548_C", "Pantone 7548 C"],
  ["PANTONE_7549_C", "Pantone 7549 C"],
  ["PANTONE_7550_C", "Pantone 7550 C"],
  ["PANTONE_7551_C", "Pantone 7551 C"],
  ["PANTONE_7552_C", "Pantone 7552 C"],
  ["PANTONE_7553_C", "Pantone 7553 C"],
  ["PANTONE_7554_C", "Pantone 7554 C"],
  ["PANTONE_7555_C", "Pantone 7555 C"],
  ["PANTONE_7556_C", "Pantone 7556 C"],
  ["PANTONE_7557_C", "Pantone 7557 C"],
  ["PANTONE_7558_C", "Pantone 7558 C"],
  ["PANTONE_7559_C", "Pantone 7559 C"],
  ["PANTONE_7560_C", "Pantone 7560 C"],
  ["PANTONE_7561_C", "Pantone 7561 C"],
  ["PANTONE_7562_C", "Pantone 7562 C"],
  ["PANTONE_7563_C", "Pantone 7563 C"],
  ["PANTONE_7564_C", "Pantone 7564 C"],
  ["PANTONE_7565_C", "Pantone 7565 C"],
  ["PANTONE_7566_C", "Pantone 7566 C"],
  ["PANTONE_7567_C", "Pantone 7567 C"],
  ["PANTONE_7568_C", "Pantone 7568 C"],
  ["PANTONE_7569_C", "Pantone 7569 C"],
  ["PANTONE_7570_C", "Pantone 7570 C"],
  ["PANTONE_7571_C", "Pantone 7571 C"],
  ["PANTONE_7572_C", "Pantone 7572 C"],
  ["PANTONE_7573_C", "Pantone 7573 C"],
  ["PANTONE_7574_C", "Pantone 7574 C"],
  ["PANTONE_7575_C", "Pantone 7575 C"],
  ["PANTONE_7576_C", "Pantone 7576 C"],
  ["PANTONE_7577_C", "Pantone 7577 C"],
  ["PANTONE_7578_C", "Pantone 7578 C"],
  ["PANTONE_7579_C", "Pantone 7579 C"],
  ["PANTONE_7580_C", "Pantone 7580 C"],
  ["PANTONE_7581_C", "Pantone 7581 C"],
  ["PANTONE_7582_C", "Pantone 7582 C"],
  ["PANTONE_7583_C", "Pantone 7583 C"],
  ["PANTONE_7584_C", "Pantone 7584 C"],
  ["PANTONE_7585_C", "Pantone 7585 C"],
  ["PANTONE_7586_C", "Pantone 7586 C"],
  ["PANTONE_7587_C", "Pantone 7587 C"],
  ["PANTONE_7588_C", "Pantone 7588 C"],
  ["PANTONE_7589_C", "Pantone 7589 C"],
  ["PANTONE_7590_C", "Pantone 7590 C"],
  ["PANTONE_7591_C", "Pantone 7591 C"],
  ["PANTONE_7592_C", "Pantone 7592 C"],
  ["PANTONE_7593_C", "Pantone 7593 C"],
  ["PANTONE_7594_C", "Pantone 7594 C"],
  ["PANTONE_7595_C", "Pantone 7595 C"],
  ["PANTONE_7596_C", "Pantone 7596 C"],
  ["PANTONE_7597_C", "Pantone 7597 C"],
  ["PANTONE_7598_C", "Pantone 7598 C"],
  ["PANTONE_7599_C", "Pantone 7599 C"],
  ["PANTONE_7600_C", "Pantone 7600 C"],
  ["PANTONE_7601_C", "Pantone 7601 C"],
  ["PANTONE_7602_C", "Pantone 7602 C"],
  ["PANTONE_7603_C", "Pantone 7603 C"],
  ["PANTONE_7604_C", "Pantone 7604 C"],
  ["PANTONE_7605_C", "Pantone 7605 C"],
  ["PANTONE_7606_C", "Pantone 7606 C"],
  ["PANTONE_7607_C", "Pantone 7607 C"],
  ["PANTONE_7608_C", "Pantone 7608 C"],
  ["PANTONE_7609_C", "Pantone 7609 C"],
  ["PANTONE_7610_C", "Pantone 7610 C"],
  ["PANTONE_7611_C", "Pantone 7611 C"],
  ["PANTONE_7612_C", "Pantone 7612 C"],
  ["PANTONE_7613_C", "Pantone 7613 C"],
  ["PANTONE_7614_C", "Pantone 7614 C"],
  ["PANTONE_7615_C", "Pantone 7615 C"],
  ["PANTONE_7616_C", "Pantone 7616 C"],
  ["PANTONE_7617_C", "Pantone 7617 C"],
  ["PANTONE_7618_C", "Pantone 7618 C"],
  ["PANTONE_7619_C", "Pantone 7619 C"],
  ["PANTONE_7620_C", "Pantone 7620 C"],
  ["PANTONE_7621_C", "Pantone 7621 C"],
  ["PANTONE_7622_C", "Pantone 7622 C"],
  ["PANTONE_7623_C", "Pantone 7623 C"],
  ["PANTONE_7624_C", "Pantone 7624 C"],
  ["PANTONE_7625_C", "Pantone 7625 C"],
  ["PANTONE_7626_C", "Pantone 7626 C"],
  ["PANTONE_7627_C", "Pantone 7627 C"],
  ["PANTONE_7628_C", "Pantone 7628 C"],
  ["PANTONE_7629_C", "Pantone 7629 C"],
  ["PANTONE_7630_C", "Pantone 7630 C"],
  ["PANTONE_7631_C", "Pantone 7631 C"],
  ["PANTONE_7632_C", "Pantone 7632 C"],
  ["PANTONE_7633_C", "Pantone 7633 C"],
  ["PANTONE_7634_C", "Pantone 7634 C"],
  ["PANTONE_7635_C", "Pantone 7635 C"],
  ["PANTONE_7636_C", "Pantone 7636 C"],
  ["PANTONE_7637_C", "Pantone 7637 C"],
  ["PANTONE_7638_C", "Pantone 7638 C"],
  ["PANTONE_7639_C", "Pantone 7639 C"],
  ["PANTONE_7640_C", "Pantone 7640 C"],
  ["PANTONE_7641_C", "Pantone 7641 C"],
  ["PANTONE_7642_C", "Pantone 7642 C"],
  ["PANTONE_7643_C", "Pantone 7643 C"],
  ["PANTONE_7644_C", "Pantone 7644 C"],
  ["PANTONE_7645_C", "Pantone 7645 C"],
  ["PANTONE_7646_C", "Pantone 7646 C"],
  ["PANTONE_7647_C", "Pantone 7647 C"],
  ["PANTONE_7648_C", "Pantone 7648 C"],
  ["PANTONE_7649_C", "Pantone 7649 C"],
  ["PANTONE_7650_C", "Pantone 7650 C"],
  ["PANTONE_7651_C", "Pantone 7651 C"],
  ["PANTONE_7652_C", "Pantone 7652 C"],
  ["PANTONE_7653_C", "Pantone 7653 C"],
  ["PANTONE_7654_C", "Pantone 7654 C"],
  ["PANTONE_7655_C", "Pantone 7655 C"],
  ["PANTONE_7656_C", "Pantone 7656 C"],
  ["PANTONE_7657_C", "Pantone 7657 C"],
  ["PANTONE_7658_C", "Pantone 7658 C"],
  ["PANTONE_7659_C", "Pantone 7659 C"],
  ["PANTONE_7660_C", "Pantone 7660 C"],
  ["PANTONE_7661_C", "Pantone 7661 C"],
  ["PANTONE_7662_C", "Pantone 7662 C"],
  ["PANTONE_7663_C", "Pantone 7663 C"],
  ["PANTONE_7664_C", "Pantone 7664 C"],
  ["PANTONE_7665_C", "Pantone 7665 C"],
  ["PANTONE_7666_C", "Pantone 7666 C"],
  ["PANTONE_7667_C", "Pantone 7667 C"],
  ["PANTONE_7668_C", "Pantone 7668 C"],
  ["PANTONE_7669_C", "Pantone 7669 C"],
  ["PANTONE_7670_C", "Pantone 7670 C"],
  ["PANTONE_7671_C", "Pantone 7671 C"],
  ["PANTONE_7672_C", "Pantone 7672 C"],
  ["PANTONE_7673_C", "Pantone 7673 C"],
  ["PANTONE_7674_C", "Pantone 7674 C"],
  ["PANTONE_7675_C", "Pantone 7675 C"],
  ["PANTONE_7676_C", "Pantone 7676 C"],
  ["PANTONE_7677_C", "Pantone 7677 C"],
  ["PANTONE_7678_C", "Pantone 7678 C"],
  ["PANTONE_7679_C", "Pantone 7679 C"],
  ["PANTONE_7680_C", "Pantone 7680 C"],
  ["PANTONE_7681_C", "Pantone 7681 C"],
  ["PANTONE_7682_C", "Pantone 7682 C"],
  ["PANTONE_7683_C", "Pantone 7683 C"],
  ["PANTONE_7684_C", "Pantone 7684 C"],
  ["PANTONE_7685_C", "Pantone 7685 C"],
  ["PANTONE_7686_C", "Pantone 7686 C"],
  ["PANTONE_7687_C", "Pantone 7687 C"],
  ["PANTONE_7688_C", "Pantone 7688 C"],
  ["PANTONE_7689_C", "Pantone 7689 C"],
  ["PANTONE_7690_C", "Pantone 7690 C"],
  ["PANTONE_7691_C", "Pantone 7691 C"],
  ["PANTONE_7692_C", "Pantone 7692 C"],
  ["PANTONE_7693_C", "Pantone 7693 C"],
  ["PANTONE_7694_C", "Pantone 7694 C"],
  ["PANTONE_7695_C", "Pantone 7695 C"],
  ["PANTONE_7696_C", "Pantone 7696 C"],
  ["PANTONE_7697_C", "Pantone 7697 C"],
  ["PANTONE_7698_C", "Pantone 7698 C"],
  ["PANTONE_7699_C", "Pantone 7699 C"],
  ["PANTONE_7700_C", "Pantone 7700 C"],
  ["PANTONE_7701_C", "Pantone 7701 C"],
  ["PANTONE_7702_C", "Pantone 7702 C"],
  ["PANTONE_7703_C", "Pantone 7703 C"],
  ["PANTONE_7704_C", "Pantone 7704 C"],
  ["PANTONE_7705_C", "Pantone 7705 C"],
  ["PANTONE_7706_C", "Pantone 7706 C"],
  ["PANTONE_7707_C", "Pantone 7707 C"],
  ["PANTONE_7708_C", "Pantone 7708 C"],
  ["PANTONE_7709_C", "Pantone 7709 C"],
  ["PANTONE_7710_C", "Pantone 7710 C"],
  ["PANTONE_7711_C", "Pantone 7711 C"],
  ["PANTONE_7712_C", "Pantone 7712 C"],
  ["PANTONE_7713_C", "Pantone 7713 C"],
  ["PANTONE_7714_C", "Pantone 7714 C"],
  ["PANTONE_7715_C", "Pantone 7715 C"],
  ["PANTONE_7716_C", "Pantone 7716 C"],
  ["PANTONE_7717_C", "Pantone 7717 C"],
  ["PANTONE_7718_C", "Pantone 7718 C"],
  ["PANTONE_7719_C", "Pantone 7719 C"],
  ["PANTONE_7720_C", "Pantone 7720 C"],
  ["PANTONE_7721_C", "Pantone 7721 C"],
  ["PANTONE_7722_C", "Pantone 7722 C"],
  ["PANTONE_7723_C", "Pantone 7723 C"],
  ["PANTONE_7724_C", "Pantone 7724 C"],
  ["PANTONE_7725_C", "Pantone 7725 C"],
  ["PANTONE_7726_C", "Pantone 7726 C"],
  ["PANTONE_7727_C", "Pantone 7727 C"],
  ["PANTONE_7728_C", "Pantone 7728 C"],
  ["PANTONE_7729_C", "Pantone 7729 C"],
  ["PANTONE_7730_C", "Pantone 7730 C"],
  ["PANTONE_7731_C", "Pantone 7731 C"],
  ["PANTONE_7732_C", "Pantone 7732 C"],
  ["PANTONE_7733_C", "Pantone 7733 C"],
  ["PANTONE_7734_C", "Pantone 7734 C"],
  ["PANTONE_7735_C", "Pantone 7735 C"],
  ["PANTONE_7736_C", "Pantone 7736 C"],
  ["PANTONE_7737_C", "Pantone 7737 C"],
  ["PANTONE_7738_C", "Pantone 7738 C"],
  ["PANTONE_7739_C", "Pantone 7739 C"],
  ["PANTONE_7740_C", "Pantone 7740 C"],
  ["PANTONE_7741_C", "Pantone 7741 C"],
  ["PANTONE_7742_C", "Pantone 7742 C"],
  ["PANTONE_7743_C", "Pantone 7743 C"],
  ["PANTONE_7744_C", "Pantone 7744 C"],
  ["PANTONE_7745_C", "Pantone 7745 C"],
  ["PANTONE_7746_C", "Pantone 7746 C"],
  ["PANTONE_7747_C", "Pantone 7747 C"],
  ["PANTONE_7748_C", "Pantone 7748 C"],
  ["PANTONE_7749_C", "Pantone 7749 C"],
  ["PANTONE_7750_C", "Pantone 7750 C"],
  ["PANTONE_7751_C", "Pantone 7751 C"],
  ["PANTONE_7752_C", "Pantone 7752 C"],
  ["PANTONE_7753_C", "Pantone 7753 C"],
  ["PANTONE_7754_C", "Pantone 7754 C"],
  ["PANTONE_7755_C", "Pantone 7755 C"],
  ["PANTONE_7756_C", "Pantone 7756 C"],
  ["PANTONE_7757_C", "Pantone 7757 C"],
  ["PANTONE_7758_C", "Pantone 7758 C"],
  ["PANTONE_7759_C", "Pantone 7759 C"],
  ["PANTONE_7760_C", "Pantone 7760 C"],
  ["PANTONE_7761_C", "Pantone 7761 C"],
  ["PANTONE_7762_C", "Pantone 7762 C"],
  ["PANTONE_7763_C", "Pantone 7763 C"],
  ["PANTONE_7764_C", "Pantone 7764 C"],
  ["PANTONE_7765_C", "Pantone 7765 C"],
  ["PANTONE_7766_C", "Pantone 7766 C"],
  ["PANTONE_7767_C", "Pantone 7767 C"],
  ["PANTONE_7768_C", "Pantone 7768 C"],
  ["PANTONE_7769_C", "Pantone 7769 C"],
  ["PANTONE_7770_C", "Pantone 7770 C"],
  ["PANTONE_7771_C", "Pantone 7771 C"],
  ["PANTONE_BLACK_0961_C", "Pantone Black 0961 C"],
  ["PANTONE_BLACK_2_C", "Pantone Black 2 C"],
  ["PANTONE_BLACK_3_C", "Pantone Black 3 C"],
  ["PANTONE_BLACK_4_C", "Pantone Black 4 C"],
  ["PANTONE_BLACK_5_C", "Pantone Black 5 C"],
  ["PANTONE_BLACK_6_C", "Pantone Black 6 C"],
  ["PANTONE_BLACK_7_C", "Pantone Black 7 C"],
  ["PANTONE_BLACK_C", "Pantone Black C"],
  ["PANTONE_BLUE_072_C", "Pantone Blue 072 C"],
  ["PANTONE_BLUE_0821_C", "Pantone Blue 0821 C"],
  ["PANTONE_BRIGHT_GREEN_C", "Pantone Bright Green C"],
  ["PANTONE_BRIGHT_ORANGE_C", "Pantone Bright Orange C"],
  ["PANTONE_BRIGHT_RED_C", "Pantone Bright Red C"],
  ["PANTONE_COOL_GRAY_1_C", "Pantone Cool Gray 1 C"],
  ["PANTONE_COOL_GRAY_2_C", "Pantone Cool Gray 2 C"],
  ["PANTONE_COOL_GRAY_3_C", "Pantone Cool Gray 3 C"],
  ["PANTONE_COOL_GRAY_4_C", "Pantone Cool Gray 4 C"],
  ["PANTONE_COOL_GRAY_5_C", "Pantone Cool Gray 5 C"],
  ["PANTONE_COOL_GRAY_6_C", "Pantone Cool Gray 6 C"],
  ["PANTONE_COOL_GRAY_7_C", "Pantone Cool Gray 7 C"],
  ["PANTONE_COOL_GRAY_8_C", "Pantone Cool Gray 8 C"],
  ["PANTONE_COOL_GRAY_9_C", "Pantone Cool Gray 9 C"],
  ["PANTONE_COOL_GRAY_10_C", "Pantone Cool Gray 10 C"],
  ["PANTONE_COOL_GRAY_11_C", "Pantone Cool Gray 11 C"],
  ["PANTONE_DARK_BLUE_C", "Pantone Dark Blue C"],
  ["PANTONE_GREEN_0921_C", "Pantone Green 0921 C"],
  ["PANTONE_GREEN_C", "Pantone Green C"],
  ["PANTONE_MAGENTA_0521_C", "Pantone Magenta 0521 C"],
  ["PANTONE_MEDIUM_BLUE_C", "Pantone Medium Blue C"],
  ["PANTONE_MEDIUM_PURPLE_C", "Pantone Medium Purple C"],
  ["PANTONE_MEDIUM_YELLOW_C", "Pantone Medium Yellow C"],
  ["PANTONE_NEUTRAL_BLACK_C", "Pantone Neutral Black C"],
  ["PANTONE_ORANGE_021_C", "Pantone Orange 021 C"],
  ["PANTONE_PINK_C", "Pantone Pink C"],
  ["PANTONE_PROCESS_BLUE_C", "Pantone Process Blue C"],
  ["PANTONE_PURPLE_C", "Pantone Purple C"],
  ["PANTONE_RED_032_C", "Pantone Red 032 C"],
  ["PANTONE_RED_0331_C", "Pantone Red 0331 C"],
  ["PANTONE_REFLEX_BLUE_C", "Pantone Reflex Blue C"],
  ["PANTONE_RHODAMINE_RED_C", "Pantone Rhodamine Red C"],
  ["PANTONE_RUBINE_RED_C", "Pantone Rubine Red C"],
  ["PANTONE_STRONG_RED_C", "Pantone Strong Red C"],
  ["PANTONE_VIOLET_0631_C", "Pantone Violet 0631 C"],
  ["PANTONE_VIOLET_C", "Pantone Violet C"],
  ["PANTONE_WARM_GRAY_1_C", "Pantone Warm Gray 1 C"],
  ["PANTONE_WARM_GRAY_2_C", "Pantone Warm Gray 2 C"],
  ["PANTONE_WARM_GRAY_3_C", "Pantone Warm Gray 3 C"],
  ["PANTONE_WARM_GRAY_4_C", "Pantone Warm Gray 4 C"],
  ["PANTONE_WARM_GRAY_5_C", "Pantone Warm Gray 5 C"],
  ["PANTONE_WARM_GRAY_6_C", "Pantone Warm Gray 6 C"],
  ["PANTONE_WARM_GRAY_7_C", "Pantone Warm Gray 7 C"],
  ["PANTONE_WARM_GRAY_8_C", "Pantone Warm Gray 8 C"],
  ["PANTONE_WARM_GRAY_9_C", "Pantone Warm Gray 9 C"],
  ["PANTONE_WARM_GRAY_10_C", "Pantone Warm Gray 10 C"],
  ["PANTONE_WARM_GRAY_11_C", "Pantone Warm Gray 11 C"],
  ["PANTONE_WARM_RED_C", "Pantone Warm Red C"],
  ["PANTONE_YELLOW_012_C", "Pantone Yellow 012 C"],
  ["PANTONE_YELLOW_0131_C", "Pantone Yellow 0131 C"],
  ["PANTONE_YELLOW_C", "Pantone Yellow C"],
  ["YELLOW", "Yellow"],
]);

// @ts-ignore
export const getConversionWarningTypeLabels = (t: TFunction<"translation">) => {
  return new Map<ConversionWarningType, string>([
    ["GUESSED_FROM_COLOR", t("ConversionWarningType__GUESSED_FROM_COLOR")],
    ["MACHINE_UNKNOWN", t("ConversionWarningType__MACHINE_UNKNOWN")],
    ["SUBPRODUCT_BINDING_UNKNOWN", t("ConversionWarningType__SUBPRODUCT_BINDING_UNKNOWN")],
    ["WORKSTYLE_UNKNOWN", t("ConversionWarningType__WORKSTYLE_UNKNOWN")],
  ]);
};

// @ts-ignore
export const getCreateOrOverwriteJobMutationErrorLabels = (t: TFunction<"translation">) => {
  return new Map<CreateOrOverwriteJobMutationError, string>([
    ["CANT_OVERWRITE_NON_DRAFT_JOB", t("CreateOrOverwriteJobMutationError__CANT_OVERWRITE_NON_DRAFT_JOB")],
    ["PPS_QUERY_ERROR", t("CreateOrOverwriteJobMutationError__PPS_QUERY_ERROR")],
    ["SP_NOT_FOUND_IN_PPS", t("CreateOrOverwriteJobMutationError__SP_NOT_FOUND_IN_PPS")],
  ]);
};

// @ts-ignore
export const getInvalidErrorDetailLabels = (t: TFunction<"translation">) => {
  return new Map<InvalidErrorDetail, string>([
    ["AMBIGUOUS_TIMEZONE", t("InvalidErrorDetail__AMBIGUOUS_TIMEZONE")],
    ["BLANK", t("InvalidErrorDetail__BLANK")],
    ["CONTRADICTION", t("InvalidErrorDetail__CONTRADICTION")],
    ["DATE", t("InvalidErrorDetail__DATE")],
    ["DATETIME", t("InvalidErrorDetail__DATETIME")],
    ["EMPTY", t("InvalidErrorDetail__EMPTY")],
    ["GENERIC", t("InvalidErrorDetail__GENERIC")],
    ["IMAGE", t("InvalidErrorDetail__IMAGE")],
    ["INCOMPLETE", t("InvalidErrorDetail__INCOMPLETE")],
    ["LIST", t("InvalidErrorDetail__LIST")],
    ["NULL", t("InvalidErrorDetail__NULL")],
    ["NULL_CHARACTER", t("InvalidErrorDetail__NULL_CHARACTER")],
    ["PK_VALUE", t("InvalidErrorDetail__PK_VALUE")],
    ["REQUIRED", t("InvalidErrorDetail__REQUIRED")],
    ["TIME", t("InvalidErrorDetail__TIME")],
  ]);
};

// @ts-ignore
export const getInvalidValueErrorDetailLabels = (t: TFunction<"translation">) => {
  return new Map<InvalidValueErrorDetail, string>([
    ["LIMIT", t("InvalidValueErrorDetail__LIMIT")],
    ["MAX", t("InvalidValueErrorDetail__MAX")],
    ["MAX_DECIMAL_PLACES", t("InvalidValueErrorDetail__MAX_DECIMAL_PLACES")],
    ["MAX_DIGITS", t("InvalidValueErrorDetail__MAX_DIGITS")],
    ["MAX_LENGTH", t("InvalidValueErrorDetail__MAX_LENGTH")],
    ["MAX_WHOLE_DIGITS", t("InvalidValueErrorDetail__MAX_WHOLE_DIGITS")],
    ["MIN", t("InvalidValueErrorDetail__MIN")],
    ["MIN_LENGTH", t("InvalidValueErrorDetail__MIN_LENGTH")],
  ]);
};

// @ts-ignore
export const machineLabels = new Map<Machine, string>([
  ["CANON_VP6180", "CANON VP6180"],
  ["HEIDELBERG_C7100X", "HEIDELBERG C7100X"],
  ["KBA_RA66", "KBA RA66"],
  ["KM_ACCURIOPRESS_C3070", "KM ACCURIOPRESS C3070"],
  ["KM_BIZHUB_C7000", "KM BIZHUB C7000"],
  ["OCE_VP6160", "OCE VP6160"],
]);

// @ts-ignore
export const getNextcloudFieldErrorDetailLabels = (t: TFunction<"translation">) => {
  return new Map<NextcloudFieldErrorDetail, string>([
    ["ERROR", t("NextcloudFieldErrorDetail__ERROR")],
    ["FILE_EMPTY", t("NextcloudFieldErrorDetail__FILE_EMPTY")],
    ["FILE_NOT_EMPTY", t("NextcloudFieldErrorDetail__FILE_NOT_EMPTY")],
    ["FILE_NOT_FOUND", t("NextcloudFieldErrorDetail__FILE_NOT_FOUND")],
    ["INVALID_LINK", t("NextcloudFieldErrorDetail__INVALID_LINK")],
    ["INVALID_MIME_TYPE", t("NextcloudFieldErrorDetail__INVALID_MIME_TYPE")],
    ["NOT_A_DIRECTORY", t("NextcloudFieldErrorDetail__NOT_A_DIRECTORY")],
    ["NOT_A_FILE", t("NextcloudFieldErrorDetail__NOT_A_FILE")],
    ["WRONG_URL", t("NextcloudFieldErrorDetail__WRONG_URL")],
  ]);
};

// @ts-ignore
export const getSubproductDataCloneMutationErrorLabels = (t: TFunction<"translation">) => {
  return new Map<SubproductDataCloneMutationError, string>([
    ["SP_DATA_NOT_FOUND", t("SubproductDataCloneMutationError__SP_DATA_NOT_FOUND")],
    ["SP_DATA_VERSION_EXISTS", t("SubproductDataCloneMutationError__SP_DATA_VERSION_EXISTS")],
  ]);
};

// @ts-ignore
export const getTaskMutationErrorLabels = (t: TFunction<"translation">) => {
  return new Map<TaskMutationError, string>([
    ["CALENDAR_UPDATE_FAILED", t("TaskMutationError__CALENDAR_UPDATE_FAILED")],
    ["ENABLE_DISABLE_NOT_ALLOWED", t("TaskMutationError__ENABLE_DISABLE_NOT_ALLOWED")],
    ["INVALID_TRANSITION", t("TaskMutationError__INVALID_TRANSITION")],
    ["STATE_CHANGE_NOT_ALLOWED", t("TaskMutationError__STATE_CHANGE_NOT_ALLOWED")],
    ["XMF_PUSH_FAILED", t("TaskMutationError__XMF_PUSH_FAILED")],
    ["XMF_PUSH_PPS_QUERY_FAILED", t("TaskMutationError__XMF_PUSH_PPS_QUERY_FAILED")],
    ["XMF_PUSH_REJECTED", t("TaskMutationError__XMF_PUSH_REJECTED")],
    ["XMF_PUSH_SP_INVALID_DATA", t("TaskMutationError__XMF_PUSH_SP_INVALID_DATA")],
    ["XMF_PUSH_SP_MISSING_PAGE_COUNT", t("TaskMutationError__XMF_PUSH_SP_MISSING_PAGE_COUNT")],
    ["XMF_PUSH_SP_NOT_FOUND", t("TaskMutationError__XMF_PUSH_SP_NOT_FOUND")],
  ]);
};

// @ts-ignore
export const getTaskTypeLabels = (t: TFunction<"translation">) => {
  return new Map<TaskType, string>([
    ["COLLATING", t("TaskType__COLLATING")],
    ["COLLECTING", t("TaskType__COLLECTING")],
    ["CREASING", t("TaskType__CREASING")],
    ["CUTTING", t("TaskType__CUTTING")],
    ["DTP_PREPARATION", t("TaskType__DTP_PREPARATION")],
    ["EDGE_GLUING", t("TaskType__EDGE_GLUING")],
    ["EMBOSSING", t("TaskType__EMBOSSING")],
    ["FOLDING", t("TaskType__FOLDING")],
    ["GATHERING", t("TaskType__GATHERING")],
    ["HOLE_MAKING", t("TaskType__HOLE_MAKING")],
    ["LAMINATING", t("TaskType__LAMINATING")],
    ["PERFORATING", t("TaskType__PERFORATING")],
    ["PLATE_MAKING", t("TaskType__PLATE_MAKING")],
    ["PRESSING", t("TaskType__PRESSING")],
    ["PRINTING", t("TaskType__PRINTING")],
    ["RELIEF_UV_VARNISHING", t("TaskType__RELIEF_UV_VARNISHING")],
    ["SCORING", t("TaskType__SCORING")],
    ["SEWING", t("TaskType__SEWING")],
    ["SHAPE_CUTTING", t("TaskType__SHAPE_CUTTING")],
    ["STANDARD_UV_VARNISHING", t("TaskType__STANDARD_UV_VARNISHING")],
    ["VARNISHING", t("TaskType__VARNISHING")],
  ]);
};

// @ts-ignore
export const getUpdateJobMutationErrorDetailLabels = (t: TFunction<"translation">) => {
  return new Map<UpdateJobMutationErrorDetail, string>([
    ["ALL_SP_DATA_MUST_HAVE_URL", t("UpdateJobMutationErrorDetail__ALL_SP_DATA_MUST_HAVE_URL")],
    ["CANT_FIND_PAPER_IN_PPS", t("UpdateJobMutationErrorDetail__CANT_FIND_PAPER_IN_PPS")],
    ["CANT_QUERY_PPS", t("UpdateJobMutationErrorDetail__CANT_QUERY_PPS")],
    ["FRONTCOLORS_OR_BACKCOLORS_REQUIRED", t("UpdateJobMutationErrorDetail__FRONTCOLORS_OR_BACKCOLORS_REQUIRED")],
    ["JOB_ALREADY_COMMITTED", t("UpdateJobMutationErrorDetail__JOB_ALREADY_COMMITTED")],
    ["NEEDS_AT_LEAST_ONE_ENABLED_TASK", t("UpdateJobMutationErrorDetail__NEEDS_AT_LEAST_ONE_ENABLED_TASK")],
    ["NEEDS_AT_LEAST_ONE_SP_DATA", t("UpdateJobMutationErrorDetail__NEEDS_AT_LEAST_ONE_SP_DATA")],
  ]);
};

// @ts-ignore
export const getUpdateJobSubproductDataMutationErrorLabels = (t: TFunction<"translation">) => {
  return new Map<UpdateJobSubproductDataMutationError, string>([
    ["CANNOT_MODIFY_SP_DATA_NOT_DRAFT", t("UpdateJobSubproductDataMutationError__CANNOT_MODIFY_SP_DATA_NOT_DRAFT")],
  ]);
};

// @ts-ignore
export const getUpdateSubproductDataErrorDetailLabels = (t: TFunction<"translation">) => {
  return new Map<UpdateSubproductDataErrorDetail, string>([
    ["SP_DATA_LOCKED", t("UpdateSubproductDataErrorDetail__SP_DATA_LOCKED")],
  ]);
};

// @ts-ignore
export const workStyleLabels = new Map<WorkStyle, string>([
  ["PERFECTING", "Perfecting"],
  ["SIMPLEX", "Simplex"],
  ["WORK_AND_BACK", "Work And Back"],
  ["WORK_AND_TUMBLE", "Work And Tumble"],
  ["WORK_AND_TURN", "Work And Turn"],
]);
