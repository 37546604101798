import { useTranslation } from "react-i18next";
import { useLazyLoadQuery } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import {
  requestedPaperToString,
  sizeToString,
} from "../../../utils/stringifiers";
import {
  Autocomplete,
  createFilterOptions,
  FilledInput,
  FormControl,
  InputLabel,
} from "@mui/material";
import React, { ReactNode } from "react";
import { PaperAutocompleteQuery } from "./__generated__/PaperAutocompleteQuery.graphql";
import FormHelpersErrorsWarnings from "../../common/FormHelperErrorsWarnings";

interface PaperAutocompleteProps {
  formErrors: { [name: string]: ReactNode[] };
  formWarnings?: { [name: string]: ReactNode[] };
  name: string;
  onChange: (...event: any) => void;
  value: string | null;
}

export default function PaperAutocomplete({
  formErrors,
  formWarnings,
  name,
  onChange,
  value,
}: PaperAutocompleteProps) {
  const [t] = useTranslation();

  const queryData = useLazyLoadQuery<PaperAutocompleteQuery>(
    graphql`
      query PaperAutocompleteQuery {
        allPapers {
          id
          class
          weight
          size {
            width
            height
          }
        }
      }
    `,
    {},
    { fetchPolicy: "store-or-network" }
  );

  const paperLabels = new Map<string, string>(
    (queryData.allPapers || [])
      .filter(
        (paper) => paper?.size?.width != null && paper?.size?.height != null
      )
      .map((paper) => [
        `${paper!.id}`,
        `${requestedPaperToString(paper)}, ${sizeToString(paper?.size)}`,
      ])
  );
  const paperAutocompleteOptions = (queryData.allPapers || [])
    .filter(
      (paper) => paper?.size?.width != null && paper?.size?.height != null
    )
    .map((paper) => `${paper!.id}`);
  const filterOptions = createFilterOptions({
    limit: 10,
    stringify: (paper_id: string) => paperLabels.get(paper_id) || "",
  });
  return (
    <FormControl
      error={name in formErrors}
      variant="filled"
      sx={{ width: "100%" }}
    >
      <InputLabel htmlFor={`input-${name}`}>{t("Paper")}</InputLabel>
      <Autocomplete
        options={paperAutocompleteOptions}
        filterOptions={filterOptions}
        getOptionLabel={(paper_id) => paperLabels.get(paper_id) || ""}
        sx={{ width: "100%" }}
        renderInput={(params) => (
          <FilledInput
            {...params.InputProps}
            id={`input-${name}`}
            inputProps={params.inputProps}
            sx={{ width: "100%" }}
          />
        )}
        onChange={(event, item) => onChange(item)}
        value={value || null}
      />
      <FormHelpersErrorsWarnings
        formErrors={formErrors}
        formWarnings={formWarnings}
        name={name}
      />
    </FormControl>
  );
}
