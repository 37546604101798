/**
 * @generated SignedSource<<104565d66a277a1cce725ea51ffed35c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type SubproductDataCloneMutationError = "SP_DATA_NOT_FOUND" | "SP_DATA_VERSION_EXISTS" | "%future added value";
export type SubproductDataCloneMutationInput = {
  sourceId: string;
  newVersion: string;
  clientMutationId?: string | null;
};
export type SubproductDataCloneDialogMutation$variables = {
  input: SubproductDataCloneMutationInput;
};
export type SubproductDataCloneDialogMutation$data = {
  readonly cloneSubproductData: {
    readonly subproductData: {
      readonly id: string;
      readonly pk: number;
    } | null;
    readonly errors: ReadonlyArray<SubproductDataCloneMutationError> | null;
  } | null;
};
export type SubproductDataCloneDialogMutation = {
  variables: SubproductDataCloneDialogMutation$variables;
  response: SubproductDataCloneDialogMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "SubproductDataCloneMutationPayload",
    "kind": "LinkedField",
    "name": "cloneSubproductData",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "SubproductDataNode",
        "kind": "LinkedField",
        "name": "subproductData",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "pk",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "errors",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SubproductDataCloneDialogMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SubproductDataCloneDialogMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "d30ecf090016376ef9e62a93a8658f1c",
    "id": null,
    "metadata": {},
    "name": "SubproductDataCloneDialogMutation",
    "operationKind": "mutation",
    "text": "mutation SubproductDataCloneDialogMutation(\n  $input: SubproductDataCloneMutationInput!\n) {\n  cloneSubproductData(input: $input) {\n    subproductData {\n      id\n      pk\n    }\n    errors\n  }\n}\n"
  }
};
})();

(node as any).hash = "6a717eacc3597e7f9b5673dcf8037a23";

export default node;
