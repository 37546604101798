import graphql from "babel-plugin-relay/macro";
import {
  Alert,
  IconButton,
  ListItemButton,
  ListItemText,
  Snackbar,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-relay";
import { getUpdateJobSubproductDataMutationErrorLabels } from "../../../__generatedEnumLabels";
import { UpdateJobSubproductDataMutationError } from "../../job/JobDetail/__generated__/SubproductDataSelectorListMutation.graphql";
import DeleteIcon from "@mui/icons-material/Delete";
import queryErrorToMessages from "../../../utils/queryErrorToMessages";
import { SubproductDataListNotFoundItemMutation } from "./__generated__/SubproductDataListNotFoundItemMutation.graphql";

interface SubproductDataListNotFoundItemProps {
  jobId: string;
  spDataId: string;
}

export default function SubproductDataListNotFoundItem({
  jobId,
  spDataId,
}: SubproductDataListNotFoundItemProps) {
  const { t } = useTranslation();

  const [commit, isInFlight] =
    useMutation<SubproductDataListNotFoundItemMutation>(
      graphql`
        mutation SubproductDataListNotFoundItemMutation(
          $jobId: ID!
          $spDataId: ID!
        ) {
          updateJobSubproductData(
            input: { id: $jobId, spDataId: $spDataId, action: UNASSIGN }
          ) {
            job {
              id
              ...RelatedSubproductsList_subproductData
            }
            errors
          }
        }
      `
    );

  const [errorSnackbar, setErrorSnackbar] = useState<{
    open: boolean;
    errorKeys: readonly string[];
    mutationErrors: readonly UpdateJobSubproductDataMutationError[];
  }>({ open: false, errorKeys: [], mutationErrors: [] });

  return (
    <>
      <Snackbar
        open={errorSnackbar.open}
        autoHideDuration={5000}
        onClose={() =>
          setErrorSnackbar({
            ...errorSnackbar,
            open: false,
          })
        }
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={() =>
            setErrorSnackbar({
              ...errorSnackbar,
              open: false,
            })
          }
          severity="error"
          sx={{ width: "100%" }}
        >
          <p>{t("Error when assigning subproduct")}</p>
          {errorSnackbar.errorKeys.map((key) => (
            <p key={key}>{t(key)}</p>
          ))}
          {errorSnackbar.mutationErrors.map((key) => (
            <p key={key}>
              {getUpdateJobSubproductDataMutationErrorLabels(t).get(key)}
            </p>
          ))}
        </Alert>
      </Snackbar>
      <ListItemButton>
        <ListItemText>
          <Typography sx={{ color: "#e89641" }}>
            {t("This subproduct can't be found in PPS")}
          </Typography>
        </ListItemText>
        <IconButton
          edge="end"
          onClick={(event) => {
            commit({
              variables: {
                jobId: jobId,
                spDataId: spDataId,
              },
              onCompleted: (response) => {
                if (
                  (response.updateJobSubproductData?.errors || []).length > 0
                ) {
                  setErrorSnackbar({
                    open: true,
                    mutationErrors: response.updateJobSubproductData!.errors!,
                    errorKeys: [],
                  });
                }
              },
              onError: (error) => {
                const errorKeys = queryErrorToMessages(error);
                console.log(error);
                setErrorSnackbar({
                  open: true,
                  errorKeys: [
                    errorKeys.error.primaryError,
                    errorKeys.error.secondaryError,
                  ],
                  mutationErrors: [],
                });
              },
            });
          }}
          disabled={isInFlight}
        >
          <DeleteIcon />
        </IconButton>
      </ListItemButton>
    </>
  );
}
