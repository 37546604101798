import React from "react";
import JobManagerAppBar from "./JobManagerAppBar/JobManagerAppBar";
import NotFoundCard from "../common/NotFoundCard";

interface NotFoundProps {
  toggleDrawer: (
    open: boolean
  ) => (event: React.KeyboardEvent | React.MouseEvent) => void;
}

export default function NotFoundView({ toggleDrawer }: NotFoundProps) {
  return (
    <>
      <JobManagerAppBar toggleDrawer={toggleDrawer} />
      <NotFoundCard />
    </>
  );
}
