/**
 * @generated SignedSource<<c0423cfa3e17addf664137f724c03341>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type JobDetailViewFastQuery$variables = {
  pk: number;
};
export type JobDetailViewFastQuery$data = {
  readonly job: {
    readonly id: string;
    readonly pk: number;
    readonly isReady: boolean;
    readonly subproductData: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
        } | null;
      } | null>;
    };
    readonly " $fragmentSpreads": FragmentRefs<"JobDataCard_params" | "TasksCard_tasks">;
  } | null;
};
export type JobDetailViewFastQuery = {
  variables: JobDetailViewFastQuery$variables;
  response: JobDetailViewFastQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "pk"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "pk",
    "variableName": "pk"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "pk",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isReady",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "SubproductDataNodeConnection",
  "kind": "LinkedField",
  "name": "subproductData",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "SubproductDataNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "SubproductDataNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v2/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "state",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "JobDetailViewFastQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "JobNode",
        "kind": "LinkedField",
        "name": "job",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "JobDataCard_params"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "TasksCard_tasks"
          },
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "JobDetailViewFastQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "JobNode",
        "kind": "LinkedField",
        "name": "job",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v6/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "jdfId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "frontcolors",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "backcolors",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "binding",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "printingMachine",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "workstyle",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "note",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "signaturesPerSheetX",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "signaturesPerSheetY",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "printSheetCount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lamination",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "creasing",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "uvFinish",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "shapeCutting",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "embossing",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "paperNettPerSheet",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "paperGrossPerSheet",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "paperReservePerJob",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "paperId",
            "storageKey": null
          },
          {
            "kind": "ClientExtension",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CreateOrOverwriteJobMutationWarningType",
                "kind": "LinkedField",
                "name": "postPopulationWarnings",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "field",
                    "storageKey": null
                  },
                  (v7/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "value",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ]
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "TaskNodeConnection",
            "kind": "LinkedField",
            "name": "taskSet",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "TaskNodeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TaskNode",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v7/*: any*/),
                      (v6/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "enabled",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "92278632eedbb9ff56be745b7cd5c3bb",
    "id": null,
    "metadata": {},
    "name": "JobDetailViewFastQuery",
    "operationKind": "query",
    "text": "query JobDetailViewFastQuery(\n  $pk: Int!\n) {\n  job(pk: $pk) {\n    id\n    pk\n    isReady\n    ...JobDataCard_params\n    ...TasksCard_tasks\n    subproductData {\n      edges {\n        node {\n          id\n        }\n      }\n    }\n  }\n}\n\nfragment JobDataCard_params on JobNode {\n  id\n  pk\n  state\n  isReady\n  jdfId\n  name\n  frontcolors\n  backcolors\n  binding\n  printingMachine\n  workstyle\n  note\n  signaturesPerSheetX\n  signaturesPerSheetY\n  printSheetCount\n  lamination\n  creasing\n  uvFinish\n  shapeCutting\n  embossing\n  paperNettPerSheet\n  paperGrossPerSheet\n  paperReservePerJob\n  paperId\n}\n\nfragment TasksCard_tasks on JobNode {\n  taskSet {\n    edges {\n      node {\n        id\n        type\n        state\n        enabled\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "e555f0d7fadf5410f4aa210e197ef502";

export default node;
