export default function queryErrorToMessages(error: any) {
  // WARNING: primaryError and secondaryError are used as keys for the i18next `t` function, but
  // i18next-parser can't detect it. Don't forget to include comment like `t([...])` every time
  // you define a new error!
  try {
    if (error.name != null) {
      if (error.name === "RRNLRequestError") {
        if (error.res.ok === true) {
          for (const queryError of error.res.errors) {
            if (queryError.message === "PPS_CONNECTION_ERROR") {
              // t('PPS connection error')
              // t('Cant connect to PPS')
              return {
                error: {
                  primaryError: "PPS connection error",
                  secondaryError: "Cant connect to PPS",
                },
              };
            }
            if (queryError.message === "PPS_QUERY_ERROR") {
              // t('PPS communication error')
              // t('Error communicating with PPS')
              return {
                error: {
                  primaryError: "PPS communication error",
                  secondaryError: "Error communicating with PPS",
                },
              };
            }
          }
        }
        if (error.res.status === 502 || error.res.status === 504) {
          // t('Temporarily unavailable')
          // t('The service is temporarily unavailable')
          return {
            error: {
              primaryError: "Temporarily unavailable",
              secondaryError: "The service is temporarily unavailable",
            },
          };
        }
        if (error.res.status >= 500 && error.res.status < 600) {
          // t('Server error')
          // t('An error has occured on the server')
          return {
            error: {
              primaryError: "Server error",
              secondaryError: "An error has occured on the server",
            },
          };
        }
      }
    }
  } catch (e) {
    // Ignore it, let's return a generic error at the end of the function
  }

  // t('Unknown error')
  // t('An unknown error has occurred')
  return {
    error: {
      primaryError: "Unknown error",
      secondaryError: "An unknown error has occurred",
    },
  };
}
