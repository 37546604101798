import InfoItem from "../../common/InfoItem";
import React, { ReactNode } from "react";
import { Control, Controller } from "react-hook-form";
import { FilledInput, FormControl, Grid, InputLabel } from "@mui/material";
import FormHelpersErrorsWarnings from "../../common/FormHelperErrorsWarnings";

interface InfoItemOrFormInputProps {
  control: Control;
  description: string;
  formErrors: { [name: string]: ReactNode[] };
  formWarnings?: { [name: string]: ReactNode[] };
  initialValue: number | string | null;
  multiline?: boolean;
  name: string;
  readOnly: boolean;
  type: "integer" | "float" | "text";
  xs: number;
  sm?: number;
}

export default function InfoItemOrFormInput({
  control,
  description,
  formErrors,
  formWarnings,
  initialValue,
  multiline,
  name,
  readOnly,
  type,
  xs,
  sm,
}: InfoItemOrFormInputProps) {
  if (readOnly) {
    return (
      <InfoItem
        xs={xs}
        sm={sm}
        value={initialValue}
        description={description}
      />
    );
  }

  return (
    <Grid item xs={xs} sm={sm}>
      <Controller
        control={control}
        name={name}
        defaultValue={initialValue}
        render={({ field: { onChange, value } }) => (
          <FormControl
            error={name in formErrors}
            variant="filled"
            sx={{ width: "100%" }}
          >
            <InputLabel htmlFor={`input-${name}`}>{description}</InputLabel>
            <FilledInput
              id={`input-${name}`}
              value={value}
              type={type === "integer" || type === "float" ? "number" : type}
              onChange={(event) => {
                let val: string | number = event.target.value;
                if (type === "integer") {
                  val = parseInt(val);
                } else if (type === "float") {
                  val = parseFloat(val);
                }
                return onChange(val);
              }}
              multiline={multiline}
            />
            <FormHelpersErrorsWarnings
              formErrors={formErrors}
              formWarnings={formWarnings}
              name={name}
            />
          </FormControl>
        )}
      />
    </Grid>
  );
}
