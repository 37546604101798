/**
 * @generated SignedSource<<2cc250c29cc59dec208704a75153ba50>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type SubproductDataCardQuery$variables = {
  pk: number;
};
export type SubproductDataCardQuery$data = {
  readonly subproductData: {
    readonly id: string;
    readonly pk: number;
    readonly dataUrl: string;
    readonly version: string;
    readonly isLocked: boolean;
    readonly jobSet: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly jdfId: string;
        } | null;
      } | null>;
    };
    readonly subproduct: {
      readonly number: string | null;
      readonly name: string | null;
      readonly binding: string | null;
      readonly pagesCount: number | null;
      readonly productPart: string | null;
      readonly run: number | null;
      readonly color: {
        readonly front: number | null;
        readonly back: number | null;
      } | null;
      readonly requestedPaper: {
        readonly class: string | null;
        readonly weight: number | null;
      } | null;
      readonly sizeOpen: {
        readonly width: number | null;
        readonly height: number | null;
      } | null;
      readonly sizeClosed: {
        readonly width: number | null;
        readonly height: number | null;
      } | null;
      readonly machine: string | null;
      readonly impositionPattern: string | null;
      readonly workstyle: string | null;
      readonly lamination: string | null;
      readonly creasing: string | null;
      readonly uvFinish: string | null;
      readonly shapeCutting: string | null;
      readonly embossing: string | null;
      readonly varnish: string | null;
      readonly product: {
        readonly name: string | null;
        readonly number: string | null;
        readonly dueDate: any | null;
        readonly project: {
          readonly name: string | null;
          readonly number: string | null;
        } | null;
      } | null;
    } | null;
  } | null;
};
export type SubproductDataCardQuery = {
  variables: SubproductDataCardQuery$variables;
  response: SubproductDataCardQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "pk"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "pk",
    "variableName": "pk"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "pk",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "dataUrl",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "version",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isLocked",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "jdfId",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "number",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v10 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "width",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "height",
    "storageKey": null
  }
],
v11 = {
  "alias": null,
  "args": null,
  "concreteType": "SubproductType",
  "kind": "LinkedField",
  "name": "subproduct",
  "plural": false,
  "selections": [
    (v8/*: any*/),
    (v9/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "binding",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "pagesCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "productPart",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "run",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "NumOfSeparationsType",
      "kind": "LinkedField",
      "name": "color",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "front",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "back",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PaperType",
      "kind": "LinkedField",
      "name": "requestedPaper",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "class",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "weight",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SizeType",
      "kind": "LinkedField",
      "name": "sizeOpen",
      "plural": false,
      "selections": (v10/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SizeType",
      "kind": "LinkedField",
      "name": "sizeClosed",
      "plural": false,
      "selections": (v10/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "machine",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "impositionPattern",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "workstyle",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lamination",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "creasing",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "uvFinish",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "shapeCutting",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "embossing",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "varnish",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ProductType",
      "kind": "LinkedField",
      "name": "product",
      "plural": false,
      "selections": [
        (v9/*: any*/),
        (v8/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "dueDate",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ProjectType",
          "kind": "LinkedField",
          "name": "project",
          "plural": false,
          "selections": [
            (v9/*: any*/),
            (v8/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SubproductDataCardQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SubproductDataNode",
        "kind": "LinkedField",
        "name": "subproductData",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "JobNodeConnection",
            "kind": "LinkedField",
            "name": "jobSet",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "JobNodeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "JobNode",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v7/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v11/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SubproductDataCardQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SubproductDataNode",
        "kind": "LinkedField",
        "name": "subproductData",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "JobNodeConnection",
            "kind": "LinkedField",
            "name": "jobSet",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "JobNodeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "JobNode",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v7/*: any*/),
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v11/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "73e2871ca4f569ad8c88f6f4f23b87c7",
    "id": null,
    "metadata": {},
    "name": "SubproductDataCardQuery",
    "operationKind": "query",
    "text": "query SubproductDataCardQuery(\n  $pk: Int!\n) {\n  subproductData(pk: $pk) {\n    id\n    pk\n    dataUrl\n    version\n    isLocked\n    jobSet {\n      edges {\n        node {\n          jdfId\n          id\n        }\n      }\n    }\n    subproduct {\n      number\n      name\n      binding\n      pagesCount\n      productPart\n      run\n      color {\n        front\n        back\n      }\n      requestedPaper {\n        class\n        weight\n      }\n      sizeOpen {\n        width\n        height\n      }\n      sizeClosed {\n        width\n        height\n      }\n      machine\n      impositionPattern\n      workstyle\n      lamination\n      creasing\n      uvFinish\n      shapeCutting\n      embossing\n      varnish\n      product {\n        name\n        number\n        dueDate\n        project {\n          name\n          number\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "f1139528b279b412d82796f36d842134";

export default node;
