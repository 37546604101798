import InfoItem from "../../common/InfoItem";
import React, { ReactNode } from "react";
import { Control, Controller } from "react-hook-form";
import {
  Autocomplete,
  Divider,
  FilledInput,
  FormControl,
  Grid,
  InputLabel,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import intersperse from "../../../utils/intersperse";
import FormHelpersErrorsWarnings from "../../common/FormHelperErrorsWarnings";

interface InfoItemOrFormInputProps {
  control: Control;
  description: string;
  filterOptions?: (options: any[], state: any) => any[];
  formErrors: { [name: string]: ReactNode[] };
  formWarnings?: { [name: string]: ReactNode[] };
  initialValue?: string | null;
  initialValues?: readonly string[] | null;
  labels: Map<any, string>;
  multiple?: boolean;
  name: string;
  options: any[];
  readOnly: boolean;
  xs: number;
  sm?: number;
}

export default function InfoItemOrFormInput({
  control,
  description,
  filterOptions,
  formErrors,
  formWarnings,
  initialValue,
  initialValues,
  labels,
  multiple,
  name,
  options,
  readOnly,
  xs,
  sm,
}: InfoItemOrFormInputProps) {
  if (readOnly) {
    if (multiple) {
      return (
        <Grid item xs={xs} sm={sm}>
          <List sx={{ width: "100%" }}>
            {intersperse(
              (initialValues || []).map((item) => (
                <ListItem key={`bc_${item}`}>
                  <ListItemText primary={labels.get(item)} />
                </ListItem>
              )),
              <Divider />
            )}
          </List>
        </Grid>
      );
    } else {
      return (
        <InfoItem
          xs={xs}
          sm={sm}
          value={initialValue == null ? null : labels.get(initialValue)}
          description={description}
        />
      );
    }
  }

  return (
    <Grid item xs={xs} sm={sm}>
      <Controller
        control={control}
        name={name}
        defaultValue={multiple ? [...(initialValues || [])] : initialValue}
        render={({ field: { onChange, value } }) => (
          <FormControl
            error={name in formErrors}
            variant="filled"
            sx={{ width: "100%" }}
          >
            <InputLabel htmlFor={`input-${name}`}>{description}</InputLabel>
            <Autocomplete
              multiple={multiple}
              options={options}
              filterOptions={filterOptions}
              defaultValue={initialValue}
              getOptionLabel={(option) => labels.get(option) || ""}
              sx={{ width: "100%" }}
              renderInput={(params) => {
                return (
                  <FilledInput
                    {...params.InputProps}
                    id={`input-${name}`}
                    inputProps={params.inputProps}
                    sx={{ width: "100%" }}
                  />
                );
              }}
              onChange={(event, item) => onChange(item)}
              value={value || (multiple ? [] : null)}
            />
            <FormHelpersErrorsWarnings
              formErrors={formErrors}
              formWarnings={formWarnings}
              name={name}
            />
          </FormControl>
        )}
      />
    </Grid>
  );
}
