import React from "react";
import { Suspense } from "react";

import CssBaseline from "@mui/material/CssBaseline";
import ThemeProvider from "@mui/material/styles/ThemeProvider";
import createTheme from "@mui/material/styles/createTheme";
import { RelayEnvironmentProvider, useQueryLoader } from "react-relay";

import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

import JobListView, {
  jobListDefaultStates,
} from "./components/job/JobListView";
import { JobListViewQuery as JobListViewQueryType } from "./components/job/__generated__/JobListViewQuery.graphql";
import { TaskListViewQuery as TaskListViewQueryType } from "./components/tasks/__generated__/TaskListViewQuery.graphql";
import { JobDetailViewFastQuery as JobDetailViewFastQueryType } from "./components/job/JobDetail/__generated__/JobDetailViewFastQuery.graphql";
import { JobDetailViewSlowQuery as JobDetailViewSlowQueryType } from "./components/job/JobDetail/__generated__/JobDetailViewSlowQuery.graphql";
import { SubproductDataCardQuery as SubproductDataCardQueryType } from "./components/subproduct-data/SubproductDataDetail/__generated__/SubproductDataCardQuery.graphql";
import SubproductDataCardQuery from "./components/subproduct-data/SubproductDataDetail/__generated__/SubproductDataCardQuery.graphql";
import { SubproductDataListViewQuery as SubproductDataListViewQueryType } from "./components/subproduct-data/SubproductDataList/__generated__/SubproductDataListViewQuery.graphql";
import SubproductDataListViewQuery from "./components/subproduct-data/SubproductDataList/__generated__/SubproductDataListViewQuery.graphql";
import JobDetailViewFastQuery from "./components/job/JobDetail/__generated__/JobDetailViewFastQuery.graphql";
import JobDetailViewSlowQuery from "./components/job/JobDetail/__generated__/JobDetailViewSlowQuery.graphql";
import JobListViewQuery from "./components/job/__generated__/JobListViewQuery.graphql";
import TaskListViewQuery from "./components/tasks/__generated__/TaskListViewQuery.graphql";
import relay_environment from "./relayEnvironment";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import i18n from "i18next";
import JobManagerDrawer from "./components/navigation/JobManagerDrawer";
import { Route, Routes, BrowserRouter } from "react-suspense-router";
import TaskListView, {
  taskListDefaultStates,
} from "./components/tasks/TaskListView";
import SubproductDataDetailView from "./components/subproduct-data/SubproductDataDetail/SubproductDataDetailView";
import { Box, CircularProgress } from "@mui/material";
import NotFoundView from "./components/navigation/NotFoundView";
import JobDetailView from "./components/job/JobDetail/JobDetailView";
import SubproductDataListView from "./components/subproduct-data/SubproductDataList/SubproductDataListView";

const theme = createTheme({
  palette: {
    background: {
      default: "#f5f5f5",
    },
  },
});

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "en-US",
    supportedLngs: ["en-US", "sk-SK"],
    debug: process.env.NODE_ENV === "development",
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    backend: {
      loadPath: "/static/locales/{{lng}}/{{ns}}.json",
    },
    load: "currentOnly",
  });

function JMRouter() {
  const [drawerState, setDrawerState] = React.useState(false);

  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event &&
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setDrawerState(open);
    };

  const [jobDetailFastQueryReference, loadJobDetailFastQuery] =
    useQueryLoader<JobDetailViewFastQueryType>(JobDetailViewFastQuery);
  const [jobDetailSlowQueryReference, loadJobDetailSlowQuery] =
    useQueryLoader<JobDetailViewSlowQueryType>(JobDetailViewSlowQuery);
  const [subproductDataQueryReference, loadSubproductDataQuery] =
    useQueryLoader<SubproductDataCardQueryType>(SubproductDataCardQuery);
  const [jobListQueryReference, loadJobListQuery] =
    useQueryLoader<JobListViewQueryType>(JobListViewQuery);
  const [taskListQueryReference, loadTaskListQuery] =
    useQueryLoader<TaskListViewQueryType>(TaskListViewQuery);
  const [subproductDataListQueryReference, loadSubproductDataListQuery] =
    useQueryLoader<SubproductDataListViewQueryType>(
      SubproductDataListViewQuery
    );

  return (
    <div>
      <JobManagerDrawer toggleDrawer={toggleDrawer} drawerState={drawerState} />
      <Routes>
        <Route
          path="/jobs/:id"
          fetchData={(match: { params: { id: number } }) => {
            loadJobDetailFastQuery(
              { pk: match.params.id },
              { fetchPolicy: "network-only" }
            );
            loadJobDetailSlowQuery(
              { pk: match.params.id },
              { fetchPolicy: "network-only" }
            );
          }}
        >
          {jobDetailFastQueryReference == null ||
          jobDetailSlowQueryReference == null ? null : (
            <JobDetailView
              jobDetailFastQueryRef={jobDetailFastQueryReference!}
              jobDetailSlowQueryRef={jobDetailSlowQueryReference!}
              toggleDrawer={toggleDrawer}
            />
          )}
        </Route>
        <Route
          path="/subproduct-data/:id"
          fetchData={(match: { params: { id: number } }) =>
            loadSubproductDataQuery(
              { pk: match.params.id },
              { fetchPolicy: "network-only" }
            )
          }
        >
          {subproductDataQueryReference == null ? null : (
            <SubproductDataDetailView
              toggleDrawer={toggleDrawer}
              subproductDataQueryRef={subproductDataQueryReference!}
            />
          )}
        </Route>
        <Route
          path="/subproduct-data/"
          fetchData={() =>
            loadSubproductDataListQuery({}, { fetchPolicy: "network-only" })
          }
        >
          {subproductDataListQueryReference == null ? null : (
            <SubproductDataListView
              toggleDrawer={toggleDrawer}
              subproductDataListQueryRef={subproductDataListQueryReference!}
            />
          )}
        </Route>
        <Route
          path="/tasks/"
          fetchData={() =>
            loadTaskListQuery(
              { states: taskListDefaultStates },
              { fetchPolicy: "network-only" }
            )
          }
        >
          {taskListQueryReference == null ? null : (
            <TaskListView
              toggleDrawer={toggleDrawer}
              taskListQueryRef={taskListQueryReference!}
            />
          )}
        </Route>
        <Route
          path="/"
          fetchData={() =>
            loadJobListQuery(
              { states: jobListDefaultStates },
              { fetchPolicy: "network-only" }
            )
          }
        >
          {jobListQueryReference == null ? null : (
            <JobListView
              toggleDrawer={toggleDrawer}
              jobListQueryRef={jobListQueryReference!}
            />
          )}
        </Route>
        <Route
          path={"*"}
          element={<NotFoundView toggleDrawer={toggleDrawer} />}
        />
      </Routes>
    </div>
  );
}

function App() {
  return (
    <div className="App">
      <RelayEnvironmentProvider environment={relay_environment}>
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <Suspense
              fallback={
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    height: "100%",
                  }}
                >
                  <CircularProgress />
                </Box>
              }
            >
              <CssBaseline />
              <JMRouter />
            </Suspense>
          </BrowserRouter>
        </ThemeProvider>
      </RelayEnvironmentProvider>
    </div>
  );
}

export default App;
