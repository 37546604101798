import React, { useEffect, useState, Suspense } from "react";

import {
  Box,
  Card,
  Chip,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Skeleton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  LoadMoreFn,
  PreloadedQuery,
  usePaginationFragment,
  usePreloadedQuery,
} from "react-relay";
import graphql from "babel-plugin-relay/macro";
import { useTranslation } from "react-i18next";
import JobManagerAppBar from "../navigation/JobManagerAppBar/JobManagerAppBar";
import LoadMoreButton from "../common/LoadMoreButton";
import ListViewFilter, {
  ListViewFilterContainer,
} from "../common/ListViewFilter";
import StatusChip from "../common/StatusChip";
import { NavLink } from "react-router-dom";
import ErrorBoundaryCard from "../common/ErrorBoundaryCard";
import NewJobFAB from "./NewJobFAB";
import { JobListViewComponent_jobs$data } from "./__generated__/JobListViewComponent_jobs.graphql";
import {
  JobListViewQuery,
  JobState,
} from "./__generated__/JobListViewQuery.graphql";

interface JobListCardProps {
  data: JobListViewComponent_jobs$data;
  hasNext: boolean;
  loadNext: LoadMoreFn<any>;
  isLoadingNext: boolean;
}

function JobListCard({
  data,
  hasNext,
  loadNext,
  isLoadingNext,
}: JobListCardProps) {
  const { t } = useTranslation();

  const listItems = (data.allJobs?.edges || [])
    .filter((edge) => edge !== null && edge.node !== null)
    .map((edge) => (
      <ListItemButton
        key={edge!.node!.id}
        component={NavLink}
        to={"/jobs/" + edge!.node!.pk}
      >
        <ListItemText
          primary={"Job " + edge!.node!.jdfId}
          secondary={edge!.node!.name}
        />
        <StatusChip status={edge!.node!.state} />
      </ListItemButton>
    ));

  const content =
    listItems.length > 0 ? (
      <>
        <List>{listItems}</List>
        {hasNext ? (
          <LoadMoreButton loadNext={loadNext} isLoadingNext={isLoadingNext} />
        ) : null}
      </>
    ) : (
      <Typography
        sx={{ fontStyle: "italic", textAlign: "center", margin: "32px" }}
      >
        {t("No jobs found")}
      </Typography>
    );

  return (
    <Card
      variant="outlined"
      sx={{
        maxWidth: "600px",
        margin: "0 auto",
        bgcolor: "background.paper",
      }}
    >
      <Box
        sx={{
          margin: "16px auto",
        }}
      >
        {content}
      </Box>
    </Card>
  );
}

interface JobListFilterProps {
  data: JobListViewComponent_jobs$data;
  filterState: Set<JobState>;
  setFilterState: React.Dispatch<React.SetStateAction<Set<JobState>>>;
  filterDialogOpen?: boolean;
  onFilterDialogClose?: () => void;
}

function JobListFilter({
  data,
  filterState,
  setFilterState,
  filterDialogOpen,
  onFilterDialogClose,
}: JobListFilterProps) {
  const { t } = useTranslation();
  const jobStates = data.allJobs?.jobStates;

  if (jobStates == null) {
    return null;
  }

  const jobStateLabels = new Map<JobState, string>([
    ["DRAFT", t("Draft")],
    ["READY", t("Ready")],
    ["IN_PROGRESS", t("In progress")],
    ["DONE", t("Done")],
  ]);
  const jobStateAmounts = new Map<JobState, { total: number; shown: number }>(
    jobStates!.map((state) => [
      state.stateKey,
      { total: state.stateTotalCount, shown: state.stateCount },
    ])
  );

  return (
    <ListViewFilterContainer
      dialogOpen={filterDialogOpen}
      onDialogClose={onFilterDialogClose}
    >
      <ListViewFilter
        title={t("Filter by state")}
        keys={jobStates.map((state) => state.stateKey)}
        labels={jobStateLabels}
        amounts={jobStateAmounts}
        state={filterState}
        setState={setFilterState}
      />
    </ListViewFilterContainer>
  );
}

interface JobListProps {
  jobListQueryRef: PreloadedQuery<JobListViewQuery>;
  toggleDrawer: (
    open: boolean
  ) => (event: React.KeyboardEvent | React.MouseEvent) => void;
}

interface JobListContentProps {
  jobListQueryRef: PreloadedQuery<JobListViewQuery>;
  searchString: string | null;
  filterDialogOpen?: boolean;
  onFilterDialogClose?: () => void;
}

function JobListContent({
  jobListQueryRef,
  searchString,
  filterDialogOpen,
  onFilterDialogClose,
}: JobListContentProps) {
  const [filterState, setFilterState] = useState<Set<JobState>>(
    new Set<JobState>(jobListDefaultStates)
  );

  const queryData = usePreloadedQuery(
    graphql`
      query JobListViewQuery(
        $states: [JobState!]
        $search: String
        $pk: Float
      ) {
        ...JobListViewComponent_jobs
      }
    `,
    jobListQueryRef
  );

  const { data, refetch, loadNext, isLoadingNext, hasNext } =
    usePaginationFragment<JobListViewQuery, any>(
      graphql`
        fragment JobListViewComponent_jobs on Query
        @argumentDefinitions(
          cursor: { type: "String" }
          count: { type: "Int", defaultValue: 30 }
        )
        @refetchable(queryName: "JobListPaginationQuery") {
          allJobs(
            first: $count
            after: $cursor
            state: $states
            name_Icontains: $search
            id: $pk
          ) @connection(key: "JobList_allJobs") {
            edges {
              node {
                pk
                id
                name
                jdfId
                state
              }
            }
            jobStates {
              stateKey
              stateCount
              stateTotalCount
            }
          }
        }
      `,
      queryData
    );

  useEffect(() => {
    const variables =
      searchString != null && searchString.match("^J|jP|p[0-9]+$")
        ? {
            states: Array.from(filterState),
            pk: parseInt(searchString.substring(2)),
            search: null,
          }
        : {
            states: Array.from(filterState),
            search: searchString,
          };
    refetch(variables, { fetchPolicy: "store-or-network" });
  }, [filterState, refetch, searchString]);

  return (
    <Box
      sx={{
        maxWidth: "1340px",
        margin: "16px auto",
      }}
    >
      <JobListCard
        data={data}
        loadNext={loadNext}
        isLoadingNext={isLoadingNext}
        hasNext={hasNext}
      />
      <JobListFilter
        data={data}
        filterState={filterState}
        setFilterState={setFilterState}
        filterDialogOpen={filterDialogOpen}
        onFilterDialogClose={onFilterDialogClose}
      />
    </Box>
  );
}

function JobListContentSkeleton() {
  return (
    <Box
      sx={{
        maxWidth: "1340px",
        margin: "16px auto",
      }}
    >
      <Card
        variant="outlined"
        sx={{
          maxWidth: "600px",
          margin: "0 auto",
          bgcolor: "background.paper",
        }}
      >
        <List>
          {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((key: number) => (
            <ListItem key={key}>
              <ListItemText primary={<Skeleton />} secondary={<Skeleton />} />
              <Skeleton sx={{ marginLeft: "16px" }}>
                <Chip
                  sx={{
                    width: "116px",
                  }}
                />
              </Skeleton>
            </ListItem>
          ))}
        </List>
      </Card>
    </Box>
  );
}

export default function JobListView({
  jobListQueryRef,
  toggleDrawer,
}: JobListProps) {
  const theme = useTheme();
  const [searchState, setSearchState] = useState<string | null>(null);

  const [filterDialogOpen, setFilterDialogOpen] = useState(false);
  const showFilterAsDialog = !useMediaQuery("(min-width:1100px)");

  return (
    <>
      <JobManagerAppBar
        toggleDrawer={toggleDrawer}
        onSearchInputChange={(str) => {
          setSearchState(str === "" ? null : str);
        }}
        onFilterButtonTap={
          showFilterAsDialog ? () => setFilterDialogOpen(true) : undefined
        }
      />
      <Suspense fallback={<JobListContentSkeleton />}>
        <ErrorBoundaryCard>
          <JobListContent
            jobListQueryRef={jobListQueryRef}
            searchString={searchState}
            filterDialogOpen={showFilterAsDialog ? filterDialogOpen : undefined}
            onFilterDialogClose={() => setFilterDialogOpen(false)}
          />
          <Box
            sx={{
              position: "fixed",
              bottom: "64px",
              right: "64px",
              [theme.breakpoints.down("sm")]: {
                bottom: "16px",
                right: "16px",
              },
            }}
          >
            <NewJobFAB />
          </Box>
        </ErrorBoundaryCard>
      </Suspense>
    </>
  );
}

export const jobListDefaultStates: JobState[] = [
  "DRAFT",
  "READY",
  "IN_PROGRESS",
];
