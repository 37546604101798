import { usePaginationFragment } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import React, { useEffect } from "react";
import { Box, Typography } from "@mui/material";
import SubproductDataListContent, {
  CheckboxState,
} from "./SubproductDataListContent";
import LoadMoreButton from "../../common/LoadMoreButton";
import { SubproductDataSelectorListQuery$data } from "../../job/JobDetail/__generated__/SubproductDataSelectorListQuery.graphql";
import { SPDataStates } from "../../job/JobDetail/SubproductDataSelectorList";
import { SubproductDataListPaginationQuery } from "./__generated__/SubproductDataListPaginationQuery.graphql";
import { SubproductDataListViewQuery$data } from "./__generated__/SubproductDataListViewQuery.graphql";
import { useTranslation } from "react-i18next";
import { SPDataState } from "./SubproductDataListFilter";

interface SubproductDataListProps {
  queryData:
    | SubproductDataListViewQuery$data
    | SubproductDataSelectorListQuery$data;
  searchString: string | null;
  spDataStates?: { [id: string]: SPDataStates };
  onCheckboxChange?: (id: string, isChecked: boolean) => void;
  openAsDialog?: boolean;
  hideJobs?: string[];
  filterState?: Set<SPDataState>;
}

export default function SubproductDataList({
  queryData,
  searchString,
  spDataStates,
  onCheckboxChange,
  openAsDialog,
  hideJobs,
  filterState,
}: SubproductDataListProps) {
  const { t } = useTranslation();

  const { data, refetch, loadNext, isLoadingNext, hasNext } =
    usePaginationFragment<SubproductDataListPaginationQuery, any>(
      graphql`
        fragment SubproductDataList_subproductData on Query
        @argumentDefinitions(
          cursor: { type: "String" }
          count: { type: "Int", defaultValue: 15 }
        )
        @refetchable(queryName: "SubproductDataListPaginationQuery") {
          allActiveSubproductData(
            first: $count
            after: $cursor
            subproductNameIcontains: $search
            subproductNumber: $searchNumber
            returnAssignedToJobs: $returnAssignedToJobs
            returnNotAssignedToJobs: $returnUnassignedToJobs
          ) @connection(key: "SubproductDataList_allActiveSubproductData") {
            edges {
              node {
                id
                ...SubproductDataListItem_subproductDataNode
              }
            }
          }
        }
      `,
      queryData
    );

  useEffect(() => {
    const searchVariables =
      searchString != null && searchString.match("^S|sP|p[0-9]+$")
        ? {
            searchNumber: "SP" + searchString.substring(2),
            search: null,
          }
        : {
            search: searchString,
          };
    const assignmentFilterVariables =
      filterState !== undefined
        ? {
            returnAssignedToJobs: filterState.has(SPDataState.ASSIGNED),
            returnUnassignedToJobs: filterState.has(SPDataState.UNASSIGNED),
          }
        : {};
    refetch(
      {
        ...searchVariables,
        ...assignmentFilterVariables,
      },
      { fetchPolicy: "store-or-network" }
    );
  }, [refetch, searchString, filterState]);

  let checkboxStates = null;

  if (spDataStates) {
    checkboxStates = (data.allActiveSubproductData?.edges || [])
      .filter(
        (edge: { node: null } | null) => edge !== null && edge.node !== null
      )
      .reduce((obj: { [id: string]: CheckboxState }, x: any) => {
        switch (spDataStates[x!.node!.id]) {
          case undefined:
            obj[x!.node!.id] = CheckboxState.UNCHECKED;
            break;
          case SPDataStates.ASSIGNING:
            obj[x!.node!.id] = CheckboxState.CHECKING;
            break;
          case SPDataStates.ASSIGNED:
            obj[x!.node!.id] = CheckboxState.CHECKED;
            break;
          case SPDataStates.UNASSIGNING:
            obj[x!.node!.id] = CheckboxState.UNCHECKING;
            break;
        }
        return obj;
      }, {});
  }

  const listContent =
    data.allActiveSubproductData.edges.length > 0 ? (
      <>
        <SubproductDataListContent
          data={data}
          states={checkboxStates}
          onCheckboxChange={onCheckboxChange}
          onReloadRequested={() =>
            refetch({}, { fetchPolicy: "store-and-network" })
          }
          openAsDialog={openAsDialog}
          hideJobs={hideJobs}
        />
        {hasNext ? (
          <LoadMoreButton loadNext={loadNext} isLoadingNext={isLoadingNext} />
        ) : null}
      </>
    ) : (
      <Typography
        sx={{ fontStyle: "italic", textAlign: "center", margin: "32px" }}
      >
        {t("No subproduct data found")}
      </Typography>
    );

  return (
    <Box
      sx={{
        maxWidth: "1340px",
        margin: "16px auto",
      }}
    >
      {listContent}
    </Box>
  );
}
