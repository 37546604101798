/**
 * @generated SignedSource<<79680399a95499958a3b5aef394ca0f8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TaskState = "PENDING" | "IN_PROGRESS" | "DONE" | "%future added value";
export type TaskType = "DTP_PREPARATION" | "PLATE_MAKING" | "PRINTING" | "VARNISHING" | "STANDARD_UV_VARNISHING" | "LAMINATING" | "SHAPE_CUTTING" | "CUTTING" | "FOLDING" | "GATHERING" | "HOLE_MAKING" | "COLLATING" | "SEWING" | "PRESSING" | "EMBOSSING" | "PERFORATING" | "CREASING" | "SCORING" | "RELIEF_UV_VARNISHING" | "COLLECTING" | "EDGE_GLUING" | "%future added value";
export type TaskListPaginationQuery$variables = {
  count?: number | null;
  cursor?: string | null;
  pk?: number | null;
  search?: string | null;
  states?: ReadonlyArray<TaskState> | null;
  types?: ReadonlyArray<TaskType> | null;
};
export type TaskListPaginationQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"TaskListViewComponent_tasks">;
};
export type TaskListPaginationQuery = {
  variables: TaskListPaginationQuery$variables;
  response: TaskListPaginationQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": 30,
    "kind": "LocalArgument",
    "name": "count"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cursor"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "pk"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "search"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "states"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "types"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "cursor"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count"
  },
  {
    "kind": "Variable",
    "name": "job_Id",
    "variableName": "pk"
  },
  {
    "kind": "Variable",
    "name": "job_Name_Icontains",
    "variableName": "search"
  },
  {
    "kind": "Variable",
    "name": "state",
    "variableName": "states"
  },
  {
    "kind": "Variable",
    "name": "taskType",
    "variableName": "types"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TaskListPaginationQuery",
    "selections": [
      {
        "args": [
          {
            "kind": "Variable",
            "name": "count",
            "variableName": "count"
          },
          {
            "kind": "Variable",
            "name": "cursor",
            "variableName": "cursor"
          }
        ],
        "kind": "FragmentSpread",
        "name": "TaskListViewComponent_tasks"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TaskListPaginationQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "TaskNodeConnection",
        "kind": "LinkedField",
        "name": "allTasks",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TaskNodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "TaskNode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "JobNode",
                    "kind": "LinkedField",
                    "name": "job",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "pk",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "jdfId",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      },
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "type",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "state",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "TaskStateCountType",
            "kind": "LinkedField",
            "name": "taskStates",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "stateKey",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "stateCount",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "stateTotalCount",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "TaskTypeCountType",
            "kind": "LinkedField",
            "name": "taskTypes",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "typeKey",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "typeCount",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "typeTotalCount",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v1/*: any*/),
        "filters": [
          "state",
          "taskType",
          "job_Name_Icontains",
          "job_Id"
        ],
        "handle": "connection",
        "key": "TaskList_allTasks",
        "kind": "LinkedHandle",
        "name": "allTasks"
      }
    ]
  },
  "params": {
    "cacheID": "e00a5490c2da7767d3cd05f6c61c4ff9",
    "id": null,
    "metadata": {},
    "name": "TaskListPaginationQuery",
    "operationKind": "query",
    "text": "query TaskListPaginationQuery(\n  $count: Int = 30\n  $cursor: String\n  $pk: Float\n  $search: String\n  $states: [TaskState!]\n  $types: [TaskType!]\n) {\n  ...TaskListViewComponent_tasks_1G22uz\n}\n\nfragment TaskListViewComponent_tasks_1G22uz on Query {\n  allTasks(first: $count, after: $cursor, state: $states, taskType: $types, job_Name_Icontains: $search, job_Id: $pk) {\n    edges {\n      node {\n        job {\n          pk\n          jdfId\n          name\n          id\n        }\n        id\n        type\n        state\n        __typename\n      }\n      cursor\n    }\n    taskStates {\n      stateKey\n      stateCount\n      stateTotalCount\n    }\n    taskTypes {\n      typeKey\n      typeCount\n      typeTotalCount\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "f5d8a5399131ab39fc4921468b43cebe";

export default node;
