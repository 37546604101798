import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { MouseEventHandler, ReactNode } from "react";
import intersperse from "../../utils/intersperse";

interface QueryErrorDialogProps {
  handleClose: MouseEventHandler<HTMLButtonElement>;
  open: boolean;
  primaryError: string | null;
  secondaryError: string | string[] | null;
  showSaveFailed?: boolean;
}

export default function QueryErrorDialog({
  handleClose,
  open,
  primaryError,
  secondaryError,
  showSaveFailed,
}: QueryErrorDialogProps) {
  const { t } = useTranslation();

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>
        {primaryError != null ? primaryError : t("Query error")}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {showSaveFailed ? t("Data weren't saved due to error") : null}
          <br />
          {secondaryError != null
            ? Array.isArray(secondaryError)
              ? intersperse<ReactNode>(secondaryError, <br />)
              : secondaryError
            : t("There was an unknown error in query")}
          <br />
          {showSaveFailed ? t("Note that refreshing will lose data") : null}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>OK</Button>
      </DialogActions>
    </Dialog>
  );
}
