/**
 * @generated SignedSource<<aacbe4ce469b5e51452c2414f34050ac>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
export type JobState = "DRAFT" | "READY" | "IN_PROGRESS" | "DONE" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type JobListViewComponent_jobs$data = {
  readonly allJobs: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly pk: number;
        readonly id: string;
        readonly name: string;
        readonly jdfId: string;
        readonly state: JobState;
      } | null;
    } | null>;
    readonly jobStates: ReadonlyArray<{
      readonly stateKey: JobState;
      readonly stateCount: number;
      readonly stateTotalCount: number;
    }>;
  } | null;
  readonly " $fragmentType": "JobListViewComponent_jobs";
};
export type JobListViewComponent_jobs$key = {
  readonly " $data"?: JobListViewComponent_jobs$data;
  readonly " $fragmentSpreads": FragmentRefs<"JobListViewComponent_jobs">;
};

const node: ReaderFragment = (function(){
var v0 = [
  "allJobs"
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": 30,
      "kind": "LocalArgument",
      "name": "count"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "cursor"
    },
    {
      "kind": "RootArgument",
      "name": "pk"
    },
    {
      "kind": "RootArgument",
      "name": "search"
    },
    {
      "kind": "RootArgument",
      "name": "states"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "count",
        "cursor": "cursor",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "count",
          "cursor": "cursor"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [],
      "operation": require('./JobListPaginationQuery.graphql')
    }
  },
  "name": "JobListViewComponent_jobs",
  "selections": [
    {
      "alias": "allJobs",
      "args": [
        {
          "kind": "Variable",
          "name": "id",
          "variableName": "pk"
        },
        {
          "kind": "Variable",
          "name": "name_Icontains",
          "variableName": "search"
        },
        {
          "kind": "Variable",
          "name": "state",
          "variableName": "states"
        }
      ],
      "concreteType": "JobNodeConnection",
      "kind": "LinkedField",
      "name": "__JobList_allJobs_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "JobNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "JobNode",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "pk",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "id",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "name",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "jdfId",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "state",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "JobStateCountType",
          "kind": "LinkedField",
          "name": "jobStates",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "stateKey",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "stateCount",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "stateTotalCount",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "4fec1c2b678ab77d5a851885da2c8f4f";

export default node;
