import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormHelperText,
  Input,
  InputLabel,
  Typography,
} from "@mui/material";
import React, { ReactNode, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import { LoadingButton } from "@mui/lab";
import { SubproductDataCloneDialogMutation } from "./__generated__/SubproductDataCloneDialogMutation.graphql";
import queryErrorToMessages from "../../utils/queryErrorToMessages";
import QueryErrorDialog from "../common/QueryErrorDialog";
import { getSubproductDataCloneMutationErrorLabels } from "../../__generatedEnumLabels";
import SubproductDataDetailDialog from "./SubproductDataDetail/SubproductDataDetailDialog";
import { useNavigate } from "react-suspense-router";

interface CloneSPDataDialogProps {
  onSuccess?: () => void;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  showCloneAsDialog?: boolean;
  spDataId: string;
  spNumber: string;
}

export default function CloneSPDataDialog({
  onSuccess,
  open,
  setOpen,
  showCloneAsDialog,
  spDataId,
  spNumber,
}: CloneSPDataDialogProps) {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const [versionFieldError, setVersionFieldError] = useState<ReactNode[]>([]);
  const [newVersionName, setNewVersionName] = useState<string>("");
  const [mutationErrors, setMutationErrors] = useState<{
    open: boolean;
    primaryErrorKey: string | null;
    secondaryErrorKey: string | null;
  }>({
    open: false,
    primaryErrorKey: null,
    secondaryErrorKey: null,
  });
  const [clonePK, setClonePK] = useState<number | null>(null);

  const [commit, isInFlight] =
    useMutation<SubproductDataCloneDialogMutation>(graphql`
      mutation SubproductDataCloneDialogMutation(
        $input: SubproductDataCloneMutationInput!
      ) {
        cloneSubproductData(input: $input) {
          subproductData {
            id
            pk
          }
          errors
        }
      }
    `);

  return (
    <>
      <QueryErrorDialog
        handleClose={() => {
          setMutationErrors({
            ...mutationErrors,
            open: false,
          });
        }}
        open={mutationErrors["open"]}
        primaryError={
          mutationErrors["primaryErrorKey"] != null
            ? t(mutationErrors["primaryErrorKey"])
            : null
        }
        secondaryError={
          mutationErrors["secondaryErrorKey"] != null
            ? t(mutationErrors["secondaryErrorKey"])
            : null
        }
        showSaveFailed={true}
      />
      <Dialog open={open}>
        <DialogTitle>
          {t("Clone subproduct", { spNumber: spNumber })}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t("Please enter name of the new Subproduct Data version")}
          </DialogContentText>
          <FormControl
            variant="standard"
            margin="dense"
            sx={{ width: "100%" }}
            error={versionFieldError.length > 0}
            disabled={isInFlight}
            required
          >
            <InputLabel htmlFor="new-version-name">{t("Version")}</InputLabel>
            <Input
              id="new-version-name"
              value={newVersionName}
              onChange={(event) => setNewVersionName(event.target.value)}
            />
            {/* TODO key prop in FormHelperText */}
            {versionFieldError.map((error) => (
              <FormHelperText error>{error}</FormHelperText>
            ))}
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Cancel</Button>
          <LoadingButton
            loading={isInFlight}
            onClick={() => {
              setVersionFieldError([]);
              if (newVersionName === "") {
                setVersionFieldError([
                  <Typography>{t("This field is required")}</Typography>,
                ]);
                return;
              }
              commit({
                variables: {
                  input: {
                    sourceId: spDataId,
                    newVersion: newVersionName,
                  },
                },
                onCompleted: ({ cloneSubproductData }) => {
                  if (cloneSubproductData?.errors != null) {
                    const errorLabels =
                      getSubproductDataCloneMutationErrorLabels(t);
                    setVersionFieldError(
                      cloneSubproductData.errors.map((error) => (
                        <Typography>{errorLabels.get(error)}</Typography>
                      ))
                    );
                    return;
                  }
                  setNewVersionName("");
                  setOpen(false);
                  if (cloneSubproductData?.subproductData?.pk === undefined) {
                    return;
                  }
                  if (onSuccess !== undefined) {
                    onSuccess();
                  }
                  if (showCloneAsDialog) {
                    setClonePK(cloneSubproductData.subproductData.pk);
                  } else {
                    navigate(
                      `/subproduct-data/${cloneSubproductData.subproductData.pk}`
                    );
                  }
                },
                onError: (error: Error) => {
                  const errorKeys = queryErrorToMessages(error);
                  console.log(error);
                  setMutationErrors({
                    open: true,
                    primaryErrorKey: errorKeys.error.primaryError,
                    secondaryErrorKey: errorKeys.error.secondaryError,
                  });
                },
              });
            }}
          >
            Clone
          </LoadingButton>
        </DialogActions>
      </Dialog>
      {showCloneAsDialog ? (
        <SubproductDataDetailDialog
          pk={clonePK != null ? clonePK : 0}
          open={clonePK != null}
          onClose={() => setClonePK(null)}
        />
      ) : null}
    </>
  );
}
