import { useTranslation } from "react-i18next";
import { Box, Card, Typography } from "@mui/material";
import { NavLink } from "react-router-dom";
import React from "react";

export default function NotFoundCard() {
  const { t } = useTranslation();
  return (
    <Card
      variant="outlined"
      sx={{
        maxWidth: "600px",
        margin: "32px auto",
        padding: "32px",
        bgcolor: "background.paper",
      }}
    >
      <Typography
        sx={{
          textAlign: "center",
          fontSize: "1.5rem",
        }}
      >
        {t("Not found")}
      </Typography>
      <Typography
        sx={{
          textAlign: "center",
          fontSize: "1.2rem",
          marginTop: "16px",
        }}
      >
        {t("We can't find anything at this address")}
      </Typography>
      <Box sx={{ textAlign: "center", marginTop: "32px" }}>
        <NavLink to="/">{t("Go back to jobs")}</NavLink>
      </Box>
    </Card>
  );
}
