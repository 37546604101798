/**
 * @generated SignedSource<<4eb05a021d99a91075e6e646075291db>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type TaskState = "PENDING" | "IN_PROGRESS" | "DONE" | "%future added value";
export type TaskType = "DTP_PREPARATION" | "PLATE_MAKING" | "PRINTING" | "VARNISHING" | "STANDARD_UV_VARNISHING" | "LAMINATING" | "SHAPE_CUTTING" | "CUTTING" | "FOLDING" | "GATHERING" | "HOLE_MAKING" | "COLLATING" | "SEWING" | "PRESSING" | "EMBOSSING" | "PERFORATING" | "CREASING" | "SCORING" | "RELIEF_UV_VARNISHING" | "COLLECTING" | "EDGE_GLUING" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type TasksCard_tasks$data = {
  readonly taskSet: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly type: TaskType | null;
        readonly state: TaskState | null;
        readonly enabled: boolean;
      } | null;
    } | null>;
  };
  readonly " $fragmentType": "TasksCard_tasks";
};
export type TasksCard_tasks$key = {
  readonly " $data"?: TasksCard_tasks$data;
  readonly " $fragmentSpreads": FragmentRefs<"TasksCard_tasks">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TasksCard_tasks",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "TaskNodeConnection",
      "kind": "LinkedField",
      "name": "taskSet",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "TaskNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "TaskNode",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "id",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "type",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "state",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "enabled",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "JobNode",
  "abstractKey": null
};

(node as any).hash = "b8ed8f7784765598a5f2b58cb5aa1d3f";

export default node;
