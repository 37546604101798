import { List } from "@mui/material";
import React from "react";
import { SubproductDataList_subproductData$data } from "./__generated__/SubproductDataList_subproductData.graphql";
import SubproductDataListItem from "./SubproductDataListItem";

export enum CheckboxState {
  UNCHECKED,
  CHECKING,
  CHECKED,
  UNCHECKING,
}

interface SubproductDataListProps {
  data: SubproductDataList_subproductData$data;
  states?: { [id: string]: CheckboxState };
  onCheckboxChange?: (id: string, isChecked: boolean) => void;
  onReloadRequested?: () => void;
  hideJobs?: string[];
  openAsDialog?: boolean;
}

export default function SubproductDataListContent({
  data,
  states,
  onCheckboxChange,
  onReloadRequested,
  hideJobs,
  openAsDialog,
}: SubproductDataListProps) {
  const listItems = (data.allActiveSubproductData?.edges || [])
    .filter((edge) => edge !== null && edge.node !== null)
    .map((edge) => {
      return (
        <SubproductDataListItem
          allowCloningSPData
          checkboxes={
            states != null &&
            onCheckboxChange !== undefined &&
            states[edge!.node!.id] !== undefined
              ? {
                  state: states[edge!.node!.id],
                  onCheckboxChange: onCheckboxChange,
                }
              : undefined
          }
          fragmentRef={edge!.node!}
          hideJobs={hideJobs}
          openAsDialog={openAsDialog}
          showAssignedJobs
          onCloneSuccess={onReloadRequested}
        />
      );
    });
  return <List>{listItems}</List>;
}
