import { FormHelperText } from "@mui/material";
import React, { ReactNode } from "react";

interface ErrorHelpersErrorsWarningsProps {
  formErrors: { [name: string]: ReactNode[] };
  formWarnings?: { [name: string]: ReactNode[] };
  name: string;
}

export default function FormHelpersErrorsWarnings({
  formErrors,
  formWarnings,
  name,
}: ErrorHelpersErrorsWarningsProps) {
  return (
    <>
      {!(name in formErrors)
        ? null
        : formErrors[name].map((errorItem) => (
            <FormHelperText>
              {/* TODO unique prop key */}
              {errorItem}
            </FormHelperText>
          ))}
      {!(formWarnings != null && name in formWarnings)
        ? null
        : formWarnings[name].map((errorItem) => (
            <FormHelperText sx={{ color: "#e89641" }}>
              {/* TODO unique prop key */}
              {errorItem}
            </FormHelperText>
          ))}
    </>
  );
}
