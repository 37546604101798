import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useFragment } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import {
  Checkbox,
  Chip,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from "@mui/material";
import SubproductDataDetailDialog from "../SubproductDataDetail/SubproductDataDetailDialog";
import AttachmentIcon from "@mui/icons-material/Attachment";
import CallSplitIcon from "@mui/icons-material/CallSplit";
import { SubproductDataListItem_subproductDataNode$key } from "./__generated__/SubproductDataListItem_subproductDataNode.graphql";
import { NavLink } from "react-router-dom";
import { CheckboxState } from "./SubproductDataListContent";
import intersperse from "../../../utils/intersperse";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CloneSPDataDialog from "../SubproductDataCloneDialog";
import SubproductDataListNotFoundItem from "./SubproductDataListNotFoundItem";

interface RelatedSubproductsListItemProps {
  allowCloningSPData?: boolean;
  checkboxes?: {
    state: CheckboxState;
    onCheckboxChange: (id: string, isChecked: boolean) => void;
  };
  fragmentRef: SubproductDataListItem_subproductDataNode$key;
  hideJobs?: string[];
  onCloneSuccess?: () => void;
  openAsDialog?: boolean;
  populateJobFromSPData?: {
    onPopulateClick: React.Dispatch<React.SetStateAction<string | null>>;
    disableButtons: boolean;
  };
  showAssignedJobs?: boolean;
  jobId?: string;
}

export default function SubproductDataListItem({
  allowCloningSPData,
  checkboxes,
  fragmentRef,
  hideJobs,
  onCloneSuccess,
  openAsDialog,
  populateJobFromSPData,
  showAssignedJobs,
  jobId,
}: RelatedSubproductsListItemProps) {
  const { t } = useTranslation();

  const data = useFragment<SubproductDataListItem_subproductDataNode$key>(
    graphql`
      fragment SubproductDataListItem_subproductDataNode on SubproductDataNode {
        id
        pk
        version
        dataUrl
        subproduct {
          number
          name
          product {
            dueDate
          }
        }
        jobSet {
          edges {
            node {
              id
              jdfId
            }
          }
        }
      }
    `,
    fragmentRef
  );

  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [cloneDialogOpen, setCloneDialogOpen] = useState(false);

  if (data.subproduct == null) {
    return jobId !== undefined ? (
      <SubproductDataListNotFoundItem spDataId={data.id} jobId={jobId} />
    ) : null;
  }

  const checkbox =
    checkboxes?.state != null ? (
      <ListItemIcon>
        <Checkbox
          sx={{
            margin: "auto",
          }}
          edge="start"
          checked={
            checkboxes.state === CheckboxState.CHECKING ||
            checkboxes.state === CheckboxState.CHECKED
          }
          disabled={
            checkboxes.state === CheckboxState.CHECKING ||
            checkboxes.state === CheckboxState.UNCHECKING
          }
          tabIndex={-1}
          onChange={(event) => {
            checkboxes.onCheckboxChange(data.id, event.target.checked);
          }}
        />
      </ListItemIcon>
    ) : null;

  const jobList =
    showAssignedJobs && data.jobSet.edges != null
      ? data.jobSet.edges.filter(
          (jobEdge) => !hideJobs?.some((id) => id === jobEdge?.node?.id)
        )
      : [];

  const dialog = openAsDialog ? (
    <SubproductDataDetailDialog
      pk={data.pk}
      open={editDialogOpen}
      onClose={() => setEditDialogOpen(false)}
    />
  ) : null;
  const listItemButtonProps = openAsDialog
    ? {
        onClick: () => setEditDialogOpen(true),
      }
    : {
        component: NavLink,
        to: "/subproduct-data/" + data.pk.toString(),
      };

  const cloneDialog =
    allowCloningSPData && data.subproduct.number != null ? (
      <CloneSPDataDialog
        onSuccess={onCloneSuccess}
        open={cloneDialogOpen}
        setOpen={setCloneDialogOpen}
        showCloneAsDialog={openAsDialog}
        spDataId={data.id}
        spNumber={data.subproduct.number}
      />
    ) : null;

  return (
    <ListItem key={data.id} disablePadding>
      {dialog}
      {checkbox}
      {cloneDialog}
      <ListItemButton {...listItemButtonProps}>
        <ListItemText
          primary={
            data.subproduct.number +
            (data.subproduct.product?.dueDate == null
              ? ""
              : " | " +
                t("intlDateTime", {
                  val: new Date(data.subproduct.product.dueDate),
                  formatParams: {
                    val: {
                      weekday: "long",
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                    },
                  },
                }))
          }
          secondary={data.subproduct.name}
        />
        {data.dataUrl === "" ? null : (
          <IconButton
            edge="end"
            sx={{ marginRight: "8px" }}
            onClick={(event) => {
              window.open(data.dataUrl, "_blank", "noopener noreferrer");
              event.preventDefault();
            }}
          >
            <AttachmentIcon />
          </IconButton>
        )}
        <Chip label={data.version} />
        {jobList.length > 1 ? (
          <Tooltip
            title={intersperse(
              jobList.map((jobEdge) => jobEdge?.node?.jdfId),
              ", "
            )}
            arrow
          >
            <Chip label={t("Multiple jobs")} />
          </Tooltip>
        ) : (
          jobList.map((jobEdge) => (
            <Chip key={jobEdge?.node?.id} label={jobEdge?.node?.jdfId} />
          ))
        )}
        {populateJobFromSPData !== undefined ? (
          <IconButton
            edge="end"
            disabled={populateJobFromSPData.disableButtons}
            onClick={(event) => {
              event.stopPropagation();
              populateJobFromSPData.onPopulateClick(data.id);
            }}
          >
            <CallSplitIcon />
          </IconButton>
        ) : null}
        {allowCloningSPData ? (
          <IconButton
            edge="end"
            onClick={(event) => {
              setCloneDialogOpen(true);
              event.stopPropagation();
              event.preventDefault();
            }}
          >
            <ContentCopyIcon />
          </IconButton>
        ) : null}
      </ListItemButton>
    </ListItem>
  );
}
