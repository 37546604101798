import { Box, Card, Typography } from "@mui/material";
import React from "react";
import ErrorBoundary from "./ErrorBoundary";

export default function ErrorBoundaryCard(props: React.PropsWithChildren<{}>) {
  return (
    <ErrorBoundary
      fallback={(primaryError, secondaryError) => (
        <Box
          sx={{
            maxWidth: "1340px",
            margin: "16px auto",
          }}
        >
          <Card
            variant="outlined"
            sx={{
              maxWidth: "600px",
              margin: "0 auto",
              padding: "32px",
              bgcolor: "background.paper",
            }}
          >
            <Typography
              sx={{
                textAlign: "center",
                fontSize: "1.5rem",
              }}
            >
              {primaryError}
            </Typography>
            <Typography
              sx={{
                textAlign: "center",
                fontSize: "1.2rem",
                marginTop: "16px",
              }}
            >
              {secondaryError}
            </Typography>
          </Card>
        </Box>
      )}
    >
      {props.children}
    </ErrorBoundary>
  );
}
