import {
  Box,
  Stack,
  StepConnector,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StatusChip from "./StatusChip";
import { StepIconProps } from "@mui/material/StepIcon";
import StepLabel from "@mui/material/StepLabel";
import { styled } from "@mui/material/styles";

const steps = ["DRAFT", "READY", "IN_PROGRESS", "DONE"];

interface Props {
  activeStep: "DRAFT" | "READY" | "IN_PROGRESS" | "DONE";
}

const StepIconRoot = styled("div")<{
  ownerState: { completed?: boolean; active?: boolean };
}>(() => ({
  zIndex: 1,
}));

function StepIcon(props: StepIconProps) {
  const { active, completed, className } = props;

  let step: "active" | "completed" | "todo" = "active";
  if (!active && !completed) {
    step = "todo";
  }
  if (completed) {
    step = "completed";
  }

  let icons: { [index: string]: React.ReactElement } = {};
  for (let x = 0; x < steps.length; x++) {
    icons[x + 1] = <StatusChip status={steps[x]} step={step} />;
  }

  return (
    <StepIconRoot ownerState={{ completed, active }} className={className}>
      {icons[String(props.icon)]}
    </StepIconRoot>
  );
}

export default function StatusStepper(props: Props) {
  const theme = useTheme();
  const mobileStepper = useMediaQuery(theme.breakpoints.down(780));

  if (mobileStepper) {
    return (
      <Box sx={{ margin: "auto", width: "fit-content" }}>
        <StatusChip status={props.activeStep} />
      </Box>
    );
  }

  return (
    <Stack sx={{ width: "30%" }} spacing={4}>
      <Stepper
        alternativeLabel
        activeStep={steps.indexOf(props.activeStep)}
        connector={<StepConnector sx={{ top: "18px" }} />}
      >
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel StepIconComponent={StepIcon}></StepLabel>
          </Step>
        ))}
      </Stepper>
    </Stack>
  );
}
