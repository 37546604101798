/**
 * @generated SignedSource<<a3c02e2963c8bf7818d49f8e398538e5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type JobDetailViewSlowQuery$variables = {
  pk: number;
};
export type JobDetailViewSlowQuery$data = {
  readonly job: {
    readonly " $fragmentSpreads": FragmentRefs<"JobPaperInfoItem_paper" | "RelatedSubproductsList_subproductData">;
  } | null;
};
export type JobDetailViewSlowQuery = {
  variables: JobDetailViewSlowQuery$variables;
  response: JobDetailViewSlowQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "pk"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "pk",
    "variableName": "pk"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "JobDetailViewSlowQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "JobNode",
        "kind": "LinkedField",
        "name": "job",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "JobPaperInfoItem_paper"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "RelatedSubproductsList_subproductData"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "JobDetailViewSlowQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "JobNode",
        "kind": "LinkedField",
        "name": "job",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PaperType",
            "kind": "LinkedField",
            "name": "paper",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "class",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "weight",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "SizeType",
                "kind": "LinkedField",
                "name": "size",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "width",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "height",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "SubproductDataNodeConnection",
            "kind": "LinkedField",
            "name": "subproductData",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "SubproductDataNodeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SubproductDataNode",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "pk",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "version",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "dataUrl",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "SubproductType",
                        "kind": "LinkedField",
                        "name": "subproduct",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "number",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "name",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ProductType",
                            "kind": "LinkedField",
                            "name": "product",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "dueDate",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "JobNodeConnection",
                        "kind": "LinkedField",
                        "name": "jobSet",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "JobNodeEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "JobNode",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                  (v2/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "jdfId",
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "52367e7f788aea7704280d8fa90e8dff",
    "id": null,
    "metadata": {},
    "name": "JobDetailViewSlowQuery",
    "operationKind": "query",
    "text": "query JobDetailViewSlowQuery(\n  $pk: Int!\n) {\n  job(pk: $pk) {\n    ...JobPaperInfoItem_paper\n    ...RelatedSubproductsList_subproductData\n    id\n  }\n}\n\nfragment JobPaperInfoItem_paper on JobNode {\n  paper {\n    id\n    class\n    weight\n    size {\n      width\n      height\n    }\n  }\n}\n\nfragment RelatedSubproductsList_subproductData on JobNode {\n  subproductData {\n    edges {\n      node {\n        ...SubproductDataListItem_subproductDataNode\n        id\n      }\n    }\n  }\n}\n\nfragment SubproductDataListItem_subproductDataNode on SubproductDataNode {\n  id\n  pk\n  version\n  dataUrl\n  subproduct {\n    number\n    name\n    product {\n      dueDate\n    }\n  }\n  jobSet {\n    edges {\n      node {\n        id\n        jdfId\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "8eb55fa8afa9c6bdc20ee15d5c5e5c47";

export default node;
