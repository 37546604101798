/**
 * @generated SignedSource<<523f36ca7b36a0fcf927565185c3de5f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type JobPaperInfoItem_paper$data = {
  readonly paper: {
    readonly id: any | null;
    readonly class: string | null;
    readonly weight: number | null;
    readonly size: {
      readonly width: number | null;
      readonly height: number | null;
    } | null;
  } | null;
  readonly " $fragmentType": "JobPaperInfoItem_paper";
};
export type JobPaperInfoItem_paper$key = {
  readonly " $data"?: JobPaperInfoItem_paper$data;
  readonly " $fragmentSpreads": FragmentRefs<"JobPaperInfoItem_paper">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "JobPaperInfoItem_paper",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "PaperType",
      "kind": "LinkedField",
      "name": "paper",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "class",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "weight",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "SizeType",
          "kind": "LinkedField",
          "name": "size",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "width",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "height",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "JobNode",
  "abstractKey": null
};

(node as any).hash = "cc3d1f015cc4447fd380cbb648537074";

export default node;
