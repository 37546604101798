import React, { Suspense, useEffect, useState } from "react";
import {
  AppBar,
  Dialog,
  IconButton,
  Slide,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import SubproductDataCard, {
  SubproductDataCardSkeleton,
} from "./SubproductDataCard";
import ErrorBoundaryCard from "../../common/ErrorBoundaryCard";
import { loadQuery, PreloadedQuery, useRelayEnvironment } from "react-relay";
import { SubproductDataCardQuery as SubproductDataCardQueryType } from "./__generated__/SubproductDataCardQuery.graphql";
import SubproductDataCardQuery from "./__generated__/SubproductDataCardQuery.graphql";
import { useTranslation } from "react-i18next";
import CloseIcon from "@mui/icons-material/Close";
import { TransitionProps } from "@mui/material/transitions";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface SubproductDataDetailDialogProps {
  pk: number;
  open: boolean;
  onClose: () => void;
}

export default function SubproductDataDetailDialog({
  pk,
  open,
  onClose,
}: SubproductDataDetailDialogProps) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const { t } = useTranslation();

  const [spDataQureyRef, setSpDataQueryRef] =
    useState<PreloadedQuery<SubproductDataCardQueryType> | null>(null);
  const environment = useRelayEnvironment();

  useEffect(() => {
    if (open && spDataQureyRef == null) {
      const queryRef = loadQuery<SubproductDataCardQueryType>(
        environment,
        SubproductDataCardQuery,
        { pk: pk }
      );
      setSpDataQueryRef(queryRef);
      return;
    }

    if (!open && spDataQureyRef != null) {
      spDataQureyRef?.dispose();
      setSpDataQueryRef(null);
    }
  }, [pk, open, environment, spDataQureyRef]);

  return (
    <Dialog
      onClose={onClose}
      open={open}
      maxWidth={false}
      onBackdropClick={(e) => e.stopPropagation()}
      fullScreen={fullScreen}
      scroll={fullScreen ? "body" : undefined}
      TransitionComponent={fullScreen ? Transition : undefined}
    >
      {fullScreen ? (
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={onClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {t("Subproduct data")}
            </Typography>
          </Toolbar>
        </AppBar>
      ) : null}
      <Suspense fallback={<SubproductDataCardSkeleton />}>
        <ErrorBoundaryCard>
          {spDataQureyRef != null ? (
            <SubproductDataCard
              subproductDataQueryRef={spDataQureyRef}
              onCancel={onClose}
              hideSaveAndCreateJob
            />
          ) : null}
        </ErrorBoundaryCard>
      </Suspense>
    </Dialog>
  );
}
