/**
 * @generated SignedSource<<1ec40ea3245ab5ae77af706c293d3aef>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type JobManagerDrawerUserQuery$variables = {};
export type JobManagerDrawerUserQuery$data = {
  readonly me: {
    readonly name: string;
  };
};
export type JobManagerDrawerUserQuery = {
  variables: JobManagerDrawerUserQuery$variables;
  response: JobManagerDrawerUserQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Me",
    "kind": "LinkedField",
    "name": "me",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "JobManagerDrawerUserQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "JobManagerDrawerUserQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "285f736ed9c3149e3c32042ffa4cb032",
    "id": null,
    "metadata": {},
    "name": "JobManagerDrawerUserQuery",
    "operationKind": "query",
    "text": "query JobManagerDrawerUserQuery {\n  me {\n    name\n  }\n}\n"
  }
};
})();

(node as any).hash = "df0339806d0d13600b718a065d0f4bd6";

export default node;
