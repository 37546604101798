import { useTranslation } from "react-i18next";
import React, { useState } from "react";
import LanguageMenu from "./LanguageMenu";
import {
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";
import TranslateIcon from "@mui/icons-material/Translate";
import { ToolbarIconProps } from "./JobManagerAppBar";

export default function ToolbarLanguageIcon({
  asMenuItem,
  onClick,
}: ToolbarIconProps) {
  const { t } = useTranslation();

  const [langMenuAnchorEl, setLangMenuAnchorEl] = useState<null | HTMLElement>(
    null
  );
  const [langMenuOpen, setLangMenuOpen] = useState(false);
  const handleLangMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setLangMenuAnchorEl(event.currentTarget);
    setLangMenuOpen(true);
  };
  const handleLangMenuClose = () => {
    setLangMenuAnchorEl(null);
    setLangMenuOpen(false);
  };

  const languageMenu = (
    <LanguageMenu
      open={langMenuOpen}
      onClose={handleLangMenuClose}
      anchorElement={langMenuAnchorEl}
      showAsDialog={asMenuItem}
    />
  );

  if (asMenuItem) {
    return {
      languageMenuTrigger: (
        <>
          <MenuItem
            onClick={() => {
              setLangMenuOpen(true);
              if (onClick !== undefined) {
                onClick();
              }
            }}
          >
            <ListItemIcon>
              <TranslateIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>{t("Language")}</ListItemText>
          </MenuItem>
        </>
      ),
      languageMenu: languageMenu,
    };
  }

  return {
    languageMenuTrigger: (
      <>
        <Tooltip title={<Typography>{t("Select language")}</Typography>}>
          <IconButton color="inherit" onClick={handleLangMenuClick}>
            <TranslateIcon />
          </IconButton>
        </Tooltip>
      </>
    ),
    languageMenu: languageMenu,
  };
}
