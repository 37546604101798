/**
 * @generated SignedSource<<2e6a15a7cb7c86fa073a1b0a9342d45b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type UpdateSubproductDataErrorDetail = "SP_DATA_LOCKED" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ErrorString_updateSubproductDataMutationError$data = {
  readonly updateSPDataErrorType: UpdateSubproductDataErrorDetail;
  readonly " $fragmentType": "ErrorString_updateSubproductDataMutationError";
};
export type ErrorString_updateSubproductDataMutationError$key = {
  readonly " $data"?: ErrorString_updateSubproductDataMutationError$data;
  readonly " $fragmentSpreads": FragmentRefs<"ErrorString_updateSubproductDataMutationError">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ErrorString_updateSubproductDataMutationError",
  "selections": [
    {
      "alias": "updateSPDataErrorType",
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    }
  ],
  "type": "UpdateSubproductDataMutationErrorType",
  "abstractKey": null
};

(node as any).hash = "f5417f40b51cdafbfb94db5008e2202a";

export default node;
