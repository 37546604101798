import { useTranslation } from "react-i18next";
import { useLazyLoadQuery } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import React, { useState } from "react";
import AccountMenu from "./AccountMenu";
import {
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";
import { AccountCircle } from "@mui/icons-material";
import { ToolbarIconProps } from "./JobManagerAppBar";
import { ToolbarAccountIconQuery } from "./__generated__/ToolbarAccountIconQuery.graphql";

export default function ToolbarAccountIcon({
  asMenuItem,
  onClick,
}: ToolbarIconProps) {
  const { t } = useTranslation();

  const user = useLazyLoadQuery<ToolbarAccountIconQuery>(
    graphql`
      query ToolbarAccountIconQuery {
        me {
          name
          email
          logoutUrl
        }
      }
    `,
    {},
    { fetchPolicy: "store-or-network" }
  );

  const [accountMenuAnchorEl, setAccountMenuAnchorEl] =
    useState<null | HTMLElement>(null);
  const [accountMenuOpen, setAccountMenuOpen] = useState(false);
  const handleAccountMenuClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setAccountMenuAnchorEl(event.currentTarget);
    setAccountMenuOpen(true);
  };
  const handleAccountMenuClose = () => {
    setAccountMenuAnchorEl(null);
    setAccountMenuOpen(false);
  };

  const accountMenu = (
    <AccountMenu
      open={accountMenuOpen}
      onClose={handleAccountMenuClose}
      anchorEl={accountMenuAnchorEl}
      user={user}
      showAsDialog={asMenuItem}
    />
  );

  if (asMenuItem) {
    return {
      accountMenuTrigger: (
        <>
          <MenuItem
            onClick={() => {
              setAccountMenuOpen(true);
              if (onClick !== undefined) {
                onClick();
              }
            }}
          >
            <ListItemIcon>
              <AccountCircle fontSize="small" />
            </ListItemIcon>
            <ListItemText>{t("User")}</ListItemText>
          </MenuItem>
        </>
      ),
      accountMenu: accountMenu,
    };
  }

  return {
    accountMenuTrigger: (
      <>
        <Tooltip title={<Typography>{t("User")}</Typography>}>
          <IconButton color="inherit" onClick={handleAccountMenuClick}>
            <AccountCircle />
          </IconButton>
        </Tooltip>
      </>
    ),
    accountMenu: accountMenu,
  };
}
