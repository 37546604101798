export function sizeToString(
  size: { width: number | null; height: number | null } | null | undefined
) {
  if (size?.width == null || size?.height == null) return null;
  return `${size.width} mm x ${size.height} mm`;
}

export function requestedPaperToString(
  requestedPaper:
    | { readonly class: string | null; readonly weight: number | null }
    | null
    | undefined
) {
  if (requestedPaper?.class == null || requestedPaper?.weight == null)
    return null;
  return `${requestedPaper?.class}, ${requestedPaper?.weight} g`;
}
