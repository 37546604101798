import React, { useEffect, useMemo, useState } from "react";
import { Suspense } from "react";

import {
  AppBar,
  Box,
  debounce,
  IconButton,
  Menu,
  MenuList,
  Toolbar,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import { useTranslation } from "react-i18next";
import ErrorBoundary from "../../common/ErrorBoundary";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ToolbarLanguageIcon from "./ToolbarLanguageIcon";
import ToolbarAccountIcon from "./ToolbarAccountIcon";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ToolbarSearchField from "./ToolbarSearchField";
import FilterListIcon from "@mui/icons-material/FilterList";

export interface ToolbarIconProps {
  asMenuItem?: boolean;
  onClick?: () => void;
}

function ToolbarMoreMenu() {
  const { t } = useTranslation();

  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const menuOpen = Boolean(menuAnchorEl);
  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  const { languageMenuTrigger, languageMenu } = ToolbarLanguageIcon({
    asMenuItem: true,
    onClick: handleMenuClose,
  });
  const { accountMenuTrigger, accountMenu } = ToolbarAccountIcon({
    asMenuItem: true,
    onClick: handleMenuClose,
  });

  return (
    <>
      <Tooltip title={<Typography>{t("User")}</Typography>}>
        <IconButton color="inherit" onClick={handleMenuClick}>
          <MoreVertIcon />
        </IconButton>
      </Tooltip>
      <Menu open={menuOpen} onClose={handleMenuClose} anchorEl={menuAnchorEl}>
        <MenuList>
          {accountMenuTrigger}
          {languageMenuTrigger}
        </MenuList>
      </Menu>
      {languageMenu}
      {accountMenu}
    </>
  );
}

interface Props {
  toggleDrawer: (
    open: boolean
  ) => (event: React.KeyboardEvent | React.MouseEvent) => void;
  onSearchInputChange?: (str: string) => void;
  onFilterButtonTap?: () => void;
}

function ToolbarContents({
  toggleDrawer,
  onSearchInputChange,
  onFilterButtonTap,
}: Props) {
  const theme = useTheme();
  const mobileToolbar = useMediaQuery(theme.breakpoints.down(690));

  const [showMobileSearch, setShowMobileSearch] = useState(false);

  const { languageMenuTrigger, languageMenu } = ToolbarLanguageIcon({});
  const { accountMenuTrigger, accountMenu } = ToolbarAccountIcon({});

  const [searchState, setSearchState] = useState("");

  const debouncedOnSearchInputEventChange = useMemo(
    () =>
      debounce(
        onSearchInputChange != null ? onSearchInputChange : () => {},
        300
      ),
    [onSearchInputChange]
  );
  useEffect(() => {
    return () => {
      debouncedOnSearchInputEventChange.clear();
    };
  }, [debouncedOnSearchInputEventChange]);

  const onSearchStringChange = (newString: string) => {
    setSearchState(newString);
    debouncedOnSearchInputEventChange(newString);
  };

  const filterButton =
    onFilterButtonTap === undefined ? null : (
      <IconButton color="inherit" onClick={onFilterButtonTap}>
        <FilterListIcon />
      </IconButton>
    );

  if (showMobileSearch) {
    return (
      <Toolbar
        disableGutters
        sx={{
          padding: "8px 12px",
        }}
      >
        <IconButton color="inherit" onClick={() => setShowMobileSearch(false)}>
          <ArrowBackIcon />
        </IconButton>
        {onSearchInputChange != null ? (
          <ToolbarSearchField
            searchState={searchState}
            onSearchStringChange={onSearchStringChange}
            mobileVersion
          />
        ) : null}
      </Toolbar>
    );
  }

  return (
    <Toolbar disableGutters sx={{ padding: "8px 12px" }}>
      <IconButton color="inherit" onClick={toggleDrawer(true)}>
        <MenuIcon />
      </IconButton>
      <Typography
        variant="h6"
        sx={{ paddingLeft: "14px", marginRight: "32px" }}
      >
        Job Manager
      </Typography>
      {mobileToolbar ? (
        <>
          <Box sx={{ flexGrow: 1 }} />
          {onSearchInputChange != null ? (
            <IconButton
              color="inherit"
              onClick={() => setShowMobileSearch(true)}
            >
              <SearchIcon />
            </IconButton>
          ) : null}
          {filterButton}
          <ToolbarMoreMenu />
        </>
      ) : (
        <>
          {onSearchInputChange != null ? (
            <ToolbarSearchField
              searchState={searchState}
              onSearchStringChange={onSearchStringChange}
            />
          ) : null}
          <Box sx={{ flexGrow: 1 }} />
          {filterButton}
          {languageMenuTrigger}
          {languageMenu}
          {accountMenuTrigger}
          {accountMenu}
        </>
      )}
    </Toolbar>
  );
}

export default function JobManagerAppBar(props: Props) {
  return (
    <ErrorBoundary>
      {/* No fallback. We want to show AppBar only to logged-in users, and if the user query fails, we can't be
          certain that the user is logged in. Therefore, we will show nothing. */}
      <AppBar position="sticky">
        <Suspense fallback={<Toolbar />}>
          <ToolbarContents {...props} />
        </Suspense>
      </AppBar>
    </ErrorBoundary>
  );
}
