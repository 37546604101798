import { useTranslation } from "react-i18next";
import { Alert, Box, Snackbar } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import React, { useState } from "react";
import { LoadMoreFn } from "react-relay";
import queryErrorToMessages from "../../utils/queryErrorToMessages";

interface LoadMoreButtonProps {
  loadNext: LoadMoreFn<any>;
  isLoadingNext: boolean;
}

export default function LoadMoreButton({
  loadNext,
  isLoadingNext,
}: LoadMoreButtonProps) {
  const { t } = useTranslation();

  const [errorSnackbar, setErrorSnackbar] = useState<{
    open: boolean;
    errorKeys: readonly string[];
  }>({ open: false, errorKeys: [] });

  return (
    <Box sx={{ width: "100%" }}>
      <Snackbar
        open={errorSnackbar.open}
        autoHideDuration={5000}
        onClose={() =>
          setErrorSnackbar({
            ...errorSnackbar,
            open: false,
          })
        }
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={() =>
            setErrorSnackbar({
              ...errorSnackbar,
              open: false,
            })
          }
          severity="error"
          sx={{ width: "100%" }}
        >
          <p>{t("Error when loading more entries")}</p>
          {errorSnackbar.errorKeys.map((key) => (
            <p key={key}>{t(key)}</p>
          ))}
        </Alert>
      </Snackbar>
      <LoadingButton
        onClick={() =>
          loadNext(10, {
            onComplete: (error) => {
              if (error == null) {
                return;
              }
              const errorKeys = queryErrorToMessages(error);
              console.log(error);
              setErrorSnackbar({
                open: true,
                errorKeys: [
                  errorKeys.error.primaryError,
                  errorKeys.error.secondaryError,
                ],
              });
            },
          })
        }
        loading={isLoadingNext}
        sx={{
          margin: "auto",
          display: "block",
        }}
      >
        {t("Load more")}
      </LoadingButton>
    </Box>
  );
}
