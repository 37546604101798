import { Box, useTheme } from "@mui/material";
import React, { Suspense } from "react";
import ErrorBoundaryCard from "../../common/ErrorBoundaryCard";
import JobManagerAppBar from "../../navigation/JobManagerAppBar/JobManagerAppBar";
import SubproductDataCard, {
  SubproductDataCardSkeleton,
} from "./SubproductDataCard";
import { PreloadedQuery } from "react-relay";
import { SubproductDataCardQuery } from "./__generated__/SubproductDataCardQuery.graphql";
import { useNavigate } from "react-suspense-router";

interface SubproductDataDetailViewProps {
  subproductDataQueryRef: PreloadedQuery<SubproductDataCardQuery>;
  toggleDrawer: (
    open: boolean
  ) => (event: React.KeyboardEvent | React.MouseEvent) => void;
}

export default function SubproductDataDetailView({
  subproductDataQueryRef,
  toggleDrawer,
}: SubproductDataDetailViewProps) {
  const navigate = useNavigate();
  const theme = useTheme();

  return (
    <>
      <JobManagerAppBar toggleDrawer={toggleDrawer} />
      <Box
        sx={{
          margin: "16px",
          [theme.breakpoints.down(680)]: {
            margin: 0,
            marginTop: "16px",
          },
        }}
      >
        <Suspense fallback={<SubproductDataCardSkeleton />}>
          <ErrorBoundaryCard>
            <SubproductDataCard
              subproductDataQueryRef={subproductDataQueryRef}
              onCancel={() => navigate("/subproduct-data")}
            />
          </ErrorBoundaryCard>
        </Suspense>
      </Box>
    </>
  );
}
