import { alpha, Box, IconButton, InputBase, styled } from "@mui/material";
import { useTranslation } from "react-i18next";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import React from "react";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up(1100)]: {
      width: "600px",
    },
  },
}));

const MobileStyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  backgroundColor: theme.palette.primary.main,
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `1em`,
    transition: theme.transitions.create("width"),
    width: "100%",
  },
}));

interface SearchFieldProps {
  searchState: string;
  onSearchStringChange: (newString: string) => void;
  mobileVersion?: boolean;
}

export default function ToolbarSearchField({
  searchState,
  onSearchStringChange,
  mobileVersion,
}: SearchFieldProps) {
  const { t } = useTranslation();

  const ToolbarSearchInput = mobileVersion
    ? MobileStyledInputBase
    : StyledInputBase;

  return (
    <Search>
      {mobileVersion ? null : (
        <SearchIconWrapper>
          <SearchIcon />
        </SearchIconWrapper>
      )}
      <ToolbarSearchInput
        fullWidth
        placeholder={t("Search")}
        value={searchState}
        onChange={(event) => onSearchStringChange(event.target.value)}
        endAdornment={
          searchState !== "" ? (
            <IconButton
              size="small"
              onClick={() => onSearchStringChange("")}
              sx={{ color: "white" }}
            >
              <ClearIcon />
            </IconButton>
          ) : (
            <Box sx={{ width: "34px", height: "34px" }} />
          )
        }
      />
    </Search>
  );
}
