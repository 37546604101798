import React, { ReactNode } from "react";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";

interface ListFilterCheckboxProps {
  label: String;
  shown?: Number;
  total?: Number;
  checked: boolean;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

function ListFilterCheckbox({
  label,
  shown,
  total,
  checked,
  handleChange,
}: ListFilterCheckboxProps) {
  const { t } = useTranslation();
  return (
    <FormControlLabel
      control={<Checkbox checked={checked} onChange={handleChange} />}
      label={
        shown !== undefined && total !== undefined
          ? label + " (" + t("NofN", { first: shown, second: total }) + ")"
          : label
      }
    />
  );
}

interface ListViewFilterContainerProps {
  children: ReactNode;
  dialogOpen?: boolean;
  onDialogClose?: () => void;
}

export function ListViewFilterContainer({
  children,
  dialogOpen,
  onDialogClose,
}: ListViewFilterContainerProps) {
  const { t } = useTranslation();

  if (dialogOpen === undefined) {
    return (
      <Box
        sx={{
          alignItems: "flex-start",
          display: "flex",
          flexDirection: "column",
          position: "fixed",
          right: "calc(50% - 624px)",
          top: "80px",
          width: "300px",
          height: "calc(100% - (64px + 2*16px))",
          overflow: "auto",
        }}
      >
        {children}
      </Box>
    );
  }

  if (dialogOpen) {
    return (
      <Dialog open={true} onClose={onDialogClose}>
        <DialogTitle>{t("Filter items")}</DialogTitle>
        <DialogContent>{children}</DialogContent>
        <DialogActions>
          <Button onClick={onDialogClose}>{t("Close")}</Button>
        </DialogActions>
      </Dialog>
    );
  }

  return null;
}

interface ListViewFilterProps<FilterKey> {
  title: string;
  keys: FilterKey[];
  labels: Map<FilterKey, string>;
  amounts?: Map<FilterKey, { total: number; shown: number }>;
  state: Set<FilterKey>;
  setState: React.Dispatch<React.SetStateAction<Set<FilterKey>>>;
}

export default function ListViewFilter<FilterKey>({
  title,
  keys,
  labels,
  amounts,
  state,
  setState,
}: ListViewFilterProps<FilterKey>) {
  return (
    <>
      <Typography
        variant="h2"
        sx={{
          fontSize: ".875rem",
          lineHeight: "1.375rem",
          fontWeight: "500",
        }}
      >
        {title}
      </Typography>
      <FormGroup
        sx={{
          display: "flex",
          flexDirection: "column",
          margin: "8px 0 16px 9px",
        }}
      >
        {keys.map((key: FilterKey) => {
          if (labels.get(key) === undefined) {
            return null;
          }
          return (
            <ListFilterCheckbox
              label={labels.get(key)!}
              shown={amounts?.get(key)?.shown}
              total={amounts?.get(key)?.total}
              checked={state.has(key)}
              handleChange={(event) => {
                const newState = new Set(state);
                if (event.target.checked) {
                  newState.add(key);
                } else {
                  newState.delete(key);
                }
                setState(newState);
              }}
            />
          );
        })}
      </FormGroup>
    </>
  );
}
