import { useTranslation } from "react-i18next";
import React, {
  ChangeEvent,
  Suspense,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  Card,
  CardContent,
  debounce,
  FormControl,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  useTheme,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import SubproductDataSelectorList from "./SubproductDataSelectorList";
import { SubproductDataListContentSkeleton } from "../../subproduct-data/SubproductDataList/SubproductDataListView";

interface SubproductDataCardProps {
  jobId: string;
  assignedSubproductDataIDs: string[];
}

export default function SubproductDataSelectorCard({
  jobId,
  assignedSubproductDataIDs,
}: SubproductDataCardProps) {
  const theme = useTheme();
  const { t } = useTranslation();
  const [searchString, setSearchString] = useState<string | null>(null);
  const [inputSearchString, setInputSearchString] = useState<string>("");

  const debouncedSetSearchString = useMemo(
    () => debounce(setSearchString, 300),
    [setSearchString]
  );
  useEffect(() => {
    return () => {
      debouncedSetSearchString.clear();
    };
  }, [debouncedSetSearchString]);

  return (
    <Card
      variant="outlined"
      sx={{
        maxHeight: "calc(100vh - 128px)",
        overflow: "auto",
        [theme.breakpoints.down(680)]: {
          maxHeight: "fit-content",
        },
      }}
    >
      <CardContent>
        <FormControl
          variant="standard"
          sx={{ width: "100%", marginBottom: "8px" }}
        >
          <InputLabel htmlFor="subproduct-search">
            {t("Search in subproduct data")}
          </InputLabel>
          <Input
            id="subproduct-search"
            autoComplete="off"
            value={inputSearchString}
            onChange={(
              event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
            ) => {
              const str = event.target.value;
              debouncedSetSearchString(str === "" ? null : str);
              setInputSearchString(str);
            }}
            endAdornment={
              <IconButton
                size="small"
                onClick={() => {
                  setSearchString(null);
                  setInputSearchString("");
                }}
              >
                <ClearIcon />
              </IconButton>
            }
            startAdornment={
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            }
          />
        </FormControl>
        <Suspense fallback={<SubproductDataListContentSkeleton />}>
          <SubproductDataSelectorList
            searchString={searchString}
            jobId={jobId}
            assignedSubproductIDs={assignedSubproductDataIDs}
          />
        </Suspense>
      </CardContent>
    </Card>
  );
}
