/**
 * @generated SignedSource<<5eba74020322d14196e5e9153807af9e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type JobState = "DRAFT" | "READY" | "IN_PROGRESS" | "DONE" | "%future added value";
export type TaskMutationError = "INVALID_TRANSITION" | "STATE_CHANGE_NOT_ALLOWED" | "ENABLE_DISABLE_NOT_ALLOWED" | "XMF_PUSH_SP_NOT_FOUND" | "XMF_PUSH_SP_MISSING_PAGE_COUNT" | "XMF_PUSH_SP_INVALID_DATA" | "XMF_PUSH_FAILED" | "XMF_PUSH_REJECTED" | "XMF_PUSH_PPS_QUERY_FAILED" | "CALENDAR_UPDATE_FAILED" | "%future added value";
export type TaskState = "PENDING" | "IN_PROGRESS" | "DONE" | "%future added value";
export type TaskMutationFieldInput = {
  id?: string | null;
  state?: TaskState | null;
  enabled?: boolean | null;
  clientMutationId?: string | null;
};
export type TaskListItemStateMutation$variables = {
  input: TaskMutationFieldInput;
};
export type TaskListItemStateMutation$data = {
  readonly updateTask: {
    readonly task: {
      readonly id: string;
      readonly state: TaskState | null;
      readonly job: {
        readonly id: string;
        readonly state: JobState;
      };
    } | null;
    readonly errors: ReadonlyArray<TaskMutationError> | null;
  } | null;
};
export type TaskListItemStateMutation = {
  variables: TaskListItemStateMutation$variables;
  response: TaskListItemStateMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "state",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "TaskMutationFieldPayload",
    "kind": "LinkedField",
    "name": "updateTask",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "TaskNode",
        "kind": "LinkedField",
        "name": "task",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "JobNode",
            "kind": "LinkedField",
            "name": "job",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "errors",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TaskListItemStateMutation",
    "selections": (v3/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TaskListItemStateMutation",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "759b39ac0b471d0693ff321b62490b24",
    "id": null,
    "metadata": {},
    "name": "TaskListItemStateMutation",
    "operationKind": "mutation",
    "text": "mutation TaskListItemStateMutation(\n  $input: TaskMutationFieldInput!\n) {\n  updateTask(input: $input) {\n    task {\n      id\n      state\n      job {\n        id\n        state\n      }\n    }\n    errors\n  }\n}\n"
  }
};
})();

(node as any).hash = "54ab1e385b958943ada986c43017beb5";

export default node;
