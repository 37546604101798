import { Alert, CircularProgress, Fab, Snackbar } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import graphql from "babel-plugin-relay/macro";
import { useMutation } from "react-relay";
import { NewJobFABMutation } from "./__generated__/NewJobFABMutation.graphql";
import { useNavigate } from "react-suspense-router";
import { getCreateOrOverwriteJobMutationErrorLabels } from "../../__generatedEnumLabels";
import React, { useState } from "react";
import { CreateOrOverwriteJobMutationError } from "./JobDetail/__generated__/RelatedSubproductsListPopulateDataMutation.graphql";
import { useTranslation } from "react-i18next";
import queryErrorToMessages from "../../utils/queryErrorToMessages";

export default function NewJobFAB() {
  const { t } = useTranslation();
  const [commit, isInFlight] = useMutation<NewJobFABMutation>(
    graphql`
      mutation NewJobFABMutation {
        createOrOverwriteJob(input: {}) {
          job {
            pk
          }
          errors
        }
      }
    `
  );

  const [errorSnackbar, setErrorSnackbar] = useState<{
    open: boolean;
    errorKeys: readonly string[];
    mutationErrors: readonly CreateOrOverwriteJobMutationError[];
  }>({ open: false, errorKeys: [], mutationErrors: [] });

  const navigate = useNavigate();

  return (
    <>
      <Snackbar
        open={errorSnackbar.open}
        autoHideDuration={5000}
        onClose={() =>
          setErrorSnackbar({
            ...errorSnackbar,
            open: false,
          })
        }
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={() =>
            setErrorSnackbar({
              ...errorSnackbar,
              open: false,
            })
          }
          severity="error"
          sx={{ width: "100%" }}
        >
          <p>{t("Error creating new job")}</p>
          {errorSnackbar.errorKeys.map((key) => (
            <p key={key}>{t(key)}</p>
          ))}
          {errorSnackbar.mutationErrors.map((key) => (
            <p key={key}>
              {getCreateOrOverwriteJobMutationErrorLabels(t).get(key)}
            </p>
          ))}
        </Alert>
      </Snackbar>
      <Fab
        color="primary"
        aria-label="add"
        onClick={(event) => {
          commit({
            variables: {},
            onError: (error) => {
              const errorKeys = queryErrorToMessages(error);
              console.log(error);
              setErrorSnackbar({
                open: true,
                errorKeys: [
                  errorKeys.error.primaryError,
                  errorKeys.error.secondaryError,
                ],
                mutationErrors: [],
              });
            },
            onCompleted: (response) => {
              if ((response.createOrOverwriteJob?.errors || []).length > 0) {
                setErrorSnackbar({
                  open: true,
                  mutationErrors: response.createOrOverwriteJob!.errors!,
                  errorKeys: [],
                });
              } else {
                if (response.createOrOverwriteJob?.job?.pk != null) {
                  navigate(`/jobs/${response.createOrOverwriteJob!.job!.pk}`);
                }
              }
            },
          });
        }}
      >
        {isInFlight ? <CircularProgress color="secondary" /> : <AddIcon />}
      </Fab>
    </>
  );
}
