/**
 * @generated SignedSource<<51be0bcbe5bf68de565cc958b92bf638>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type InvalidChoiceErrorDetail = "CHOICE" | "EXTENSION" | "%future added value";
export type InvalidErrorDetail = "GENERIC" | "NULL_CHARACTER" | "NULL" | "BLANK" | "DATE" | "DATETIME" | "TIME" | "EMPTY" | "IMAGE" | "AMBIGUOUS_TIMEZONE" | "INCOMPLETE" | "LIST" | "PK_VALUE" | "REQUIRED" | "CONTRADICTION" | "%future added value";
export type InvalidValueErrorDetail = "LIMIT" | "MAX" | "MAX_LENGTH" | "MIN" | "MIN_LENGTH" | "MAX_DIGITS" | "MAX_DECIMAL_PLACES" | "MAX_WHOLE_DIGITS" | "%future added value";
export type UniqueValueErrorDetail = "UNIQUE" | "UNIQUE_FOR_DATE" | "UNIQUE_TOGETHER" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ErrorString_djangoError$data = {
  readonly message: string;
  readonly subtype: {
    readonly __typename: "InvalidErrorType";
    readonly invalidErrorType: InvalidErrorDetail;
  } | {
    readonly __typename: "InvalidChoiceErrorType";
    readonly invalidChoiceType: InvalidChoiceErrorDetail;
    readonly allowedValues: ReadonlyArray<string | null> | null;
  } | {
    readonly __typename: "LimitValueErrorType";
    readonly limitErrorType: InvalidValueErrorDetail;
    readonly limitValue: string | null;
  } | {
    readonly __typename: "OverflowValueErrorType";
    readonly maxValue: string | null;
    readonly minValue: string | null;
  } | {
    readonly __typename: "UniqueValueErrorType";
    readonly uniqeValueErrorType: UniqueValueErrorDetail;
    readonly modelName: string | null;
    readonly fieldLabels: ReadonlyArray<string | null> | null;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  };
  readonly " $fragmentType": "ErrorString_djangoError";
};
export type ErrorString_djangoError$key = {
  readonly " $data"?: ErrorString_djangoError$data;
  readonly " $fragmentSpreads": FragmentRefs<"ErrorString_djangoError">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ErrorString_djangoError",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "message",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "subtype",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "__typename",
          "storageKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": "invalidErrorType",
              "args": null,
              "kind": "ScalarField",
              "name": "type",
              "storageKey": null
            }
          ],
          "type": "InvalidErrorType",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": "invalidChoiceType",
              "args": null,
              "kind": "ScalarField",
              "name": "type",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "allowedValues",
              "storageKey": null
            }
          ],
          "type": "InvalidChoiceErrorType",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": "limitErrorType",
              "args": null,
              "kind": "ScalarField",
              "name": "type",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "limitValue",
              "storageKey": null
            }
          ],
          "type": "LimitValueErrorType",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "maxValue",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "minValue",
              "storageKey": null
            }
          ],
          "type": "OverflowValueErrorType",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": "uniqeValueErrorType",
              "args": null,
              "kind": "ScalarField",
              "name": "type",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "modelName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "fieldLabels",
              "storageKey": null
            }
          ],
          "type": "UniqueValueErrorType",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "DjangoErrorType",
  "abstractKey": null
};

(node as any).hash = "497bc2bd7ce24b1188b9b5776b8b5421";

export default node;
