import ListViewFilter, {
  ListViewFilterContainer,
} from "../../common/ListViewFilter";
import { useTranslation } from "react-i18next";

export enum SPDataState {
  ASSIGNED,
  UNASSIGNED,
}

interface SubproductDataListFilterProps {
  filterState: Set<SPDataState>;
  setFilterState: React.Dispatch<React.SetStateAction<Set<SPDataState>>>;
  filterDialogOpen?: boolean;
  onFilterDialogClose?: () => void;
}

export default function SubproductDataListFilter({
  filterState,
  setFilterState,
  filterDialogOpen,
  onFilterDialogClose,
}: SubproductDataListFilterProps) {
  const { t } = useTranslation();

  const spDataStateLabels = new Map<SPDataState, string>([
    [SPDataState.ASSIGNED, t("Assigned to a job")],
    [SPDataState.UNASSIGNED, t("Not assigned to a job")],
  ]);

  return (
    <ListViewFilterContainer
      dialogOpen={filterDialogOpen}
      onDialogClose={onFilterDialogClose}
    >
      <ListViewFilter
        title={t("Filter by state")}
        keys={[SPDataState.ASSIGNED, SPDataState.UNASSIGNED]}
        labels={spDataStateLabels}
        state={filterState}
        setState={setFilterState}
      />
    </ListViewFilterContainer>
  );
}
