import { PreloadedQuery, useFragment, useMutation } from "react-relay";
import { JobDetailViewSlowQuery } from "./__generated__/JobDetailViewSlowQuery.graphql";
import { useTranslation } from "react-i18next";
import {
  colorLabels,
  getBindingLabels,
  getConversionWarningTypeLabels,
  machineLabels,
  workStyleLabels,
} from "../../../__generatedEnumLabels";
import {
  Autocomplete,
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CircularProgress,
  createFilterOptions,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  Skeleton,
  TextField,
  Typography,
} from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import { Controller, useForm } from "react-hook-form";
import React, { ReactNode, Suspense, useEffect, useState } from "react";
import CardSection from "../../common/CardSection";
import InfoItem from "../../common/InfoItem";
import RelatedSubproductsList, {
  RelatedSubproductsListSkeleton,
} from "./RelatedSubproductsList";
import intersperse from "../../../utils/intersperse";
import JobPaperInfoItem from "./JobPaperInfoItem";
import PaperAutocomplete from "./PaperAutocomplete";
import { LoadingButton } from "@mui/lab";
import {
  Binding,
  Color,
  JobDataCard_params$key,
  Machine,
  WorkStyle,
} from "./__generated__/JobDataCard_params.graphql";
import {
  JobDataCardMutation,
  JobDataCardMutation$data,
} from "./__generated__/JobDataCardMutation.graphql";
import {
  JobDataCardCommitMutation,
  JobDataCardCommitMutation$data,
} from "./__generated__/JobDataCardCommitMutation.graphql";
import ErrorString from "../../common/ErrorString";
import InfoItemOrFormInput from "./InfoItemOrFormInput";
import InfoItemOrAutocomplete from "./InfoItemOrAutocomplete";
import QueryErrorDialog from "../../common/QueryErrorDialog";
import queryErrorToMessages from "../../../utils/queryErrorToMessages";
import { RecordSourceSelectorProxy } from "relay-runtime";
import StatusStepper from "../../common/StatusStepper";

interface JobDataCardProps {
  jobFragmentRef: JobDataCard_params$key;
  jobDetailSlowQueryRef: PreloadedQuery<JobDetailViewSlowQuery>;
}

export default function JobDataCard({
  jobFragmentRef,
  jobDetailSlowQueryRef,
}: JobDataCardProps) {
  const { t } = useTranslation();

  const bindingLabels = getBindingLabels(t);
  let bindingOptions: Binding[] = [];
  bindingLabels.forEach((value, key) => bindingOptions.push(key));

  let machineOptions: Machine[] = [];
  machineLabels.forEach((value, key) => machineOptions.push(key));

  let workstyleOptions: WorkStyle[] = [];
  workStyleLabels.forEach((value, key) => workstyleOptions.push(key));

  let colorOptions: Color[] = [];
  colorLabels.forEach((value, key) => colorOptions.push(key));
  const colorFilterOptions = createFilterOptions({
    limit: 30,
    stringify: (color: Color) => colorLabels.get(color) || "",
  });

  const data = useFragment(
    graphql`
      fragment JobDataCard_params on JobNode {
        id
        pk
        state
        isReady
        jdfId
        name
        frontcolors
        backcolors
        binding
        printingMachine
        workstyle
        note
        signaturesPerSheetX
        signaturesPerSheetY
        printSheetCount
        lamination
        creasing
        uvFinish
        shapeCutting
        embossing
        paperNettPerSheet
        paperGrossPerSheet
        paperReservePerJob
        paperId

        postPopulationWarnings {
          field
          type
          value
        }
      }
    `,
    jobFragmentRef
  );

  const [commitUpdateJob, updateJobIsInFlight] =
    useMutation<JobDataCardMutation>(
      graphql`
        mutation JobDataCardMutation($input: UpdateJobFieldInput!) {
          updateJob(input: $input) {
            job {
              ...JobDataCard_params
            }
            errors {
              field
              errors {
                __typename
                ... on BaseErrorType {
                  ...ErrorString_baseError
                }
                ... on DjangoErrorType {
                  ...ErrorString_djangoError
                }
                ... on UpdateJobMutationErrorType {
                  ...ErrorString_updateJobMutationError
                }
              }
            }
          }
        }
      `
    );
  const [commitCommitJob, commitJobIsInFlight] =
    useMutation<JobDataCardCommitMutation>(
      graphql`
        mutation JobDataCardCommitMutation($input: UpdateJobFieldInput!) {
          updateJob(input: $input) {
            job {
              # Fragments used by fast query
              ...JobDataCard_params
              # Updated tasks
              ...TasksCard_tasks
              # Fragments used by slow query
              ...JobPaperInfoItem_paper
              ...RelatedSubproductsList_subproductData
            }
            errors {
              field
              errors {
                __typename
                ... on BaseErrorType {
                  ...ErrorString_baseError
                }
                ... on DjangoErrorType {
                  ...ErrorString_djangoError
                }
                ... on UpdateJobMutationErrorType {
                  ...ErrorString_updateJobMutationError
                }
              }
            }
          }
        }
      `
    );

  const { handleSubmit, control, reset, getValues } = useForm();

  useEffect(() => {
    let newData: any = {};
    for (const [key] of Object.entries(getValues())) {
      // @ts-ignore
      newData[key] = data[key];
    }
    reset(newData);
  }, [data, reset, getValues]);

  // We are using this custom solution for form error handling instead of react-hook-forms setError APIs, since our
  // error translation is built as React Component (<ErrorString ...> needs hooks for GraphQL fragments and
  // translations), and existing APIs let us only pass error messages as strings.
  const [formErrors, setFormErrors] = useState<{
    [fieldName: string]: ReactNode[];
  }>({});
  let formWarnings: { [fieldName: string]: ReactNode[] } = {};
  for (const warning of data.postPopulationWarnings || []) {
    if (!(warning.field in formWarnings)) {
      formWarnings[warning.field] = [];
    }
    formWarnings[warning.field].push(
      <Typography>
        {getConversionWarningTypeLabels(t).get(warning.type)}
        {warning.value ? `: ${warning.value}` : null}
      </Typography>
    );
  }

  const [mutationErrors, setMutationErrors] = useState<{
    open: boolean;
    primaryErrorKey: string | null;
    secondaryErrorKey: string | null;
  }>({
    open: false,
    primaryErrorKey: null,
    secondaryErrorKey: null,
  });

  const getUpdateMutationParams = (formData: any, commit = false) => ({
    variables: {
      input: {
        ...formData,
        commit: commit,
        id: data.pk,
      },
    },
    onError: (error: Error) => {
      const errorKeys = queryErrorToMessages(error);
      console.log(error);
      setMutationErrors({
        open: true,
        primaryErrorKey: errorKeys.error.primaryError,
        secondaryErrorKey: errorKeys.error.secondaryError,
      });
    },
    onCompleted: (
      response: JobDataCardMutation$data | JobDataCardCommitMutation$data
    ) => {
      if (response.updateJob?.errors != null) {
        let newFormErrors: { [fieldName: string]: ReactNode[] } = {};
        for (let error of response.updateJob?.errors) {
          newFormErrors[error.field] = error.errors.map((field_error) => (
            <ErrorString errorRef={field_error} />
          ));
        }
        setFormErrors(newFormErrors);
      }
    },
    updater: (store: RecordSourceSelectorProxy) => {
      // Remove postPopulationWarnings - probably were resolved by user on save, and even if they weren't, job is no
      // longer in the same state it was after being populated from SP data, therefore these warning may be out-of-date.
      const jobRecordProxy = store.get(data.id);
      jobRecordProxy?.setLinkedRecords([], "postPopulationWarnings");
    },
  });

  return (
    <Card variant="outlined">
      <QueryErrorDialog
        handleClose={() => {
          setMutationErrors({
            ...mutationErrors,
            open: false,
          });
        }}
        open={mutationErrors["open"]}
        primaryError={
          mutationErrors["primaryErrorKey"] != null
            ? t(mutationErrors["primaryErrorKey"])
            : null
        }
        secondaryError={
          mutationErrors["secondaryErrorKey"] != null
            ? t(mutationErrors["secondaryErrorKey"])
            : null
        }
        showSaveFailed={true}
      />
      <CardHeader
        title={
          data.state !== "%future added value" ? (
            <StatusStepper activeStep={data.state} />
          ) : null
        }
      />
      <Divider />
      <CardContent>
        {"__all__" in formErrors ? (
          <List
            sx={{
              color: "#d32f2f",
            }}
          >
            {formErrors["__all__"].map((item) => (
              <ListItem>{item}</ListItem>
            ))}
          </List>
        ) : null}
        {/* We show task errors here since we don't want to have to bubble this information to the parent component
            so that it can be passed down to the Tasks card. */}
        {"tasks" in formErrors ? (
          <List
            sx={{
              color: "#d32f2f",
            }}
          >
            {formErrors["tasks"].map((item) => (
              <ListItem>{item}</ListItem>
            ))}
          </List>
        ) : null}
        {"__all__" in formWarnings ? (
          <List
            sx={{
              color: "#e89641",
            }}
          >
            {formWarnings["__all__"].map((item) => (
              <ListItem>{item}</ListItem>
            ))}
          </List>
        ) : null}
        <CardSection title={t("Basic job metadata")} disablePaddingForIcon>
          <InfoItem value={data.jdfId} description={t("Job JDF ID")} xs={12} />
          <InfoItemOrFormInput
            xs={12}
            name="name"
            description={t("Job name")}
            readOnly={data.isReady}
            initialValue={data.name}
            type="text"
            control={control}
            formErrors={formErrors}
            formWarnings={formWarnings}
          />
        </CardSection>
        <Divider variant="inset" />
        <CardSection
          title={t("Subproducts")}
          disablePaddingForIcon
          error={"subproductData" in formErrors}
        >
          <Suspense fallback={<RelatedSubproductsListSkeleton />}>
            <RelatedSubproductsList
              jobDetailSlowQueryRef={jobDetailSlowQueryRef}
              allowPopulatingJobFromSPData={!data.isReady}
              disablePopulateJobIcons={
                updateJobIsInFlight || commitJobIsInFlight
              }
              jobId={data.id}
            />
          </Suspense>
          {"subproductData" in formErrors ? (
            <List
              sx={{
                color: "#d32f2f",
              }}
            >
              {formErrors["subproductData"].map((item) => (
                <ListItem>{item}</ListItem>
              ))}
            </List>
          ) : null}
          {"subproductData" in formWarnings ? (
            <List
              sx={{
                color: "#e89641",
              }}
            >
              {formWarnings["subproductData"].map((item) => (
                <ListItem>{item}</ListItem>
              ))}
            </List>
          ) : null}
        </CardSection>
        <Divider variant="inset" />
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <CardSection title={t("Front colors")} disablePaddingForIcon>
              <InfoItemOrAutocomplete
                xs={12}
                name="frontcolors"
                description={t("Front colors")}
                readOnly={data.isReady}
                multiple={true}
                initialValues={data.frontcolors}
                control={control}
                formErrors={formErrors}
                formWarnings={formWarnings}
                options={colorOptions}
                filterOptions={colorFilterOptions}
                labels={colorLabels}
              />
            </CardSection>
          </Grid>
          <Grid item xs={6}>
            <CardSection title={t("Back colors")} disablePaddingForIcon>
              <Grid
                container
                sx={{
                  marginLeft: "16px",
                  marginTop: "16px",
                }}
              >
                <InfoItemOrAutocomplete
                  xs={12}
                  name="backcolors"
                  description={t("Back colors")}
                  readOnly={data.isReady}
                  multiple={true}
                  initialValues={data.backcolors}
                  control={control}
                  formErrors={formErrors}
                  formWarnings={formWarnings}
                  options={colorOptions}
                  filterOptions={colorFilterOptions}
                  labels={colorLabels}
                />
              </Grid>
            </CardSection>
          </Grid>
        </Grid>
        <Divider variant="inset" />
        <CardSection title={t("Basic job params")} disablePaddingForIcon>
          {data.isReady ? (
            <Suspense
              fallback={
                <InfoItem
                  xs={12}
                  sm={8}
                  value={<Skeleton />}
                  description={<Skeleton />}
                />
              }
            >
              <JobPaperInfoItem jobDetailSlowQueryRef={jobDetailSlowQueryRef} />
            </Suspense>
          ) : (
            <Grid item sm={8} xs={12}>
              <Controller
                control={control}
                name="paperId"
                defaultValue={data.paperId}
                render={({ field: { onChange, value } }) => (
                  <Suspense
                    fallback={
                      <Autocomplete
                        options={[]}
                        loading={true}
                        loadingText={t("Loading papers")}
                        sx={{ width: "100%" }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="filled"
                            label={t("Paper")}
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <React.Fragment>
                                  <CircularProgress
                                    color="inherit"
                                    size={20}
                                    sx={{ marginBottom: "15px" }}
                                  />
                                  {params.InputProps.endAdornment}
                                </React.Fragment>
                              ),
                            }}
                          />
                        )}
                      />
                    }
                  >
                    <PaperAutocomplete
                      formErrors={formErrors}
                      formWarnings={formWarnings}
                      name="paperId"
                      onChange={onChange}
                      value={value}
                    />
                  </Suspense>
                )}
              />
            </Grid>
          )}
          <InfoItemOrAutocomplete
            sm={4}
            xs={6}
            name="binding"
            description={t("Binding")}
            readOnly={data.isReady}
            initialValue={data.binding}
            control={control}
            formErrors={formErrors}
            formWarnings={formWarnings}
            options={bindingOptions}
            labels={bindingLabels}
          />
          <InfoItemOrAutocomplete
            xs={6}
            name="printingMachine"
            description={t("Printing Machine")}
            readOnly={data.isReady}
            initialValue={data.printingMachine}
            control={control}
            formErrors={formErrors}
            formWarnings={formWarnings}
            options={machineOptions}
            labels={machineLabels}
          />
          <InfoItemOrAutocomplete
            xs={6}
            name="workstyle"
            description={t("Workstyle")}
            readOnly={data.isReady}
            initialValue={data.workstyle}
            control={control}
            formErrors={formErrors}
            formWarnings={formWarnings}
            options={workstyleOptions}
            labels={workStyleLabels}
          />
          <InfoItemOrFormInput
            xs={12}
            name="note"
            description={t("Note")}
            readOnly={false}
            initialValue={data.note}
            type="text"
            multiline={true}
            control={control}
            formErrors={formErrors}
            formWarnings={formWarnings}
          />
        </CardSection>
        <Divider variant="inset" />
        <CardSection title={t("Job imposition params")} disablePaddingForIcon>
          <InfoItemOrFormInput
            sm={4}
            xs={6}
            name="signaturesPerSheetX"
            description={t("Signatures per sheet x")}
            readOnly={data.isReady}
            initialValue={data.signaturesPerSheetX}
            type="integer"
            control={control}
            formErrors={formErrors}
            formWarnings={formWarnings}
          />
          <InfoItemOrFormInput
            sm={4}
            xs={6}
            name="signaturesPerSheetY"
            description={t("Signatures per sheet y")}
            readOnly={data.isReady}
            initialValue={data.signaturesPerSheetY}
            type="integer"
            control={control}
            formErrors={formErrors}
            formWarnings={formWarnings}
          />
          <InfoItemOrFormInput
            sm={4}
            xs={6}
            name="printSheetCount"
            description={t("Number of print sheets")}
            readOnly={data.isReady}
            initialValue={data.printSheetCount}
            type="integer"
            control={control}
            formErrors={formErrors}
            formWarnings={formWarnings}
          />
        </CardSection>
        <Divider variant="inset" />
        <CardSection title={t("DTP")} disablePaddingForIcon>
          <InfoItemOrFormInput
            sm={4}
            xs={6}
            name="lamination"
            description={t("Lamination")}
            readOnly={data.isReady}
            initialValue={data.lamination}
            type="text"
            control={control}
            formErrors={formErrors}
            formWarnings={formWarnings}
          />
          <InfoItemOrFormInput
            sm={4}
            xs={6}
            name="creasing"
            description={t("Creasing")}
            readOnly={data.isReady}
            initialValue={data.creasing}
            type="text"
            control={control}
            formErrors={formErrors}
            formWarnings={formWarnings}
          />
          <InfoItemOrFormInput
            sm={4}
            xs={6}
            name="uvFinish"
            description={t("UV finish")}
            readOnly={data.isReady}
            initialValue={data.uvFinish}
            type="text"
            control={control}
            formErrors={formErrors}
            formWarnings={formWarnings}
          />
          <InfoItemOrFormInput
            sm={4}
            xs={6}
            name="shapeCutting"
            description={t("Shape cutting")}
            readOnly={data.isReady}
            initialValue={data.shapeCutting}
            type="text"
            control={control}
            formErrors={formErrors}
            formWarnings={formWarnings}
          />
          <InfoItemOrFormInput
            sm={4}
            xs={6}
            name="embossing"
            description={t("Embossing")}
            readOnly={data.isReady}
            initialValue={data.embossing}
            type="text"
            control={control}
            formErrors={formErrors}
            formWarnings={formWarnings}
          />
        </CardSection>
        <Divider variant="inset" />
        <CardSection title={t("Press Machine Operator")} disablePaddingForIcon>
          <InfoItemOrFormInput
            sm={4}
            xs={6}
            name="paperNettPerSheet"
            description={t("Paper nett per sheet")}
            readOnly={false}
            initialValue={data.paperNettPerSheet}
            type="integer"
            control={control}
            formErrors={formErrors}
            formWarnings={formWarnings}
          />
          <InfoItemOrFormInput
            sm={4}
            xs={6}
            name="paperGrossPerSheet"
            description={t("Paper gross per sheet")}
            readOnly={false}
            initialValue={data.paperGrossPerSheet}
            type="integer"
            control={control}
            formErrors={formErrors}
            formWarnings={formWarnings}
          />
          <InfoItemOrFormInput
            sm={4}
            xs={6}
            name="paperReservePerJob"
            description={t("Paper reserve per job")}
            readOnly={false}
            initialValue={data.paperReservePerJob}
            type="integer"
            control={control}
            formErrors={formErrors}
            formWarnings={formWarnings}
          />
          {/* TODO estimated time */}
        </CardSection>
      </CardContent>
      <CardActions>
        <Box sx={{ flexGrow: 1 }} />
        {data.isReady ? null : (
          <LoadingButton
            loading={commitJobIsInFlight}
            disabled={updateJobIsInFlight}
            onClick={() => {
              const onSubmit = (formData: any) => {
                setFormErrors({});
                commitCommitJob(getUpdateMutationParams(formData, true));
              };
              handleSubmit(onSubmit)();
            }}
          >
            {t("Save and commit job")}
          </LoadingButton>
        )}
        <LoadingButton
          loading={updateJobIsInFlight}
          disabled={commitJobIsInFlight}
          onClick={() => {
            const onSubmit = (formData: any) => {
              setFormErrors({});
              commitUpdateJob(getUpdateMutationParams(formData));
            };
            handleSubmit(onSubmit)();
          }}
          variant="contained"
        >
          {t("Save")}
        </LoadingButton>
      </CardActions>
    </Card>
  );
}

export function JobDataCardSkeleton() {
  const { t } = useTranslation();
  return (
    <Card variant="outlined">
      <CardHeader title={<Skeleton />} />
      <Divider />
      <CardContent>
        <CardSection title={t("Basic job metadata")} disablePaddingForIcon>
          <InfoItem xs={12} value={<Skeleton />} description={t("Job name")} />
        </CardSection>
        <Divider variant="inset" />
        <CardSection title={t("Subproducts")} disablePaddingForIcon>
          <RelatedSubproductsListSkeleton />
        </CardSection>
        <Divider variant="inset" />
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <CardSection title={t("Front colors")} disablePaddingForIcon>
              <List sx={{ width: "100%" }}>
                {intersperse(
                  [1, 2, 3, 4].map((key) => (
                    <ListItem key={`fc_${key}`}>
                      <ListItemText primary={<Skeleton />} />
                    </ListItem>
                  )),
                  <Divider />
                )}
              </List>
            </CardSection>
          </Grid>
          <Grid item xs={6}>
            <CardSection title={t("Back colors")} disablePaddingForIcon>
              <List sx={{ width: "100%" }}>
                {intersperse(
                  [1, 2, 3, 4].map((key) => (
                    <ListItem key={`bc_${key}`}>
                      <ListItemText primary={<Skeleton />} />
                    </ListItem>
                  )),
                  <Divider />
                )}
              </List>
            </CardSection>
          </Grid>
        </Grid>
        <Divider variant="inset" />
        <CardSection title={t("Basic job params")} disablePaddingForIcon>
          <InfoItem
            xs={12}
            sm={8}
            value={<Skeleton />}
            description={t("Paper")}
          />
          <InfoItem
            xs={6}
            sm={4}
            value={<Skeleton />}
            description={t("Binding")}
          />
          <InfoItem
            xs={6}
            value={<Skeleton />}
            description={t("Printing Machine")}
          />
          <InfoItem xs={6} value={<Skeleton />} description={t("Workstyle")} />
          <InfoItem xs={12} value={<Skeleton />} description={t("Note")} />
        </CardSection>
        <Divider variant="inset" />
        <CardSection title={t("Job imposition params")} disablePaddingForIcon>
          <InfoItem
            sm={4}
            xs={6}
            value={<Skeleton />}
            description={t("Signatures per sheet x")}
          />
          <InfoItem
            sm={4}
            xs={6}
            value={<Skeleton />}
            description={t("Signatures per sheet y")}
          />
          <InfoItem
            sm={4}
            xs={6}
            value={<Skeleton />}
            description={t("Number of print sheets")}
          />
        </CardSection>
        <Divider variant="inset" />
        <CardSection title={t("DTP")} disablePaddingForIcon>
          <InfoItem
            sm={4}
            xs={6}
            value={<Skeleton />}
            description={t("Lamination")}
          />
          <InfoItem
            sm={4}
            xs={6}
            value={<Skeleton />}
            description={t("Creasing")}
          />
          <InfoItem
            sm={4}
            xs={6}
            value={<Skeleton />}
            description={t("UV finish")}
          />
          <InfoItem
            sm={4}
            xs={6}
            value={<Skeleton />}
            description={t("Shape cutting")}
          />
          <InfoItem
            sm={4}
            xs={6}
            value={<Skeleton />}
            description={t("Embossing")}
          />
        </CardSection>
        <Divider variant="inset" />
        <CardSection title={t("Press Machine Operator")} disablePaddingForIcon>
          <InfoItem
            sm={4}
            xs={6}
            value={<Skeleton />}
            description={t("Paper nett per sheet")}
          />
          <InfoItem
            sm={4}
            xs={6}
            value={<Skeleton />}
            description={t("Paper gross per sheet")}
          />
          <InfoItem
            sm={4}
            xs={6}
            value={<Skeleton />}
            description={t("Paper reserve per job")}
          />
          {/* TODO estimated time */}
        </CardSection>
      </CardContent>
    </Card>
  );
}
