import { TFunctionResult } from "i18next";
import React from "react";
import { Grid, GridSize, Typography } from "@mui/material";

interface InfoItemProps {
  value:
    | (string | number | TFunctionResult | React.ReactNode)
    | null
    | undefined;
  description: string | React.ReactNode;
  xs: GridSize;
  sm?: GridSize;
}

export default function InfoItem({
  value,
  description,
  xs,
  sm,
}: InfoItemProps) {
  return (
    <Grid item xs={xs} sm={sm}>
      <Typography sx={{ fontSize: "16px" }}>
        {value == null || value === ""
          ? "—"
          : React.isValidElement(value)
          ? value
          : "" + value}
      </Typography>
      <Typography sx={{ fontSize: "12px", color: "#777" }}>
        {description}
      </Typography>
    </Grid>
  );
}
