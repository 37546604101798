import { Box, Grid, useTheme } from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import { PreloadedQuery, usePreloadedQuery } from "react-relay";
import React, { Suspense } from "react";
import JobManagerAppBar from "../../navigation/JobManagerAppBar/JobManagerAppBar";
import ErrorBoundaryCard from "../../common/ErrorBoundaryCard";
import JobDataCard, { JobDataCardSkeleton } from "./JobDataCard";
import TasksCard, { TasksCardSkeleton } from "./TasksCard";
import SubproductDataSelectorCard from "./SubproductDataSelectorCard";
import { JobDetailViewFastQuery } from "./__generated__/JobDetailViewFastQuery.graphql";
import { JobDetailViewSlowQuery } from "./__generated__/JobDetailViewSlowQuery.graphql";
import NotFoundCard from "../../common/NotFoundCard";

// We use two queries for this component, because there is a difference in how fast certain query elements can be
// fetched by the backend (some it can serve from local DB, and for some it must contact an upstream service).
// Since our backend does not support the @defer directive, and we want to show parts of job it can serve quickly ASAP,
// we fetch the slower parts with a second query and render a Skeleton for a while.
export const slowQuery = graphql`
  query JobDetailViewSlowQuery($pk: Int!) {
    job(pk: $pk) {
      ...JobPaperInfoItem_paper
      ...RelatedSubproductsList_subproductData
    }
  }
`;

interface JobDetailContentProps {
  jobDetailFastQueryRef: PreloadedQuery<JobDetailViewFastQuery>;
  jobDetailSlowQueryRef: PreloadedQuery<JobDetailViewSlowQuery>;
  toggleDrawer: (
    open: boolean
  ) => (event: React.KeyboardEvent | React.MouseEvent) => void;
}

function JobDetailContent({
  jobDetailFastQueryRef,
  jobDetailSlowQueryRef,
}: JobDetailContentProps) {
  const theme = useTheme();
  const queryData = usePreloadedQuery<JobDetailViewFastQuery>(
    graphql`
      query JobDetailViewFastQuery($pk: Int!) {
        job(pk: $pk) {
          id
          pk
          isReady
          ...JobDataCard_params
          ...TasksCard_tasks
          subproductData {
            edges {
              node {
                id
              }
            }
          }
        }
      }
    `,
    jobDetailFastQueryRef
  );

  if (queryData.job == null) {
    return <NotFoundCard />;
  }

  let subproductDataSelectionCard = null;
  const assignedSPDataIDs: string[] = queryData
    .job!.subproductData.edges.filter((edge) => edge?.node?.id !== undefined)
    .map((edge) => edge!.node!.id);

  const isJobReady = queryData.job?.isReady;

  if (!isJobReady) {
    subproductDataSelectionCard = (
      <Grid item xl={4} lg={6} sm={8} xs={12}>
        <SubproductDataSelectorCard
          jobId={queryData.job!.id}
          assignedSubproductDataIDs={assignedSPDataIDs}
        />
      </Grid>
    );
  }

  return (
    <Grid
      container
      justifyContent="center"
      spacing={1}
      sx={{
        paddingLeft: "32px",
        paddingRight: "32px",
        [theme.breakpoints.down(680)]: {
          padding: 0,
        },
      }}
    >
      {subproductDataSelectionCard}
      <Grid item xl={isJobReady ? 4 : 5} lg={6} sm={8} xs={12}>
        <JobDataCard
          jobFragmentRef={queryData.job!}
          jobDetailSlowQueryRef={jobDetailSlowQueryRef}
        />
      </Grid>
      <Grid item xl={isJobReady ? 4 : 3} lg={6} sm={8} xs={12}>
        <TasksCard
          jobQueryResponse={queryData}
          jobId={queryData.job.id}
          jobIsReady={queryData.job?.isReady}
        />
      </Grid>
    </Grid>
  );
}

function JobDetailContentSkeleton() {
  const theme = useTheme();

  return (
    <Grid
      container
      justifyContent="center"
      spacing={1}
      sx={{
        paddingLeft: "32px",
        paddingRight: "32px",
        [theme.breakpoints.down(680)]: {
          padding: 0,
        },
      }}
    >
      <Grid item xl={4} lg={6} sm={8} xs={12}>
        <JobDataCardSkeleton />
      </Grid>
      <Grid item xl={4} lg={6} sm={8} xs={12}>
        <TasksCardSkeleton />
      </Grid>
    </Grid>
  );
}

export default function JobDetailView({
  jobDetailFastQueryRef,
  jobDetailSlowQueryRef,
  toggleDrawer,
}: JobDetailContentProps) {
  const theme = useTheme();

  return (
    <>
      <JobManagerAppBar toggleDrawer={toggleDrawer} />
      <Box
        sx={{
          marginTop: "32px",
          maxWidth: "2232px",
          marginLeft: "auto",
          marginRight: "auto",
          [theme.breakpoints.down(680)]: {
            margin: "16px 0",
          },
        }}
      >
        <Suspense fallback={<JobDetailContentSkeleton />}>
          <ErrorBoundaryCard>
            <JobDetailContent
              jobDetailFastQueryRef={jobDetailFastQueryRef}
              jobDetailSlowQueryRef={jobDetailSlowQueryRef}
              toggleDrawer={toggleDrawer}
            />
          </ErrorBoundaryCard>
        </Suspense>
      </Box>
    </>
  );
}
