/**
 * @generated SignedSource<<6868a35e081f30a20df2af1170e1b0a1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type UpdateJobMutationErrorDetail = "NEEDS_AT_LEAST_ONE_ENABLED_TASK" | "NEEDS_AT_LEAST_ONE_SP_DATA" | "ALL_SP_DATA_MUST_HAVE_URL" | "JOB_ALREADY_COMMITTED" | "FRONTCOLORS_OR_BACKCOLORS_REQUIRED" | "CANT_QUERY_PPS" | "CANT_FIND_PAPER_IN_PPS" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ErrorString_updateJobMutationError$data = {
  readonly updateJobErrorType: UpdateJobMutationErrorDetail;
  readonly " $fragmentType": "ErrorString_updateJobMutationError";
};
export type ErrorString_updateJobMutationError$key = {
  readonly " $data"?: ErrorString_updateJobMutationError$data;
  readonly " $fragmentSpreads": FragmentRefs<"ErrorString_updateJobMutationError">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ErrorString_updateJobMutationError",
  "selections": [
    {
      "alias": "updateJobErrorType",
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    }
  ],
  "type": "UpdateJobMutationErrorType",
  "abstractKey": null
};

(node as any).hash = "96067f911f734c57e58d615984a20bda";

export default node;
