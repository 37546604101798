import { PreloadedQuery, useMutation, usePreloadedQuery } from "react-relay";
import React, { ReactNode, useState } from "react";
import { useTranslation } from "react-i18next";
import graphql from "babel-plugin-relay/macro";
import CardSection from "../../common/CardSection";
import {
  Alert,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  Divider,
  FilledInput,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  Skeleton,
  useTheme,
} from "@mui/material";
import AttachmentIcon from "@mui/icons-material/Attachment";
import InfoIcon from "@mui/icons-material/Info";
import InfoItem from "../../common/InfoItem";
import {
  requestedPaperToString,
  sizeToString,
} from "../../../utils/stringifiers";
import PrintIcon from "@mui/icons-material/Print";
import { LoadingButton } from "@mui/lab";
import NotFoundCard from "../../common/NotFoundCard";
import { SubproductDataCardQuery } from "./__generated__/SubproductDataCardQuery.graphql";
import { SubproductDataCardMutation } from "./__generated__/SubproductDataCardMutation.graphql";
import ErrorString from "../../common/ErrorString";
import QueryErrorDialog from "../../common/QueryErrorDialog";
import queryErrorToMessages from "../../../utils/queryErrorToMessages";
import { CreateOrOverwriteJobMutationError } from "../../job/JobDetail/__generated__/RelatedSubproductsListPopulateDataMutation.graphql";
import { SubproductDataCardCreateJobMutation } from "./__generated__/SubproductDataCardCreateJobMutation.graphql";
import { RecordSourceSelectorProxy } from "relay-runtime";
import { useNavigate } from "react-suspense-router";
import { getCreateOrOverwriteJobMutationErrorLabels } from "../../../__generatedEnumLabels";

interface SubproductDataCardProps {
  subproductDataQueryRef: PreloadedQuery<SubproductDataCardQuery>;
  onCancel?: () => void;
  hideSaveAndCreateJob?: boolean;
}

export default function SubproductDataCard({
  subproductDataQueryRef,
  onCancel,
  hideSaveAndCreateJob,
}: SubproductDataCardProps) {
  const { t } = useTranslation();
  const theme = useTheme();

  const { subproductData } = usePreloadedQuery(
    graphql`
      query SubproductDataCardQuery($pk: Int!) {
        subproductData(pk: $pk) {
          id
          pk
          dataUrl
          version
          isLocked
          jobSet {
            edges {
              node {
                jdfId
              }
            }
          }
          subproduct {
            number
            name
            binding
            pagesCount
            productPart
            run
            color {
              front
              back
            }
            requestedPaper {
              class
              weight
            }
            sizeOpen {
              width
              height
            }
            sizeClosed {
              width
              height
            }
            machine
            impositionPattern
            workstyle
            lamination
            creasing
            uvFinish
            shapeCutting
            embossing
            varnish
            product {
              name
              number
              dueDate
              project {
                name
                number
              }
            }
          }
        }
      }
    `,
    subproductDataQueryRef
  );

  const subproduct = subproductData?.subproduct;

  const [commit, isInFlight] = useMutation<SubproductDataCardMutation>(graphql`
    mutation SubproductDataCardMutation($input: SubproductDataMutationInput!) {
      updateSubproductData(input: $input) {
        subproductData {
          id
          dataUrl
          version
        }
        errors {
          field
          errors {
            __typename
            ... on BaseErrorType {
              ...ErrorString_baseError
            }
            ... on DjangoErrorType {
              ...ErrorString_djangoError
            }
            ... on NextcloudFieldErrorType {
              ...ErrorString_nextcloudFieldError
            }
            ... on UpdateSubproductDataMutationErrorType {
              ...ErrorString_updateSubproductDataMutationError
            }
          }
        }
      }
    }
  `);

  const [isSaveAndCreateJobRunning, setIsSaveAndCreateJobRunning] =
    useState(false);

  const [commitCreateJob] = useMutation<SubproductDataCardCreateJobMutation>(
    graphql`
      mutation SubproductDataCardCreateJobMutation($spDataId: ID) {
        createOrOverwriteJob(input: { spDataId: $spDataId }) {
          job {
            id
            pk
            ...JobDataCard_params
          }
          errors
          warnings {
            field
            type
            value
          }
        }
      }
    `
  );

  const [dataUrl, setDataUrl] = useState<string>(subproductData?.dataUrl!);
  const [dataFieldError, setDataFieldError] = useState<ReactNode[]>([]);
  const [version, setVersion] = useState<string>(subproductData?.version!);
  const [versionFieldError, setVersionFieldError] = useState<ReactNode[]>([]);

  const [mutationErrors, setMutationErrors] = useState<
    | {
        open: boolean;
        primaryErrorKey: string | null;
        secondaryErrorKey: string | null;
      }
    | {
        open: boolean;
        createOrOverwriteErrors: readonly CreateOrOverwriteJobMutationError[];
      }
  >({
    open: false,
    primaryErrorKey: null,
    secondaryErrorKey: null,
  });

  const navigate = useNavigate();

  if (subproductData == null) {
    return <NotFoundCard />;
  }

  const assignedJobs =
    subproductData?.jobSet.edges.length === 0 ? null : (
      <CardSection title={t("Assigned jobs")}>
        <Grid item xs={12}>
          {subproductData?.jobSet.edges.map((edge) => {
            if (edge?.node?.jdfId == null) return null;
            return (
              <Chip
                key={edge?.node?.jdfId}
                label={edge.node.jdfId}
                variant={"outlined"}
                sx={{ marginRight: "8px" }}
              />
            );
          })}
        </Grid>
      </CardSection>
    );

  const runSaveMutation = (onSuccess?: () => void, onFailure?: () => void) => {
    commit({
      variables: {
        input: {
          id: `${subproductData.pk}`,
          dataUrl: dataUrl,
          version: version,
        },
      },
      onCompleted: ({ updateSubproductData }) => {
        setDataFieldError([]);
        setVersionFieldError([]);
        for (const error of updateSubproductData?.errors || []) {
          switch (error?.field) {
            case "data_url":
              setDataFieldError(
                error.errors.map((field_error) => (
                  <ErrorString errorRef={field_error} />
                ))
              );
              break;
            case "version":
              setVersionFieldError(
                error.errors.map((field_error) => (
                  <ErrorString errorRef={field_error} />
                ))
              );
              break;
          }
        }
        if (
          updateSubproductData?.errors == null ||
          updateSubproductData.errors.length === 0
        ) {
          if (onSuccess !== undefined) {
            onSuccess();
          }
        } else {
          if (onFailure !== undefined) {
            onFailure();
          }
        }
      },
      onError: (error: Error) => {
        const errorKeys = queryErrorToMessages(error);
        console.log(error);
        setMutationErrors({
          open: true,
          primaryErrorKey: errorKeys.error.primaryError,
          secondaryErrorKey: errorKeys.error.secondaryError,
        });
        if (onFailure !== undefined) {
          onFailure();
        }
      },
    });
  };

  const runSaveAndCreateJobMutation = () => {
    setIsSaveAndCreateJobRunning(true);
    runSaveMutation(
      () => {
        commitCreateJob({
          variables: {
            spDataId: `${subproductData.id}`,
          },
          onCompleted: ({ createOrOverwriteJob }) => {
            if ((createOrOverwriteJob?.errors || []).length > 0) {
              setMutationErrors({
                open: true,
                createOrOverwriteErrors: createOrOverwriteJob!.errors!,
              });
            }
            if (createOrOverwriteJob?.job?.pk != null) {
              navigate(`/jobs/${createOrOverwriteJob.job.pk}`);
            }
            setIsSaveAndCreateJobRunning(false);
          },
          onError: (error: Error) => {
            const errorKeys = queryErrorToMessages(error);
            console.log(error);
            setMutationErrors({
              open: true,
              primaryErrorKey: errorKeys.error.primaryError,
              secondaryErrorKey: errorKeys.error.secondaryError,
            });
            setIsSaveAndCreateJobRunning(false);
          },
          updater: (store: RecordSourceSelectorProxy, payload) => {
            // Take warnings from this mutation, and assign them to postPopulationWarnings, which is defined in
            // our custom schema extension. That way, these warnings can be easily displayed in the JobDetail
            // card.
            if (payload.createOrOverwriteJob?.job?.id === null) {
              return;
            }
            const jobRecordProxy = store.get(
              payload.createOrOverwriteJob!.job!.id
            );
            const storePayload = store.getRootField("createOrOverwriteJob");
            const warnings = storePayload?.getLinkedRecords("warnings");
            if (warnings == null) {
              return;
            }
            jobRecordProxy?.setLinkedRecords(
              warnings,
              "postPopulationWarnings"
            );
          },
        });
      },
      () => {
        setIsSaveAndCreateJobRunning(false);
      }
    );
  };

  const queryErrorDialog =
    "createOrOverwriteErrors" in mutationErrors ? (
      <QueryErrorDialog
        handleClose={() => {
          setMutationErrors({
            ...mutationErrors,
            open: false,
          });
        }}
        open={mutationErrors["open"]}
        primaryError={t("Error while populating data from subproduct")}
        secondaryError={mutationErrors["createOrOverwriteErrors"]
          .map((err) => getCreateOrOverwriteJobMutationErrorLabels(t).get(err))
          .filter((err): err is string => err !== undefined)}
      />
    ) : (
      <QueryErrorDialog
        handleClose={() => {
          setMutationErrors({
            ...mutationErrors,
            open: false,
          });
        }}
        open={mutationErrors["open"]}
        primaryError={
          mutationErrors["primaryErrorKey"] != null
            ? t(mutationErrors["primaryErrorKey"])
            : null
        }
        secondaryError={
          mutationErrors["secondaryErrorKey"] != null
            ? t(mutationErrors["secondaryErrorKey"])
            : null
        }
        showSaveFailed={true}
      />
    );

  return (
    <Card
      variant="outlined"
      sx={{
        width: "660px",
        margin: "auto",
        [theme.breakpoints.down(680)]: {
          width: "100%",
          margin: 0,
        },
      }}
    >
      {queryErrorDialog}
      {subproduct != null ? (
        <CardHeader title={subproduct?.number} subheader={subproduct?.name} />
      ) : (
        <Alert severity="error" sx={{ width: "100%" }}>
          <p>{t("This subproduct cant be found may have been deleted")}</p>
        </Alert>
      )}
      <Divider />
      <CardContent>
        <CardSection title={t("Data")} icon={<AttachmentIcon />}>
          <Grid item xs={12} sm={8}>
            <FormControl
              variant="filled"
              sx={{ width: "100%" }}
              error={dataFieldError.length > 0}
              disabled={subproductData?.isLocked}
            >
              <InputLabel htmlFor="data-url">{t("Data URL")}</InputLabel>
              <FilledInput
                id="data-url"
                value={dataUrl}
                onChange={(event) => setDataUrl(event.target.value)}
              />
              {/* TODO key prop in FormHelperText */}
              {dataFieldError.map((error) => (
                <FormHelperText error>{error}</FormHelperText>
              ))}
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl
              variant="filled"
              sx={{ width: "100%" }}
              error={versionFieldError.length > 0}
              required
              disabled={subproductData?.isLocked}
            >
              <InputLabel htmlFor="data-version">{t("Version")}</InputLabel>
              <FilledInput
                id="data-version"
                value={version}
                onChange={(event) => setVersion(event.target.value)}
              />
              {/* TODO key prop in FormHelperText */}
              {versionFieldError.map((error) => (
                <FormHelperText error>{error}</FormHelperText>
              ))}
            </FormControl>
          </Grid>
        </CardSection>
        <Divider variant="inset" />
        {assignedJobs == null ? null : (
          <>
            {assignedJobs}
            <Divider variant="inset" />
          </>
        )}
        <CardSection title={t("General")} icon={<InfoIcon />}>
          <InfoItem
            sm={3}
            xs={6}
            value={
              subproduct?.product?.dueDate == null
                ? null
                : t("intlDateTime", {
                    val: new Date(subproduct.product.dueDate),
                    formatParams: {
                      val: {
                        weekday: "long",
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                      },
                    },
                  })
            }
            description={t("Due Date")}
          />
          <InfoItem
            sm={3}
            xs={6}
            value={subproduct?.pagesCount}
            description={t("Pages Count")}
          />
          <InfoItem
            sm={3}
            xs={6}
            value={subproduct?.binding}
            description={t("Binding")}
          />
          <InfoItem
            sm={3}
            xs={6}
            value={subproduct?.productPart}
            description={t("Product Part")}
          />
          <InfoItem
            sm={3}
            xs={6}
            value={
              subproduct?.run == null
                ? null
                : t("X pcs", { pcs: subproduct?.run! })
            }
            description={t("Run")}
          />
          <InfoItem
            sm={3}
            xs={6}
            value={
              subproduct?.color != null
                ? subproduct?.color?.front + "/" + subproduct?.color?.back
                : null
            }
            description={t("Color")}
          />
          <InfoItem
            sm={6}
            xs={12}
            value={requestedPaperToString(subproduct?.requestedPaper)}
            description={t("Requested Paper")}
          />
          <InfoItem
            sm={6}
            xs={12}
            value={sizeToString(subproduct?.sizeOpen)}
            description={t("Size Closed")}
          />
          <InfoItem
            sm={6}
            xs={12}
            value={sizeToString(subproduct?.sizeClosed)}
            description={t("Size Open")}
          />
        </CardSection>
        <Divider variant="inset" />
        <CardSection title={t("Press")} icon={<PrintIcon />}>
          <InfoItem
            xs={6}
            value={subproduct?.machine}
            description={t("Printing Machine")}
          />
          <InfoItem
            xs={6}
            value={subproduct?.impositionPattern}
            description={t("Imposition pattern")}
          />
          <InfoItem
            xs={6}
            value={subproduct?.workstyle}
            description={t("Work Style")}
          />
        </CardSection>
        <Divider variant="inset" />
        <CardSection title={t("Post Press")}>
          <InfoItem
            sm={3}
            xs={6}
            value={subproduct?.lamination}
            description={t("Laminating")}
          />
          <InfoItem
            sm={3}
            xs={6}
            value={subproduct?.creasing}
            description={t("Creasing")}
          />
          <InfoItem
            sm={3}
            xs={6}
            value={subproduct?.uvFinish}
            description={t("UV Finish")}
          />
          <InfoItem
            sm={3}
            xs={6}
            value={subproduct?.shapeCutting}
            description={t("Shape cutting")}
          />
          <InfoItem
            sm={3}
            xs={6}
            value={subproduct?.embossing}
            description={t("Embossing")}
          />
          <InfoItem
            sm={3}
            xs={6}
            value={subproduct?.varnish}
            description={t("Varnishing")}
          />
        </CardSection>
        <Divider variant="inset" />
        <CardSection title={t("Other")}>
          <InfoItem
            sm={3}
            xs={12}
            value={subproduct?.product?.project?.number}
            description={t("Project Number")}
          />
          <InfoItem
            sm={9}
            xs={12}
            value={subproduct?.product?.project?.name}
            description={t("Project Name")}
          />
          <InfoItem
            sm={3}
            xs={12}
            value={subproduct?.product?.number}
            description={t("Product Number")}
          />
          <InfoItem
            sm={9}
            xs={12}
            value={subproduct?.product?.name}
            description={t("Product Name")}
          />
        </CardSection>
      </CardContent>
      <Divider />
      <CardActions>
        <Button onClick={() => (onCancel != null ? onCancel() : null)}>
          {t("Cancel")}
        </Button>
        <Box sx={{ flexGrow: 1 }} />
        {hideSaveAndCreateJob ? null : (
          <LoadingButton
            loading={isSaveAndCreateJobRunning}
            disabled={isInFlight || subproductData?.isLocked}
            onClick={() => {
              if (subproductData?.isLocked) return;
              runSaveAndCreateJobMutation();
            }}
          >
            {t("Save and create job")}
          </LoadingButton>
        )}
        <LoadingButton
          disabled={isSaveAndCreateJobRunning || subproductData?.isLocked}
          loading={isInFlight}
          onClick={() => {
            if (subproductData?.isLocked) return;
            runSaveMutation();
          }}
          variant="contained"
        >
          {t("Save")}
        </LoadingButton>
      </CardActions>
    </Card>
  );
}

export function SubproductDataCardSkeleton() {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Card
      variant="outlined"
      sx={{
        width: "660px",
        margin: "auto",
        [theme.breakpoints.down(680)]: {
          width: "100%",
          margin: 0,
        },
      }}
    >
      <CardHeader title={<Skeleton />} subheader={<Skeleton />} />
      <Divider />
      <CardContent>
        <CardSection title={t("General")} icon={<InfoIcon />}>
          <InfoItem
            sm={3}
            xs={6}
            value={<Skeleton />}
            description={t("Due Date")}
          />
          <InfoItem
            sm={3}
            xs={6}
            value={<Skeleton />}
            description={t("Pages Count")}
          />
          <InfoItem
            sm={3}
            xs={6}
            value={<Skeleton />}
            description={t("Binding")}
          />
          <InfoItem
            sm={3}
            xs={6}
            value={<Skeleton />}
            description={t("Product Part")}
          />
          <InfoItem sm={3} xs={6} value={<Skeleton />} description={t("Run")} />
          <InfoItem
            sm={3}
            xs={6}
            value={<Skeleton />}
            description={t("Color")}
          />
          <InfoItem
            sm={6}
            xs={12}
            value={<Skeleton />}
            description={t("Requested Paper")}
          />
          <InfoItem
            sm={6}
            xs={12}
            value={<Skeleton />}
            description={t("Size Closed")}
          />
          <InfoItem
            sm={6}
            xs={12}
            value={<Skeleton />}
            description={t("Size Open")}
          />
        </CardSection>
        <Divider variant="inset" />
        <CardSection title={t("Press")} icon={<PrintIcon />}>
          <InfoItem
            xs={6}
            value={<Skeleton />}
            description={t("Printing Machine")}
          />
          <InfoItem
            xs={6}
            value={<Skeleton />}
            description={t("Imposition pattern")}
          />
          <InfoItem xs={6} value={<Skeleton />} description={t("Work Style")} />
        </CardSection>
        <Divider variant="inset" />
        <CardSection title={t("Post Press")}>
          <InfoItem
            sm={3}
            xs={6}
            value={<Skeleton />}
            description={t("Laminating")}
          />
          <InfoItem
            sm={3}
            xs={6}
            value={<Skeleton />}
            description={t("Creasing")}
          />
          <InfoItem
            sm={3}
            xs={6}
            value={<Skeleton />}
            description={t("UV Finish")}
          />
          <InfoItem
            sm={3}
            xs={6}
            value={<Skeleton />}
            description={t("Shape cutting")}
          />
          <InfoItem
            sm={3}
            xs={6}
            value={<Skeleton />}
            description={t("Embossing")}
          />
          <InfoItem
            sm={3}
            xs={6}
            value={<Skeleton />}
            description={t("Varnishing")}
          />
        </CardSection>
        <Divider variant="inset" />
        <CardSection title={t("Other")}>
          <InfoItem
            sm={3}
            xs={12}
            value={<Skeleton />}
            description={t("Project Number")}
          />
          <InfoItem
            sm={9}
            xs={12}
            value={<Skeleton />}
            description={t("Project Name")}
          />
          <InfoItem
            sm={3}
            xs={12}
            value={<Skeleton />}
            description={t("Product Number")}
          />
          <InfoItem
            sm={9}
            xs={12}
            value={<Skeleton />}
            description={t("Product Name")}
          />
        </CardSection>
      </CardContent>
      <Divider />
      <CardActions>
        <Button disabled>{t("Cancel")}</Button>
        <Box sx={{ flexGrow: 1 }} />
        <Button variant="contained" disabled>
          {t("Save")}
        </Button>
      </CardActions>
    </Card>
  );
}
