/**
 * @generated SignedSource<<313cd0e0758c1d7bb1bcb200aec1db6e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type ToolbarAccountIconQuery$variables = {};
export type ToolbarAccountIconQuery$data = {
  readonly me: {
    readonly name: string;
    readonly email: string | null;
    readonly logoutUrl: string | null;
  };
};
export type ToolbarAccountIconQuery = {
  variables: ToolbarAccountIconQuery$variables;
  response: ToolbarAccountIconQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Me",
    "kind": "LinkedField",
    "name": "me",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "email",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "logoutUrl",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ToolbarAccountIconQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "ToolbarAccountIconQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "b6f439149597d8203e26f0483d47103e",
    "id": null,
    "metadata": {},
    "name": "ToolbarAccountIconQuery",
    "operationKind": "query",
    "text": "query ToolbarAccountIconQuery {\n  me {\n    name\n    email\n    logoutUrl\n  }\n}\n"
  }
};
})();

(node as any).hash = "22562210e4a7449748c06bd75d310fb9";

export default node;
