/**
 * @generated SignedSource<<b70b894ebd482e2cc02a502cd3cdfddc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type CreateOrOverwriteJobMutationError = "CANT_OVERWRITE_NON_DRAFT_JOB" | "PPS_QUERY_ERROR" | "SP_NOT_FOUND_IN_PPS" | "%future added value";
export type NewJobFABMutation$variables = {};
export type NewJobFABMutation$data = {
  readonly createOrOverwriteJob: {
    readonly job: {
      readonly pk: number;
    } | null;
    readonly errors: ReadonlyArray<CreateOrOverwriteJobMutationError> | null;
  } | null;
};
export type NewJobFABMutation = {
  variables: NewJobFABMutation$variables;
  response: NewJobFABMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "Literal",
    "name": "input",
    "value": {}
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "pk",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "errors",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "NewJobFABMutation",
    "selections": [
      {
        "alias": null,
        "args": (v0/*: any*/),
        "concreteType": "CreateOrOverwriteJobFieldPayload",
        "kind": "LinkedField",
        "name": "createOrOverwriteJob",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "JobNode",
            "kind": "LinkedField",
            "name": "job",
            "plural": false,
            "selections": [
              (v1/*: any*/)
            ],
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": "createOrOverwriteJob(input:{})"
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "NewJobFABMutation",
    "selections": [
      {
        "alias": null,
        "args": (v0/*: any*/),
        "concreteType": "CreateOrOverwriteJobFieldPayload",
        "kind": "LinkedField",
        "name": "createOrOverwriteJob",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "JobNode",
            "kind": "LinkedField",
            "name": "job",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": "createOrOverwriteJob(input:{})"
      }
    ]
  },
  "params": {
    "cacheID": "66a72696e886073d3143d5dd9dbcecaf",
    "id": null,
    "metadata": {},
    "name": "NewJobFABMutation",
    "operationKind": "mutation",
    "text": "mutation NewJobFABMutation {\n  createOrOverwriteJob(input: {}) {\n    job {\n      pk\n      id\n    }\n    errors\n  }\n}\n"
  }
};
})();

(node as any).hash = "916e650844d4e5c92e72d033993320b2";

export default node;
