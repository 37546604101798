import {
  PreloadedQuery,
  useFragment,
  useMutation,
  usePreloadedQuery,
} from "react-relay";
import { useTranslation } from "react-i18next";
import graphql from "babel-plugin-relay/macro";
import React, { useState } from "react";
import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  List,
  ListItem,
  ListItemText,
  Skeleton,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { slowQuery } from "./JobDetailView";
import { RelatedSubproductsList_subproductData$key } from "./__generated__/RelatedSubproductsList_subproductData.graphql";
import {
  CreateOrOverwriteJobMutationError,
  RelatedSubproductsListPopulateDataMutation,
} from "./__generated__/RelatedSubproductsListPopulateDataMutation.graphql";
import { JobDetailViewSlowQuery } from "./__generated__/JobDetailViewSlowQuery.graphql";
import QueryErrorDialog from "../../common/QueryErrorDialog";
import queryErrorToMessages from "../../../utils/queryErrorToMessages";
import { getCreateOrOverwriteJobMutationErrorLabels } from "../../../__generatedEnumLabels";
import { RecordSourceSelectorProxy } from "relay-runtime";
import SubproductDataListItem from "../../subproduct-data/SubproductDataList/SubproductDataListItem";

export function RelatedSubproductsListSkeleton() {
  return (
    <List sx={{ width: "100%" }}>
      <ListItem
        secondaryAction={<Skeleton variant="circular" width={32} height={32} />}
      >
        <ListItemText primary={<Skeleton />} secondary={<Skeleton />} />
        <Skeleton sx={{ marginLeft: "16px", marginRight: "16px" }}>
          <Chip
            sx={{
              width: "80px",
            }}
          />
        </Skeleton>
      </ListItem>
    </List>
  );
}

interface RelatedSubproductsListProps {
  jobDetailSlowQueryRef: PreloadedQuery<JobDetailViewSlowQuery>;
  allowPopulatingJobFromSPData: boolean;
  disablePopulateJobIcons: boolean;
  jobId: string;
}

export default function RelatedSubproductsList({
  jobDetailSlowQueryRef,
  allowPopulatingJobFromSPData,
  disablePopulateJobIcons,
  jobId,
}: RelatedSubproductsListProps) {
  const [t] = useTranslation();
  const slowQueryResponse = usePreloadedQuery(slowQuery, jobDetailSlowQueryRef);
  const data = useFragment<RelatedSubproductsList_subproductData$key>(
    graphql`
      fragment RelatedSubproductsList_subproductData on JobNode {
        subproductData {
          edges {
            node {
              ...SubproductDataListItem_subproductDataNode
            }
          }
        }
      }
    `,
    slowQueryResponse.job!
  );

  const [commit, isInFlight] =
    useMutation<RelatedSubproductsListPopulateDataMutation>(
      graphql`
        mutation RelatedSubproductsListPopulateDataMutation(
          $jobId: ID
          $spDataId: ID
        ) {
          createOrOverwriteJob(input: { id: $jobId, spDataId: $spDataId }) {
            job {
              id
              ...JobDataCard_params
            }
            errors
            warnings {
              field
              type
              value
            }
          }
        }
      `
    );

  const [mutationErrors, setMutationErrors] = useState<{
    open: boolean;
    primaryErrorKey: string | null;
    secondaryErrorKey: string | null;
  }>({
    open: false,
    primaryErrorKey: null,
    secondaryErrorKey: null,
  });

  const [softMutationErrors, setSoftMutationErrors] = useState<{
    open: boolean;
    errors: readonly CreateOrOverwriteJobMutationError[];
  }>({
    open: false,
    errors: [],
  });

  const [confirmSPDataId, setConfirmSPDataId] = React.useState<string | null>(
    null
  );

  return (
    <>
      <QueryErrorDialog
        handleClose={() => {
          setMutationErrors({
            ...mutationErrors,
            open: false,
          });
        }}
        open={mutationErrors["open"]}
        primaryError={
          mutationErrors["primaryErrorKey"] != null
            ? t(mutationErrors["primaryErrorKey"])
            : null
        }
        secondaryError={
          mutationErrors["secondaryErrorKey"] != null
            ? t(mutationErrors["secondaryErrorKey"])
            : null
        }
      />
      <QueryErrorDialog
        handleClose={() => {
          setSoftMutationErrors({
            ...softMutationErrors,
            open: false,
          });
        }}
        open={softMutationErrors["open"]}
        primaryError={t("Error while populating data from subproduct")}
        secondaryError={softMutationErrors["errors"]
          .map((err) => getCreateOrOverwriteJobMutationErrorLabels(t).get(err))
          .filter((err): err is string => err !== undefined)}
      />
      <Dialog
        open={confirmSPDataId != null}
        onClose={() => setConfirmSPDataId(null)}
      >
        <DialogContent>
          <DialogContentText>
            {t("This will overwrite metadata proceed")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmSPDataId(null)}>
            {t("Cancel")}
          </Button>
          <LoadingButton
            loading={isInFlight}
            onClick={() => {
              commit({
                variables: {
                  jobId: jobId,
                  spDataId: confirmSPDataId,
                },
                onCompleted: (response) => {
                  setConfirmSPDataId(null);
                  if (
                    (response.createOrOverwriteJob?.errors || []).length > 0
                  ) {
                    setSoftMutationErrors({
                      open: true,
                      errors: response.createOrOverwriteJob!.errors!,
                    });
                  }
                },
                onError: (error) => {
                  setConfirmSPDataId(null);
                  const errorKeys = queryErrorToMessages(error);
                  console.log(error);
                  setMutationErrors({
                    open: true,
                    primaryErrorKey: errorKeys.error.primaryError,
                    secondaryErrorKey: errorKeys.error.secondaryError,
                  });
                },
                updater: (store: RecordSourceSelectorProxy) => {
                  // Take warnings from this mutation, and assign them to postPopulationWarnings, which is defined in
                  // our custom schema extension. That way, these warnings can be easily displayed in the JobDetail
                  // card.
                  const jobRecordProxy = store.get(jobId);
                  const payload = store.getRootField("createOrOverwriteJob");
                  const warnings = payload?.getLinkedRecords("warnings");
                  if (warnings == null) {
                    return;
                  }
                  jobRecordProxy?.setLinkedRecords(
                    warnings,
                    "postPopulationWarnings"
                  );
                },
              });
            }}
          >
            {t("Proceed")}
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <List sx={{ width: "100%" }}>
        {data.subproductData.edges.map((edge) => {
          if (edge?.node == null) {
            return null;
          }
          return (
            <SubproductDataListItem
              populateJobFromSPData={
                allowPopulatingJobFromSPData
                  ? {
                      disableButtons: disablePopulateJobIcons,
                      onPopulateClick: setConfirmSPDataId,
                    }
                  : undefined
              }
              fragmentRef={edge.node}
              openAsDialog
              jobId={jobId}
            />
          );
        })}
      </List>
    </>
  );
}
