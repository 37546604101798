/**
 * @generated SignedSource<<3ec237e39691d3c454c19a67a9a73949>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ConversionWarningType = "SUBPRODUCT_BINDING_UNKNOWN" | "MACHINE_UNKNOWN" | "WORKSTYLE_UNKNOWN" | "GUESSED_FROM_COLOR" | "%future added value";
export type CreateOrOverwriteJobMutationError = "CANT_OVERWRITE_NON_DRAFT_JOB" | "PPS_QUERY_ERROR" | "SP_NOT_FOUND_IN_PPS" | "%future added value";
export type RelatedSubproductsListPopulateDataMutation$variables = {
  jobId?: string | null;
  spDataId?: string | null;
};
export type RelatedSubproductsListPopulateDataMutation$data = {
  readonly createOrOverwriteJob: {
    readonly job: {
      readonly id: string;
      readonly " $fragmentSpreads": FragmentRefs<"JobDataCard_params">;
    } | null;
    readonly errors: ReadonlyArray<CreateOrOverwriteJobMutationError> | null;
    readonly warnings: ReadonlyArray<{
      readonly field: string;
      readonly type: ConversionWarningType;
      readonly value: string | null;
    }> | null;
  } | null;
};
export type RelatedSubproductsListPopulateDataMutation = {
  variables: RelatedSubproductsListPopulateDataMutation$variables;
  response: RelatedSubproductsListPopulateDataMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "jobId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "spDataId"
  }
],
v1 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "jobId"
      },
      {
        "kind": "Variable",
        "name": "spDataId",
        "variableName": "spDataId"
      }
    ],
    "kind": "ObjectValue",
    "name": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "errors",
  "storageKey": null
},
v4 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "field",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "type",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "value",
    "storageKey": null
  }
],
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "CreateOrOverwriteJobMutationWarningType",
  "kind": "LinkedField",
  "name": "warnings",
  "plural": true,
  "selections": (v4/*: any*/),
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RelatedSubproductsListPopulateDataMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateOrOverwriteJobFieldPayload",
        "kind": "LinkedField",
        "name": "createOrOverwriteJob",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "JobNode",
            "kind": "LinkedField",
            "name": "job",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "JobDataCard_params"
              }
            ],
            "storageKey": null
          },
          (v3/*: any*/),
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RelatedSubproductsListPopulateDataMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateOrOverwriteJobFieldPayload",
        "kind": "LinkedField",
        "name": "createOrOverwriteJob",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "JobNode",
            "kind": "LinkedField",
            "name": "job",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "pk",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "state",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isReady",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "jdfId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "frontcolors",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "backcolors",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "binding",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "printingMachine",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "workstyle",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "note",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "signaturesPerSheetX",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "signaturesPerSheetY",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "printSheetCount",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "lamination",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "creasing",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "uvFinish",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "shapeCutting",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "embossing",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "paperNettPerSheet",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "paperGrossPerSheet",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "paperReservePerJob",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "paperId",
                "storageKey": null
              },
              {
                "kind": "ClientExtension",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CreateOrOverwriteJobMutationWarningType",
                    "kind": "LinkedField",
                    "name": "postPopulationWarnings",
                    "plural": true,
                    "selections": (v4/*: any*/),
                    "storageKey": null
                  }
                ]
              }
            ],
            "storageKey": null
          },
          (v3/*: any*/),
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ae5b4d8bc8210ea315e58e096b90e3ea",
    "id": null,
    "metadata": {},
    "name": "RelatedSubproductsListPopulateDataMutation",
    "operationKind": "mutation",
    "text": "mutation RelatedSubproductsListPopulateDataMutation(\n  $jobId: ID\n  $spDataId: ID\n) {\n  createOrOverwriteJob(input: {id: $jobId, spDataId: $spDataId}) {\n    job {\n      id\n      ...JobDataCard_params\n    }\n    errors\n    warnings {\n      field\n      type\n      value\n    }\n  }\n}\n\nfragment JobDataCard_params on JobNode {\n  id\n  pk\n  state\n  isReady\n  jdfId\n  name\n  frontcolors\n  backcolors\n  binding\n  printingMachine\n  workstyle\n  note\n  signaturesPerSheetX\n  signaturesPerSheetY\n  printSheetCount\n  lamination\n  creasing\n  uvFinish\n  shapeCutting\n  embossing\n  paperNettPerSheet\n  paperGrossPerSheet\n  paperReservePerJob\n  paperId\n}\n"
  }
};
})();

(node as any).hash = "f112da29216fc53401519d251753d2d9";

export default node;
