/**
 * @generated SignedSource<<d6691cc479074fccd38e77c35bbed91e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type NextcloudFieldErrorDetail = "FILE_NOT_FOUND" | "ERROR" | "WRONG_URL" | "INVALID_LINK" | "NOT_A_DIRECTORY" | "NOT_A_FILE" | "FILE_NOT_EMPTY" | "FILE_EMPTY" | "INVALID_MIME_TYPE" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ErrorString_nextcloudFieldError$data = {
  readonly nextcloudErrorType: NextcloudFieldErrorDetail;
  readonly " $fragmentType": "ErrorString_nextcloudFieldError";
};
export type ErrorString_nextcloudFieldError$key = {
  readonly " $data"?: ErrorString_nextcloudFieldError$data;
  readonly " $fragmentSpreads": FragmentRefs<"ErrorString_nextcloudFieldError">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ErrorString_nextcloudFieldError",
  "selections": [
    {
      "alias": "nextcloudErrorType",
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    }
  ],
  "type": "NextcloudFieldErrorType",
  "abstractKey": null
};

(node as any).hash = "6b4dcd497f6543f1101b330d7f75b24e";

export default node;
