/**
 * @generated SignedSource<<7de6cc341bb318b54df811e12b5c8ce7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, GraphQLSubscription } from 'relay-runtime';
export type TaskState = "PENDING" | "IN_PROGRESS" | "DONE" | "%future added value";
export type TaskType = "DTP_PREPARATION" | "PLATE_MAKING" | "PRINTING" | "VARNISHING" | "STANDARD_UV_VARNISHING" | "LAMINATING" | "SHAPE_CUTTING" | "CUTTING" | "FOLDING" | "GATHERING" | "HOLE_MAKING" | "COLLATING" | "SEWING" | "PRESSING" | "EMBOSSING" | "PERFORATING" | "CREASING" | "SCORING" | "RELIEF_UV_VARNISHING" | "COLLECTING" | "EDGE_GLUING" | "%future added value";
export type TasksCardSubscription$variables = {
  jobId: string;
};
export type TasksCardSubscription$data = {
  readonly tasks: ReadonlyArray<{
    readonly id: string;
    readonly type: TaskType | null;
    readonly state: TaskState | null;
  } | null>;
};
export type TasksCardSubscription = {
  variables: TasksCardSubscription$variables;
  response: TasksCardSubscription$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "jobId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "job",
        "variableName": "jobId"
      }
    ],
    "concreteType": "TaskNode",
    "kind": "LinkedField",
    "name": "tasks",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "type",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "state",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TasksCardSubscription",
    "selections": (v1/*: any*/),
    "type": "Subscription",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TasksCardSubscription",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "1d4736137db08b4b94848ac9bdc3d205",
    "id": null,
    "metadata": {},
    "name": "TasksCardSubscription",
    "operationKind": "subscription",
    "text": "subscription TasksCardSubscription(\n  $jobId: ID!\n) {\n  tasks(job: $jobId) {\n    id\n    type\n    state\n  }\n}\n"
  }
};
})();

(node as any).hash = "58481f0e997941bb1833f80db7d9d208";

export default node;
