import { Box, Grid, styled, Typography } from "@mui/material";
import React from "react";

const CardSectionHeader = styled(Typography)(() => ({
  fontSize: ".875rem",
  lineHeight: "1.375rem",
  fontWeight: 500,
}));

interface CardSectionProps {
  error?: boolean;
  title: string;
  icon?: React.ReactNode;
  disablePaddingForIcon?: boolean;
}

export default function CardSection(
  props: React.PropsWithChildren<CardSectionProps>
) {
  return (
    <>
      <CardSectionHeader
        variant="h2"
        sx={{
          marginTop: "6px",
          color: props.error ? "#d32f2f" : "#000000",
        }}
      >
        {props.title}
      </CardSectionHeader>
      <Box sx={{ margin: "16px 0 16px 0", display: "flex" }}>
        {props.disablePaddingForIcon === true ? null : (
          <Box sx={{ width: "40px", color: "#777" }}>{props.icon}</Box>
        )}
        <Grid
          container
          sx={{
            flexGrow: 1,
            marginLeft: props.disablePaddingForIcon === true ? "8px" : 0,
          }}
          spacing={2}
        >
          {props.children}
        </Grid>
      </Box>
    </>
  );
}
